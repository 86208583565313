.live-stream-event-video {
  height: 50vh;
  width: 100%;
}

.tutorial-group-video {
  height: 50vh;
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
}
.brian-modal {
  display: flex;
  padding-left: 12px;
}
.platinum-program-page {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  user-select: none;
}

.platinum-program-page-content-wrapper {
  padding: 16px;
  width: 70%;
  // min-height: calc(100vh - 90px);
}

.past-videos-content {
  width: 30%;
  background: transparent;
  min-height: calc(100vh - 90px);
}

.platinum-program-page-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 10px;
}

.platinum-program-page-short-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #6e6e70;
  margin-bottom: 30px;
}

.platinum-program {
  .platinum-program-page-navigator {
    justify-content: space-between !important;
  }
}

.platinum-program-page-navigator {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 30px;
  border-bottom: 0.5px solid #e4e5e8;
}

.live-chat-page-navigator {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.live-chat-page-nav-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.platinum-program-page-navItem {
  width: 25%;
  padding: 6px;
  cursor: pointer;
  user-select: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a6a6a6;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding-bottom: 10px;
  border-bottom: 6px solid transparent;

  .live-chat {
    width: 36% !important;
    white-space: nowrap;
  }
}

.platinum-program-page-navItem .expand-icon {
  color: #a6a6a6 !important;
  fill: #a6a6a6 !important;
}

.platinum-program-page-navItem.active {
  border-bottom: 6px solid #5eeecd;
  color: #ffffff;
}

.platinum-program-page-navItem.active .expand-icon {
  color: #ffffff !important;
  fill: #ffffff !important;
}

// live chat
.live-chat-page-navItem {
  width: 36%;
  padding: 6px;
  cursor: pointer;
  user-select: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a6a6a6;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding-bottom: 10px;
  border-bottom: 6px solid transparent;

  .live-chat {
    width: 36% !important;
    white-space: nowrap;
  }
}

.live-chat-page-navItem .expand-icon {
  color: #a6a6a6 !important;
  fill: #a6a6a6 !important;
}

.live-chat-page-navItem.active {
  border-bottom: 6px solid #5eeecd;
}

.live-chat-page-navItem.active .expand-icon {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.platinum-program-page-tutorials-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  min-height: 420px;
}

.platinum-program-page-tutorial-section {
  padding: 20px;
  background: #161616;
  width: calc(50% - 5px);
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
}

.platinum-program-page-tutorial-section-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.platinum-program-page-tutorial-section-header .play-circle-icon {
  fill: #ffffff !important;
  color: #ffffff !important;
  width: 48px !important;
  height: 48px !important;
}

.platinum-program-page-tutorial-section-header-info {
  margin-left: 15px;
}

.platinum-program-page-tutorial-section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 7px;
}

.platinum-program-page-tutorial-section-video-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
}

.platinum-program-page-tutorial-section-body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #e4e5e8;
  margin-bottom: 15px;
}

.platinum-program-page-tutorial-section-view-video {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5eeecd;
}

.platinum-program-page-tutorial-section-view-video .arrow-circle-right-icon {
  color: #5eeecd !important;
  fill: #5eeecd !important;
  margin-left: 5px;
}

.platinum-program-page-live-streams-content {
  padding: 24px 30px;
  background: #161616;
  border-radius: 10px;
}

.platinum-program-page-live-streams-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
  margin-bottom: 15px;
}

.platinum-program-page-live-streams-info {
  margin-top: 24px;
}

.platinum-program-page-live-streams-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.live-streams-author {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 50%;
}

.platinum-program-page-live-streams-author-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.platinum-program-page-live-streams-author-job {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
}

.platinum-program-page-live-streams-info-content {
  padding-bottom: 40px;
}

.platinum-program-page-live-streams-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 8px;
}

.platinum-program-page-live-streams-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #e4e5e8;
}

.platinum-program-page-live-streams-schedule {
  padding-top: 40px;
}

.platinum-program-page-live-streams-schedule-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 20px;
}

.platinum-program-page-live-streams-schedule-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.platinum-program-page-live-streams-schedule-day-item {
  width: calc(20% - 2px);
}

.platinum-program-page-live-streams-schedule-day {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: #161616;
  border-radius: 10px;
  border: 1px solid #ffffff;
  padding: 5px;
}

.platinum-program-page-live-streams-schedule-time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  text-align: center;
  margin-top: 7px;
}

.current-video-group-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    color: #5eeecd !important;

    .current-video-group-crumb {
      color: #5eeecd !important;
      fill: #5eeecd !important;
    }
  }
}

.current-video-group-crumb {
  color: #ffffff !important;
  fill: #ffffff !important;
  margin-right: 5px;
  cursor: pointer;
}

.beginner-track-text {
  padding-left: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.building-text {
  margin-top: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  padding-bottom: 40px;
  border-bottom: 0.5px solid #e4e5e8;
}

.videos-container {
  padding: 30px;
  background: #161616;
  border-radius: 10px;
}

.summary-bg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 30px;
}

.completely-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  margin-top: 30px;
  display: flex;
}

.objective-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  margin-top: 30px;
  color: #ffffff;
}

.ul-videos {
  list-style: none;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 40px;
  border-bottom: 0.5px solid #e4e5e8;
}

.list-item-videos {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #e4e5e8;
}

.list-item-videos::before {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  content: "• ";
  color: #e4e5e8;
}

.videos-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  margin-top: 30px;
  color: #ffffff;
}

.video-table-container {
  margin-top: 40px;
  border: 0.5px solid #e4e5e8;
}

.video-table-row {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #e4e5e8;
  cursor: pointer;
}

.video-table-row:last-child {
  border-bottom: none;
}

.video-table-row:hover {
  background: rgba(228, 229, 232, 0.2);
}

.video-table-row .MuiIconButton-root {
  padding: 0 !important;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.w-18 {
  width: 18px;
}

.table-video-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-left: 10px;
}

.time-stamp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.05em;
  color: #a6a6a6;
  margin-right: 14px;
}

.align-center {
  align-items: center;
}

.radio-video .MuiSvgIcon-fontSizeMedium {
  fill: #b8b8b8 !important;
  color: #b8b8b8 !important;
}

.radio-video .MuiSvgIcon-fontSizeMedium.checked-icon {
  fill: #00cb87 !important;
  color: #00cb87 !important;
}

.arrow-circle-right-icon {
  margin-left: 5px;
}

.arrow-circle-left-icon {
  margin-right: 5px;
}

.platinum-program-qa-chat-widget {
  margin-top: 106px;

  iframe {
    height: calc(50vh + 28px);
    border-radius: 10px;
  }
}

.qa-chat-wrapper {
  background: rgb(28, 30, 32) !important;
}

.tutorial-video-group-footer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tutorial-video-group-footer-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #e4e5e8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    color: #e4e5e8 !important;
    fill: #e4e5e8 !important;
  }

  &:hover {
    color: #5eeecd !important;

    & svg {
      color: #5eeecd !important;
      fill: #5eeecd !important;
    }
  }
}

.live-stream-event-chat {
  display: none;
  margin-top: 20px;

  iframe {
    height: 50vh;
    border-radius: 10px;
  }
}

body.light {
  .platinum-program-page-title {
    color: #000;
  }

  .past-videos-content {
    background-color: transparent;
  }

  .past-videos-dropdown-option {
    color: #000000;
  }

  .past-videos-dropdown {
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px #e4e4e4;
    border-radius: 8px;
  }

  .past-videos-dropdown-text {
    color: #000000;
  }

  .past-videos-dropdown svg {
    fill: #000000 !important;
    color: #000000 !important;
  }

  .past-videos-big-title {
    color: #000000;
  }

  .past-videos-small-title {
    color: #7a7a7a;
  }

  .past-videos-blue-title {
    color: #007bff;
  }

  .qa-chat-wrapper {
    background: #f8fafc !important;
  }

  .platinum-program-page-navItem.active {
    color: #000000;
  }

  .platinum-program-page-navItem.active .expand-icon {
    color: #000000 !important;
    fill: #000000 !important;
  }

  .platinum-program-page-tutorial-section {
    background: #ffffff;
  }

  .platinum-program-page-tutorial-section-title {
    color: #000000;
  }

  .platinum-program-page-tutorial-section-video-info {
    color: #7a7a7a;
  }

  .platinum-program-page-tutorial-section-body {
    color: #7a7a7a;
  }

  .platinum-program-page-tutorial-section-header .play-circle-icon {
    color: #000000 !important;
    fill: #000000 !important;
  }

  .platinum-program-page-live-streams-content {
    background: #ffffff;
  }

  .platinum-program-page-live-streams-author-name {
    color: #000000;
  }

  .platinum-program-page-live-streams-author-job {
    color: #7a7a7a;
  }

  .platinum-program-page-live-streams-title {
    color: #000000;
  }

  .platinum-program-page-live-streams-description {
    color: #6e6e70;
  }

  .platinum-program-page-live-streams-schedule-title {
    color: #000000;
  }

  .platinum-program-page-live-streams-schedule-day {
    background: #f7f8f7;
    border-radius: 0;
    color: #6e6e70;
  }

  .platinum-program-page-live-streams-schedule-time {
    color: #6e6e70;
  }

  .videos-container {
    background: #ffffff;
  }

  .beginner-track-text {
    color: #000000;
  }

  .building-text {
    color: #7a7a7a;
  }

  .summary-bg {
    color: #000000;
  }

  .completely-text {
    color: #7a7a7a;
  }

  .objective-text {
    color: #000000;
  }

  .list-item-videos,
  .list-item-videos::before {
    color: #7a7a7a;
  }

  .videos-text {
    color: #000000;
  }

  .table-video-text {
    color: #000000;
  }

  .time-stamp {
    color: #7a7a7a;
  }

  .current-video-group-navigator {
    color: #7a7a7a;
  }

  .current-video-group-crumb {
    color: #7a7a7a !important;
    fill: #7a7a7a !important;
  }

  .tutorial-video-group-footer-item {
    color: #7a7a7a;

    & svg {
      color: #7a7a7a !important;
      fill: #7a7a7a !important;
    }

    &:hover {
      color: #5eeecd !important;

      & svg {
        color: #5eeecd !important;
        fill: #5eeecd !important;
      }
    }
  }
}

@media (max-width: 1400px) {
  .live-chat-page-navigator {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .live-chat-page-nav-items {
    margin-bottom: 20px;
  }
}

@media (min-width: 1201px) and (max-width: 1325px) {
  .past-videos-top-section {
    min-height: 617px;
  }

  .MuiCollapse-entered {
    min-height: 520px !important;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .past-videos-big-title {
    font-size: 19px;
    margin-bottom: 20px;
  }

  .past-videos-small-title {
    font-size: 14px;
  }
}

@media (min-width: 993px) and (max-width: 1021px) {
  .past-videos-top-section {
    min-height: 665px;
  }

  .MuiCollapse-entered {
    min-height: 550px !important;
  }
}

@media (min-width: 1072px) and (max-width: 1200px) {
  .past-videos-top-section {
    min-height: 600px;
  }

  .MuiCollapse-entered {
    min-height: 505px !important;
  }
}

@media (max-width: 992px) {
  .platinum-program-page {
    flex-direction: column;
  }

  .platinum-program-page-content-wrapper {
    width: 100%;
  }

  .past-videos-content {
    width: 100% !important;
    padding: 0 16px;
    min-height: auto;
  }

  .platinum-program-page-live-streams-schedule-day {
    font-size: 12px;
  }

  .platinum-program-page-live-streams-schedule-time {
    font-size: 12px;
  }

  .platinum-program-qa-chat-widget {
    padding-top: 0;
    height: 600px;
  }

  .live-stream-event-chat {
    display: block;
  }

  .past-videos-chat-widget {
    display: none;
  }
}

@media (max-width: 767px) {
  .platinum-program-page-tutorial-section {
    width: 100%;
  }

  .platinum-program-page-navigator {
    flex-wrap: wrap;
  }

  .platinum-program-page-navItem {
    width: 50%;
  }

  .platinum-program-page-live-streams-content {
    padding: 20px;
  }

  .platinum-program-page-real-time-trading-info {
    width: 100%;
  }
}
