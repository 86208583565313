.dash-circle-wrap-light {
  width: 80px;
  height: 80px;
  background: #fefcff;
  border-radius: 50%;
  margin-top: 9px;
}

.dash-circle-wrap-light .dash-circle .dash-mask,
.dash-circle-wrap-light .dash-circle .fill {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
}
.dash-circle-wrap-light .dash-circle .zoro {
  height: 0px !important;
}
.zoro {
  clip: rect(0px, 137px, 180px, 81px) !important;
}
.one-fourth {
  clip: rect(0px 80px 80px 71px) !important;
}
.half-ass {
  clip: rect(0px 80px 80px 42px) !important;
}
.tree-fourth {
  clip: rect(0px 80px 80px 40px) !important;
}
.hundo {
  clip: rect(0px, 137px, 180px, 38px) !important;
}
.dash-circle-wrap-light .dash-circle .dash-mask {
  clip: rect(0px, 137px, 180px, 40px);
}

.dash-circle-wrap-light .inside-dash-circle-up {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: rgb(9 227 147 / 20%) 0px 0px 5px 4px;
}

.dash-circle-wrap-light .inside-dash-circle-down {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: rgb(229 0 53 / 24%) 0px 0px 5px 4px;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// light Mode ^

.dash-circle-wrap-dark {
  width: 80px;
  height: 80px;
  background: #000;
  border-radius: 50%;
  margin-top: 9px;
}

.dash-circle-wrap-dark .dash-circle .dash-mask,
.dash-circle-wrap-dark .dash-circle .fill {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
}

.dash-circle-wrap-dark .dash-circle .dash-mask {
  clip: rect(0px, 137px, 180px, 40px);
}

.dash-circle-wrap-dark .inside-dash-circle-up {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1c1c1e;
  background-color: #1c1c1e;
}

.dash-circle-wrap-dark .inside-dash-circle-down {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #1c1c1e;
  box-shadow: rgba(234, 51, 35, 0.5) 0px 0px 5px 4px;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* color animation */

/* 3rd progress bar */
.dash-mask .fill {
  clip: rect(0px, 80px, 80px, 0px);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
}

/* sub parts */
.inside-dash-circle-percentage {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #555555;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.inside-dash-circle-value {
  color: #475569;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inside-dash-circle-title {
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  /* Mint Green/full */

  color: #3eb489;
}

.summary-block {
  flex: 1 1 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
}

@media (max-width: 767px) {
  .summary-blocks {
    margin-top: 25px;
    margin-bottom: 14px;
  }

  .summary-block {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
  }
}
.darkflow-title {
  position: relative;
  left: 3px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
  text-align: center;
  color: #94a3b8;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}
.options-title {
  position: relative;
  left: -9px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #94a3b8;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}

.inside-dash-circle-big {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  background: #fff;
}

body.dark {
  .inside-dash-circle-value {
    color: #fff;
  }

  .inside-dash-circle-big {
    background: #000;
  }
}
