.deleted-account-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: #fff;

    .header {
        padding: 16px;
        text-align: right;

        .logo {
            display: inline-block;
            width: 24px;
            height: 24px;
        }

        .title {
            display: inline-block;
            vertical-align: middle;
            font-size: 1.25rem;
            font-weight: bold;
            margin-left: 8px;
        }
    }

    .content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .message {
            text-align: center;
            margin-bottom: 240px;

            .logo-circle {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 8px;

                .circle-logo {
                    width: 64px;
                    height: 64px;
                }
            }

            .oops {
                font-size: 2.5rem;
                font-weight: bold;
                color: #1a202c;
                margin-top: 8px;
            }

            .description {
                margin-top: 12px;
                font-size: 1.125rem;
                color: #000;
                max-width: 450px;

                .contact-link {
                    padding-left: 8px;
                    color: #3182ce;
                    text-decoration: underline;
                }
            }

            .back-button {
                color: #000;
                border-color: #000;
                margin-top: 20px;
            }
        }
    }
}

body.dark .deleted-account-page {
    background-color: #1a202c !important;

    .oops {
        color: #fff !important;
    }

    .description {
        color: #e2e8f0 !important;

        .contact-link {
            color: #63b3ed !important;
        }
    }

    .back-button {
        color: #e2e8f0 !important;
        border-color: #e2e8f0 !important;
    }
}