.ws-sign-up {
  min-height: calc(100vh - 120px);
  background: url(../../assets/images/wealth-series-signup/bg.png) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
  padding-bottom: 50px;
}

.ws-sign-up__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  padding: 80px 32px 0px 32px;
}

.ws-glow-logo {
  width: 500px;
}

.ws-signup-description {
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  width: 100%;
  max-width: 720px;
}

.ws-signup-button-row {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ws-signup-divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.50) 50%, rgba(255, 255, 255, 0.00) 100%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ws-signup-button {
  width: 340px;
  height: 56px;
  padding: 8px 24px !important;
  border-radius: 8px !important;
  background: linear-gradient(0deg, #FFF5EC 0%, #FFF5EC 100%), linear-gradient(90deg, #F8EDE6 0.22%, #9D5521 100.01%) !important;
  box-shadow: 0px 0px 16px 0px #C9A387 !important;
  color: #725C30 !important;
  font-family: Inter !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
  transition: all 0.3s ease-in-out !important;
}

.ws-signup-tiers {
  display: flex;
  align-items: stretch;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  justify-content: center;
}

.ws-signup-tier {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: calc((100% - 16px) / 3);
  border-radius: 8px;
  border: 1px solid #FFF;
  backdrop-filter: blur(38px);

  &.ws-signup-tier--atlas {  
    background: rgba(38, 61, 54, 0.10);
  }

  &.ws-signup-tier--titan {
    background: rgba(67, 80, 101, 0.10);
  }
  
  &.ws-signup-tier--everest {
    background: rgba(58, 45, 80, 0.10);
  }
}

.ws-signup-tier-header {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 1 auto;
}

.ws-signup-tier-logo {
  height: 36px;
}

.ws-signup-tier-description {
  color: rgba(255, 255, 255, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-signup-tier-divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.12);
}

.ws-signup-tier-benefits {
  display: flex;
  padding: 12px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.ws-signup-tier-benefit-title {
  color: rgba(255, 255, 255, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-signup-tier-benefit {
  color: rgba(255, 255, 255, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-sign-up__disclaimer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  color: rgba(255, 255, 255, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  transform: translateX(-50%);
  width: 1150px;
  padding: 0 32px;
  text-align: center;
}

@media (max-width: 1399px) {
  .ws-sign-up__disclaimer {
    width: 100%;
    position: relative;
    bottom: 0;
    padding-top: 20px;
  }

  .ws-sign-up {
    height: auto;
    padding-bottom: 32px;
  }

  .ws-signup-tiers {
    flex-wrap: wrap;
    gap: 16px;
  }

  .ws-signup-tier {
    width: calc(50% - 8px);
  }
}

@media (max-width: 991px) {
  .ws-signup-tier {
    width: 100%;
  }

  .ws-signup-button-row {
    order: 2;
  }

  .ws-signup-button {
    z-index: 9999;
    position: fixed !important;
    bottom: 20px;

    &.ws-signup-button--visible {
      position: relative !important;
      bottom: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ws-signup-button {
    width: calc(720px - 30px - 64px);
  }
}

@media (max-width: 767px) {
  .ws-sign-up__content {
    gap: 32px;
  }

  .ws-signup-description {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ws-signup-button {
    width: calc(540px - 30px - 64px);
  }
}