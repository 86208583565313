.top-picks-of-the-day {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 15px;
}

.top-picks-of-the-day__title {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.beta-badge {
  display: flex;
  padding: 0px 6.5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #007bff;
  color: #007bff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.load-skel {
  padding: 0px 12px !important;
}

.top-picks-of-the-day__content {
  padding: 0px 20px 20px 0px;
  border-radius: 10px;
  background: #fff;
}

.top-picks-of-the-day__content__body {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.top-picks-of-the-day__content__footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding-left: 20px;

  svg {
    color: #fff;
  }
}

.top-picks-of-the-day__content__footer__button {
  background-image: url(../../../assets/images/ai-assistant-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px !important;
  padding: 8px 18px !important;
  width: 250px !important;
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  img {
    width: 22px;
  }
}

.ai-trade-assitant-button {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.063px;
}

.top-picks-of-the-day__content__footer__splitter {
  width: calc(50% - 120px);
  height: 1px;
  background: #e4e5e8;
}

.ai-assistant-widget {
  display: flex;
  padding: 0 20px;
}

.ai-assistant-widget .tradegpt-widget {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border: none;
}

.ai-assistant-widget.active {
  padding: 20px;
}

.ai-assistant-widget.active .tradegpt-widget {
  max-height: 100%;
  border: 1px solid #e4e5e8;
}

body.dark {
  .top-picks-of-the-day__title {
    color: #fff;
  }

  .top-picks-of-the-day__content {
    border: 1px solid #5eeecd;
    background: #000;
  }

  .top-picks-of-the-day__content__footer__splitter {
    background: #a6a6a6;
  }

  .beta-badge {
    border: 1px solid #5eeecd;
    color: #5eeecd;
  }
}

@media (max-width: 1099px) {
  .top-picks-of-the-day__content__body {
    flex-direction: column;
  }
}
