/* Optimized Animations */
.new-item-light,
.new-item-dark,
.new-item-options-up,
.new-item-options-down {
  z-index: 1000;
  animation-fill-mode: both;
  width: fit-content;
  animation-timing-function: ease-in-out;
  will-change: transform, opacity;
}

.neutral {
  color: inherit;
  /* Reset to the default color after animation */
}

.price-positive {
  color: #4caf50;
}

.price-negative {
  color: #ea3323;
}

.new-item-light {
  background-color: rgba(153, 111, 244, 0.961);
  animation-name: hvr-pulse-grow;
  animation-duration: 0.5s;
  /* Reduced duration */
}

.new-item-dark {
  background-color: rgba(37, 2, 112, 0.961);
  animation-name: hvr-pulse-grow;
  animation-duration: 0.5s;
  /* Reduced duration */
}

.new-item-options-up {
  animation-name: price-up;
  animation-duration: 0.6s;
  /* Adjust duration as desired */
}

.new-item-options-down {
  animation-name: price-down;
  animation-duration: 0.6s;
  /* Adjust duration as desired */
}


@keyframes price-up {
  0% {
    transform: scale(1);
    color: #4caf50;
  }

  50% {
    transform: scale(1.15);
    color: #4caf50;
    /* Green color for up */
  }

  100% {
    transform: scale(1);
    color: inherit;
  }
}

@keyframes price-down {
  0% {
    transform: scale(1);
    color: #ea3323;
  }

  50% {
    transform: scale(0.95);
    color: #ea3323;
  }

  100% {
    transform: scale(1);
    color: inherit;
  }
}

/* Combined Keyframes for Pop Animation */
@keyframes hvr-pop {
  0% {
    transform: scale(1.2);
    opacity: 0.8;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

/* Combined Keyframes for Pulse Grow Animation */
@keyframes hvr-pulse-grow {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Optimize Keyframe Usages */
.hvr-pop,
.hvr-pulse-grow-light,
.hvr-pulse-grow-dark {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.new-item-options-up,
.new-item-options-down {
  animation-fill-mode: none;
  /* Other styles */
}