.row-title {
  font-size: 14px;
  color: white !important;
  white-space: nowrap;
}
.options-table-header {
  .MuiButtonBase-root.Mui-active {
    color: white !important;
    font-weight: 600;
  }
}

.row-wrapper {
  border-bottom: 1px solid rgba(226, 232, 240, 0.5) !important;
  padding: 5px;
}

.row-wrapper2 {
  border-bottom: 1px solid rgba(226, 232, 240, 0.5) !important;
  padding: 2px;
}

.left-title {
  float: left;
  color: #b7b4c7 !important;
}

.right-title {
  color: black;
  float: right;
  font-weight: 400;
}

.left-title2 {
  float: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  /* color: #64748B; */
}

.right-title2 {
  float: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* color: #64748B; */
  white-space: nowrap;
}

.adjust {
  position: relative;
  left: 15px;
  padding-right: 12px;
}

/* Modified Stylings */

.collapsible-inner-table {
  background: #fff;
}

.collapsible-table tr th:first-child {
  border-right: 3px solid #e4e5e8;
}

.collapsible-table-inner-content {
  padding: 0 !important;
}

.collapsible-table-inner-row {
  width: 100%;
  overflow: auto;
  position: sticky;
  left: 0;
}

.collapsible-table-inner-content-box {
  margin: 0 !important;
  padding: 24px;
  min-width: 1200px;
  width: 100%;
}

.collapsible-table-inner-content-box
  .tabs.tabs--bordered-bottom
  .nav-tabs
  li
  .nav-link {
  color: #ccc;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 10px;
}

.collapsible-table-inner-content-box
  .tabs.tabs--bordered-bottom
  .nav-tabs
  li
  .nav-link.active {
  color: #007bff;
  font-weight: 600;
}

.collapsible-table-inner-content-box {
  li.nav-item {
    padding: 0 8px;
    border-right: 1px solid #cccccc;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}

.collapsible-inner {
  margin-top: 25px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.collapsible-inner-left {
  width: calc(50% - 12.5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
}

.collapsible-inner-left-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.collapsible-inner-left-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.collapsible-inner-left-header-title-symbol {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.013px;
}

.collapsible-inner-left-header-title-strike {
  border-radius: 20px;
  padding: 4px 21px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.put {
    background: rgba(255, 59, 48, 0.1);
    color: #ff3b30;
  }

  &.call {
    background: rgba(62, 180, 137, 0.1);
    color: #3eb489;
  }
}

.collapsible-inner-left-header-date {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.collapsible-inner-left-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.collapsible-inner-left-content-half {
  width: calc(50% - 5px);
}

.collapsible-inner-left-content-item {
  border-bottom: 1px solid #e4e5e8;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapsible-inner-left-content-item-title {
  overflow: hidden;
  color: #a6a6a6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.collapsible-inner-left-content-item-value {
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.collapsible-inner-right {
  width: calc(50% - 12.5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.collapsible-inner-right-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.collapsible-inner-timeline {
  border-radius: 4px;
  background: transparent;
  padding: 4px 10px;
  cursor: pointer;
  color: #ccc;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.collapsible-inner-timeline.active {
  background: #007bff;
  color: #fff;
  font-weight: 600;
}

.collapsible-inner-timeline-splitter {
  width: 1px;
  height: 100%;
  background: #cccccc;
}

.collapsible-inner-sparklines {
  width: 100%;
}

.collapsible-inner #option_tv_chart_container {
  width: 100%;
}

.collapsible-table-row {
  background-color: #fff;
}

.collapsible-table thead th svg {
  fill: #fff;
}

body.dark {
  .collapsible-table-row {
    background-color: #000;

    td button svg {
      fill: #fff;
    }
  }

  .collapsible-inner-table {
    background: #000;
  }

  .collapsible-table-inner-content-box
    .tabs.tabs--bordered-bottom
    .nav-tabs
    li
    .nav-link {
    background-color: transparent !important;
    color: #86868b;
  }

  .collapsible-table-inner-content-box
    .tabs.tabs--bordered-bottom
    .nav-tabs
    li
    .nav-link.active {
    color: #5eeecd;
    border-bottom-color: #5eeecd;
  }

  .collapsible-table-inner-content-box li.nav-item {
    border-right-color: #86868b;
  }

  .collapsible-inner-left-header-title-symbol {
    color: #fff;
  }

  .collapsible-inner-left-header-date {
    color: #5eeecd;
  }

  .collapsible-inner-left-header-title-strike.call {
    background: rgba(62, 180, 137, 0.4);
    color: #5eeecd;
  }

  .collapsible-inner-left-header-title-strike.put {
    background: rgba(255, 59, 48, 0.4);
    color: #ff3b30;
  }

  .collapsible-inner-left-content-item:nth-of-type(even) {
    background: #1c1c1e;
  }

  .collapsible-inner-left-content-item-value {
    color: #fff;
  }

  .collapsible-inner-timeline {
    color: #86868b;
  }

  .collapsible-inner-timeline.active {
    background: #5eeecd;
    color: #000;
  }

  .collapsible-inner-timeline-splitter {
    background: #86868b;
  }
}

@media (max-width: 1800px) {
  .collapsible-table-inner-row {
    width: calc(100vw - 280px - 98px);
  }
}

@media (max-width: 1325px) {
  .collapsible-table .MuiCollapse-entered {
    min-height: auto !important;
  }
}

@media (max-width: 992px) {
  .collapsible-table-inner-row {
    width: calc(100vw - 110px);
  }
}

@media (max-width: 991px) {
  .collapsible-table-inner-row {
    width: 660px;
  }

  .collapsible-table-inner-content-box {
    width: 660px;
    min-width: auto;
  }

  .collapsible-inner {
    flex-direction: column;
  }

  .collapsible-inner-left {
    width: 100%;
  }

  .collapsible-inner-right {
    width: 100%;
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .collapsible-table-inner-row {
    width: 480px;
  }

  .collapsible-table-inner-content-box {
    width: 480px;
  }
}

@media (max-width: 575px) {
  .collapsible-table-inner-row {
    width: calc(100vw - 60px);
  }

  .collapsible-table-inner-content-box {
    width: calc(100vw - 60px);
  }

  .collapsible-inner-left-content {
    flex-direction: column;
  }

  .collapsible-inner-left-content-half {
    width: 100%;
  }
}

// When sidebar is toggled off in desktop view
body[data-sidebar-size="sm"] {
  .collapsible-table-inner-row {
    width: calc(100vw - 120px - 98px);
    overflow: auto;
  }
}
