@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.logo-ta {
  position: fixed;
  top: 20px;
  left: 20px;
  width: auto;
}

.sign_in__page {
  background: url("background.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  align-items: center;

  .big-caption {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }

  .right-part {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .input-container {
      position: relative;
      width: 100%;
    }

    .input-field {
      width: calc(100% - 40px);
      /* Adjust the width to account for the icon */
      padding-right: 40px;
      /* Add padding to the right to avoid overlap */
    }

    .password-toggle-icon {
      position: absolute;
      right: -8px;
      top: 51%;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      /* Adjust padding as needed */
    }

    .contact-form {
      margin-top: 20px;

      .input-form {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 44px;

        .input-rows {
          width: 100%;

          .input-row2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
          }

          .input-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }

            .input-field {
              width: calc(50% - 15px);
              padding: 10px 5px;
              background: inherit;
              border: none;
              border-bottom: 2px solid #666666;
              font-family: Roboto, sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
              color: #a6a6a6;

              &:focus-visible {
                outline: none;
              }
            }

            .full-width {
              width: 100%;
            }
          }
        }
      }

      .squaredTwo {
        width: 18px;
        height: 18px;
        position: relative;
        background: #a6a6a6;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;

        label {
          width: 108px;
          height: 18px;
          cursor: pointer;
          position: absolute;
          left: 30px;
          top: -2px;
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 135.5%;
          color: #a6a6a6;

          &:after {
            content: "";
            width: 13px;
            height: 5px;
            position: absolute;
            top: 6px;
            left: -28px;
            border: 2px solid #595959;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }
        }

        input[type="checkbox"] {
          visibility: hidden;

          &:checked + label:after {
            opacity: 1;
          }
        }
      }

      .squaredThree {
        width: 18px;
        height: 18px;
        background: #a6a6a6;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 4px;
        left: -58.5px;
        position: relative;
        top: -23px;

        label {
          width: 108px;
          height: 18px;
          cursor: pointer;
          position: absolute;
          left: 30px;
          top: -2px;
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 135.5%;
          color: #a6a6a6;

          &:after {
            content: "";
            width: 13px;
            height: 5px;
            position: absolute;
            top: 6px;
            left: -28px;
            border: 2px solid #595959;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }
        }

        input[type="checkbox"] {
          visibility: hidden;

          &:checked + label:after {
            opacity: 1;
          }
        }
      }

      .forgot-password {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 135.5%;
        color: #3183ff !important;
        cursor: pointer;
      }
    }
  }

  .hidden-part {
    margin-top: 50px;
    margin-bottom: 75px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hidden-part-content {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 40px;
      text-align: center;
    }

    .hidden-part-contact-us {
      .hidden-part-contact-us-button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 26px;
        color: #ffffff;
        border-bottom: 2px solid #ffffff;
        padding-bottom: 8px;
        cursor: pointer;
      }
    }
  }
}

.sign-in-button {
  background-color: #3183ff !important;
  width: 350px !important;
  border-radius: 12px !important;
  text-transform: initial !important;
  color: #ffffff !important;
  margin-bottom: 20px !important;
  cursor: pointer !important;

  &:disabled {
    opacity: 1 !important;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
}

.logo-image {
  max-width: 100%;
  height: 60px;
}

.divider-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;

  .divider-border {
    flex-grow: 1;
    height: 1px;
    background-color: gray;
  }

  .divider-text {
    padding: 0 10px;
    color: gray;
    font-size: 14px;
  }
}

.social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .MuiIconButton-root {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.signup-suggestion {
  text-align: center;
  margin-top: 50px;

  span {
    color: white;
  }

  .signup-link {
    color: #3183ff;
    text-decoration: none;
    margin-left: 5px;
  }
}

.input-field:-webkit-autofill,
.input-field:-webkit-autofill:hover,
.input-field:-webkit-autofill:focus,
.input-field:-webkit-autofill:active {
  -webkit-text-fill-color: #a6a6a6 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
}

.forgot-password-btn {
  width: 100%;
  border-radius: 4px !important;
  background: #007BFF !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20) !important;
  color: #FFF !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;

  &:disabled {
    opacity: 0.7;
  }
}
