.emerald-sales-contact-form {
  width: calc(100% - 20px);
  max-width: 480px;
  border-radius: 10px;
  background: #fff;
  padding: 30px;
  height: auto;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
.platinum-standard-logo{
  width: 59px;
  padding-top: 27px;
  position: initial;
}
.platinum-background {
 background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%) !important;
 color: white;
}
.gap-0{
  gap: 0px;
}
.in-modal {
  font-size: 27px;
  text-align: center;
  line-height: 35px;
  margin-bottom: 0px;
}
  .disabled-cursor {
    cursor: not-allowed;
  }

  .phone-input-emerald {
    .MuiInputBase-adornedStart {
      fieldset {
        border: none;
      }
    }

    .MuiFormControl-root {
      .MuiInputBase-formControl {
        border: 1px solid #d2d5da !important;
      }
    }

    .special-phone {
      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
      }

      label {
        display: none;
      }
    }

    svg {
      fieldset {
        border: none;
      }
    }

    .MuiInputBase-root {
      height: 50px;
      border-radius: 12px;
    }

    .MuiInputBase-input {
      border: none !important;
    }
  }

  .error {
    color: red;
    margin-top: 5px;
    font-size: 0.875rem;
  }

  &.dark-contact-form {
    background: #28292d;
    
    .phone-input-emerald {
      .MuiSvgIcon-root {
        fill: white;
      }
    }
    .emerald-sales-contact-form__header {
      color: white;
    } 
  
    .emerald-sales-contact-form__title {
      color: #fff;
    }
  
    .emerald-sales-contact-form__input label {
      color: #fff;
    }
  
    .emerald-sales-contact-form__input input {
      color: #e4e5e8;
    }
  
    .emerald-sales-submit-button {
      color: #000;
    }
  
    .emerald-sales-submit-button:disabled {
      background: #a7a9af !important;
      color: #ffffff !important;
    }
  
    .emerald-sales-cancel-button {
      color: #3395ff !important;
    }
  
    .emerald-sales-contact-form__subtitle {
      color: #e4e5e8;
    }
  }
}

.emerald-sales-contact-form__header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
}

.emerald-sales-contact-form__logo {
  width: 60px;
}

.emerald-sales-contact-form__text_logos {
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.emerald-sales-contact-form__header svg {
  width: 100%;
  height: auto;
}

.emerald-sales-contact-form__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.emerald-sales-contact-form__title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

.emerald-sales-contact-form__subtitle {
  color: #a6a6a6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.emerald-sales-contact-form__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.emerald-sales-contact-form__input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;

  label {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.32px;
    margin-bottom: 0;
  }

  input {
    border-radius: 10px;
    border: 1px solid #d2d5da;
    padding: 7px 12px;
    width: 100%;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.32px;
    background: transparent;

    &:focus {
      outline: none;
    }
  }
}

.emerald-sales-submit-button {
  margin-top: 10px !important;
  width: 100% !important;
  max-width: 300px !important;
  border-radius: 5px !important;
  background: linear-gradient(269deg, #55efc4 0%, #000 100%) !important;
  padding: 10.25px 16px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-transform: initial !important;
  box-shadow: none !important;

  &:disabled {
    background: #e4e5e8 !important;
  }

  &.ws-background {
    background: linear-gradient(90deg, rgb(65, 55, 56) 0%, rgb(129, 35, 8) 100%) !important;
  }
}

.emerald-sales-cancel-button {
  width: 100% !important;
  max-width: 300px !important;
  color: #007bff !important;
  text-transform: inherit !important;
  margin-top: -10px !important;

  &:hover {
    background-color: transparent !important;
  }
}

.emerald-signup.dark {
  .MuiPaper-root {
    background-color: #000;
  }
}

body.dark {
  .phone-input-emerald {
    .MuiSvgIcon-root {
      fill: white;
    }
  }
  .emerald-sales-contact-form__header {
    color: white;
  } 

  .emerald-sales-contact-form {
    background: #28292d;
  }

  .emerald-sales-contact-form__title {
    color: #fff;
  }

  .emerald-sales-contact-form__input label {
    color: #fff;
  }

  .emerald-sales-contact-form__input input {
    color: #e4e5e8;
  }

  .emerald-sales-submit-button {
    color: #000;
  }

  .emerald-sales-submit-button:disabled {
    background: #a7a9af !important;
    color: #ffffff !important;
  }

  .emerald-sales-cancel-button {
    color: #3395ff !important;
  }

  .emerald-sales-contact-form__subtitle {
    color: #e4e5e8;
  }
}
