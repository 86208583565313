.ws-portfolio-key-adjustments {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.ws-portfolio-key-adjustments__header {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 0 16px;
}

.ws-portfolio-key-adjustments__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 300px;
  overflow: auto;
}

.ws-portfolio-key-adjustment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-key-adjustment__time {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  padding: 0 16px;
}
.ws-portfolio-key-adjustment__item.active {
  background: rgba(0, 123, 255, 0.08);
}
.ws-portfolio-key-adjustment__item {
  cursor: pointer;
  display: flex;
  padding: 6px 24px 6px 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    background: rgba(0, 123, 255, 0.08);
  }
}

.ws-portfolio-key-adjustment__ticker {
  display: flex;
  min-width: 140px;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-key-adjustment__ticker-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.ws-portfolio-new-adjustment {
  padding: 1px 6px;
  border-radius: 100px;
  background: #007BFF;
  color: #FFF;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  letter-spacing: 0.16px;
  text-transform: capitalize;
}

.ws-portfolio-key-adjustment__percent {
  display: flex;
  width: 140px;
  align-items: center;
  gap: 8px;
}

.ws-portfolio-key-adjustment__percent-prev {
  flex: 1 0 0;
  text-align: right;
}

.ws-portfolio-key-adjustment__percent-new {
  flex: 1 0 0;
  text-align: left;
}

.ws-portfolio-key-adjustment__change {
  padding: 3px 10px;
  border-radius: 100px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.12px;
  width: 72px;
  text-align: center;

  &.positive {
    color: #1B5E20;
    background: #C8E6C9;
  }

  &.negative {
    color: #C62828;
    background: #FECDD2;
  }
}

body.dark {
  .ws-portfolio-key-adjustments__header {
    color: #FFF;
  }

  .ws-portfolio-key-adjustment__time {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-key-adjustment__item {
    color: #FFF;
  }

  .ws-portfolio-key-adjustment__ticker {
    color: #FFF;
  }

  .ws-portfolio-key-adjustment__change {
    &.positive {
      color: #388E3C;
      background: #E8F5E9;
    }

    &.negative {
      color: #D32F2F;
      background: #FEEBEE;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1250px) {
  .ws-portfolio-key-adjustment__ticker {
    min-width: 120px;
    font-size: 14px;
  }

  .ws-portfolio-key-adjustment__percent {
    font-size: 12px;
    gap: 1px;
    width: auto;
  }

  .ws-portfolio-key-adjustment__change {
    font-size: 12px;
    padding: 3px 8px;
  }

  .ws-portfolio-new-adjustment {
    font-size: 10px;
    padding: 1px 4px;
  }
}

@media (max-width: 1099px) {
  .ws-portfolio-key-adjustments__content {
    height: auto;
  }

  .ws-portfolio-key-adjustments {
    padding-bottom: 16px;
  }
}