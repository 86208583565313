.market-analysis-hero {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.market-analysis-event-video {
  width: calc(70% - 5px);
  height: 700px;
  border: 0;
}
.vp-center {
  display: block !important;
}
.market-analysis-event-chat {
  width: calc(30% - 5px);
  height: 700px;
  border: 0;
}

.market-analysis-presentor-wrapper {
  width: calc(70% - 10px);
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.market-analysis-half-width {
  width: calc(50% - 30px);
}

.market-analysis-presentor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.market-analysis-presentor-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.market-analysis-presentor-detail {
  margin-left: 16px;
}

.market-analysis-presentor-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.market-analysis-presentor-name svg {
  color: #0071ee;
}

.market-analysis-presentor-position {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.market-analysis-event-info {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.market-analysis-event-info-section {
  width: 100%;
}

.market-analysis-event-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.market-analysis-event-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7a7a7a;
  margin-top: 8px;
  min-height: 95px;
}

.market-analysis-event-schedule {
  background: #ffffff;
  border-radius: 12px;
  padding: 8px 20px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.market-analysis-event-schedule-status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7a7a7a;
}

.market-analysis-event-schedule-day,
.market-analysis-event-schedule-time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  color: #000000;
  margin: 5px 0;
}

.market-analysis-event-schedule-reply-msg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7a7a7a;
  margin-top: 15px;
}

@media (min-width: 993px) and (max-width: 1199px) {
  .market-analysis-hero {
    flex-direction: column;
  }

  .market-analysis-event-video {
    width: 100%;
  }

  .market-analysis-event-chat {
    width: 100%;
  }

  .market-analysis-event-info {
    width: 100%;
  }

  .market-analysis-presentor-wrapper {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .market-analysis-presentor-wrapper,
  .market-analysis-event-info {
    width: 100%;
  }

  .market-analysis-half-width {
    width: calc(50% - 10px);
  }
}

@media (max-width: 799px) {
  .market-analysis-hero {
    flex-direction: column;
  }

  .market-analysis-event-video {
    width: 100%;
  }

  .market-analysis-event-chat {
    width: 100%;
  }

  .market-analysis-event-info-section {
    width: 100%;
  }

  .market-analysis-event-schedule-status,
  .market-analysis-event-schedule-day,
  .market-analysis-event-schedule-time {
    font-size: 14px;
  }

  .market-analysis-event-title {
    font-size: 18px;
  }

  .market-analysis-event-description {
    font-size: 14px;
  }

  .market-analysis-event-schedule {
    min-height: 150px;
  }

  .market-analysis-half-width {
    width: calc(50% - 10px);
  }
}

@media (max-width: 699px) {
  .market-analysis-presentor-wrapper {
    flex-direction: column;
  }

  .market-analysis-half-width {
    width: 100%;
  }

  .market-analysis-event-info-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

body.dark {
  .market-analysis-presentor-name {
    color: #ffffff;
  }

  .market-analysis-presentor-name svg {
    color: #0071ee;
    fill: #0071ee;
  }

  .market-analysis-presentor-position {
    color: #ffffff;
  }

  .market-analysis-event-title {
    color: #ffffff;
  }

  .market-analysis-event-description {
    color: #c3c3c3;
  }

  .market-analysis-event-schedule {
    background-color: #363637 !important;
  }

  .market-analysis-event-schedule-status,
  .market-analysis-event-schedule-reply-msg {
    color: #c3c3c3;
  }

  .market-analysis-event-schedule-day,
  .market-analysis-event-schedule-time {
    color: #ffffff;
  }
}
.market-analysis-presentor-position {
  white-space: nowrap;
}
.market-analysis-event-title {
  white-space: nowrap;
}
