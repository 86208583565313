.lite-stock-picks-page {
  width: 100%;
}

.lite-stock-picks__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 24px;
}

.lite-stock-picks__title {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15px;
  background: linear-gradient(130deg, rgba(227, 163, 150, 0.96) 0.53%, rgba(0, 123, 255, 0.96) 230.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lite-stock-picks__subtitle {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  width: 100%;
  max-width: 670px;
}

.lite-stock-picks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow: auto;
}

.lite-stock-picks::-webkit-scrollbar {
  display: none;
}

.lite-stock-pick {
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  box-shadow: 1px 1px 15px 0px rgba(28, 28, 30, 0.04);
  width: calc((100% - 48px) / 4);
  min-width: 255px;
}

.lite-stock-pick__header {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  @media (max-width: 1470px) {
    min-height: 131px;
  }
}

.lite-stock-pick__header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.lite-stock-picks__portfolio_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lite-stock-picks__portfolio_header_left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lite-stock-pick__header_title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-stock-picks__portfolio_button {
  text-transform: none !important;
}

.lite-stock-pick__icon {
  width: 24px;
  height: 24px;
}

.lite-stock-pick__description {
  min-height: 40px;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  width: 100%;
}

.lite-stock-pick__caption {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.lite-stock-pick__tickers {
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}

.lite-stock-pick__ticker {
  display: flex;
  padding: 3px 16px;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &.active {
    background: rgba(0, 123, 255, 0.08);
  }

  &:hover {
    background: rgba(0, 123, 255, 0.04)
  }
}

.lite-stock-pick__ticker_info {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 90px;
}

.lite-stock-pick__ticker_icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.lite-stock-pick__ticker_name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-stock-pick__ticker_price {
  color: rgba(0, 0, 0, 0.60);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-align: right;
  width: 90px;
}

.lite-stock-pick__ticker_add {
  color: #607D8B;
  cursor: pointer;
}

.lite-stock-pick__ticker_added {
  color: #2E7D32;
  cursor: pointer;
  fill-opacity: 1;
}

.lite-stock-picks__content {
  padding-top: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.lite-stock-picks__portfolio {
  width: calc(100% / 3 - 12px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 1px 1px 15px 0px rgba(28, 28, 30, 0.04);
  overflow: hidden;
  height: 872px;
  position: sticky;
  top: 137px;
}

.lite-stock-picks__portfolio_header {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  gap: 8px;
  background: #FFF;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  width: 100%;
}

.lite-stock-picks__portfolio_icon {
  width: 24px;
  height: 24px;
}

.lite-stock-picks__portfolio_th {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #FFF;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  width: 100%;
}

.lite-stock-picks__performance_main_graph {
  width: 100%;
  height: 100%;
}

.lite-stock-picks__portfolio_th_ticker {
  min-width: 80px;
}

.lite-stock-picks__portfolio_th_price {
  min-width: 90px;
}

.lite-stock-picks__portfolio_th_change {
  min-width: 85px;
  white-space: nowrap;

  &.full {
    color: green;
  }

  &.not-full {
    color: red;
  }
}

.lite-stock-picks__portfolio_content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  &.flex-start {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.lite-stock-picks__portfolio_empty {
  width: 220px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-stock-picks__portfolio_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.lite-stock-picks__portfolio_row.removed {
  animation: fadeOut 0.5s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

body.dark .lite-stock-picks__portfolio_row {
  animation: fadeInDark 0.5s ease-in-out;
}

@keyframes fadeInDark {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body.dark .lite-stock-picks__portfolio_row.removed {
  animation: fadeOutDark 0.5s ease-in-out;
}

@keyframes fadeOutDark {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.lite-stock-picks__portfolio_row_ticker {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 80px;
  padding: 16px;

}

.lite-stock-picks__portfolio_row_icon {
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
}

.lite-stock-picks__portfolio_row_name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.lite-stock-picks__portfolio_row_price {
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  min-width: 90px;
  padding: 16px;

}

.lite-stock-picks__portfolio_row_change {
  min-width: 90px;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  padding: 8px 16px;
}

.lite-stock-picks__performance {
  width: calc(100% / 3* 2 - 12px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  height: calc(100vh - 161px);
  position: sticky;
  top: 137px;
  overflow: auto;
}

.lite-stock-picks__performance::-webkit-scrollbar {
  display: none;
}

.lite-stock-picks__performance_back_button {
  padding: 6px 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #007BFF !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  text-transform: initial !important;

  svg {
    color: #007BFF !important;
    fill: #007BFF !important;
  }
}

.lite-stock-picks__performance_ticker_info {
  width: 100%;

  .MuiTabs-flexContainer {
    border-block-end: 2px solid #e4e2e2 !important;
  }
}

.lite-stock-picks__performance_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.lite-stock-picks__performance_main_header {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  justify-content: flex-start;
}

.lite-stock-picks__performance_item {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: baseline;
  flex: 1 0 0;
  border-radius: 8px;
  background: #FFF;
}

.lite-stock-picks__performance_item_title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.lite-stock-picks__performance_item_change {
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;

  &.positive_color {
    color: #4CAF50;
  }

  &.negative_color {
    color: #F44336;
  }
}

.lite-stock-picks__performance_main_graph {
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
}

.lite-stock-graph {
  width: 100%;
}

.lite-stock-picks__performance_main_graph_timestaps {
  width: 100%;
  padding: 0 16px;
}

body.dark {
  .lite-stock-picks__title {
    background: linear-gradient(130deg, rgba(252, 237, 236, 0.96) 0.53%, rgba(0, 158, 255, 0.96) 230.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .lite-stock-picks__subtitle {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-stock-pick {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #2C2C2C;
    box-shadow: 1px 1px 15px 0px rgba(242, 242, 245, 0.08);
  }

  .lite-stock-pick__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .lite-stock-pick__header_title {
    color: #fff;
  }

  .lite-stock-pick__description {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-stock-pick__caption {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-stock-pick__ticker {
    &:hover {
      background: rgba(0, 158, 255, 0.08);
    }

    &.active {
      background: rgba(0, 158, 255, 0.16);
    }
  }

  .lite-stock-pick__ticker_name {
    color: #fff;
  }

  .lite-stock-pick__ticker_price {
    color: rgba(255, 255, 255, 0.70)
  }

  .lite-stock-pick__ticker_add {
    color: #fff;
    fill-opacity: 0.56;
  }

  .lite-stock-pick__ticker_added {
    color: #4CAF50;
  }

  .lite-stock-picks__portfolio {
    background: #363636;
    box-shadow: 1px 1px 15px 0px rgba(242, 242, 245, 0.08);
  }

  .lite-stock-picks__portfolio_header {
    background: #2C2C2C;
    color: #fff;
  }

  .lite-stock-picks__portfolio_th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background: #2C2C2C;
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-stock-picks__portfolio_empty {
    color: #FFF;
  }

  .lite-stock-picks__portfolio_row_name {
    color: #fff;
  }

  .lite-stock-picks__portfolio_row_price {
    color: #fff;
  }

  .lite-stock-picks__portfolio_row_change {
    color: #FFF;
  }

  .lite-stock-picks__performance_back_button {
    color: #009EFF !important;
  }

  .lite-stock-picks__performance_back_button svg {
    color: #009EFF !important;
    fill: #009EFF !important;
  }

  .lite-stock-picks__performance_item {
    background: #2C2C2C;
  }

  .lite-stock-picks__performance_item_title {
    color: #fff;
  }

  .lite-stock-picks__performance_item_change.positive_color {
    color: #A5D6A7;
  }

  .lite-stock-picks__performance_item_change.negative_color {
    color: #EF9A9A;
  }

  .lite-stock-picks__performance_main_graph {
    background: #2E2E2E;
  }

  .lite-stock-picks__portfolio_row_input {
    .MuiOutlinedInput-root {
      background: rgba(255, 255, 255, 0.09) !important;
      
      input {
        color: #FFF !important;
      }
    }
  }
}

@media (max-width: 1399px) {
  .lite-stock-picks__performance_main_header {
    flex-wrap: wrap;
  }

  .lite-stock-picks__performance_item {
    width: calc(50% - 8px);
    flex: 0 0 auto;
  }
}

@media (min-width: 993px) and (max-width: 1250px) {
  .lite-stock-picks__content {
    flex-direction: column;
  }

  .lite-stock-picks__portfolio {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: initial;
    top: 0;
    flex: 0 0 auto;
  }

  .lite-stock-picks__performance {
    width: 100%;
    position: relative;
    top: 0;
    height: auto;
    overflow: initial;

    .lite-stock-picks__performance_back_button {
      display: none !important;
    }

    .ws-portfolio-ticker-info__header {
      top: 49px;
    }
  }
}

@media (max-width: 991px) {
  .lite-stock-picks__content {
    flex-direction: column;
  }

  .lite-stock-picks__portfolio {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: initial;
    top: 0;
    flex: 0 0 auto;
  }

  .lite-stock-picks__performance {
    width: 100%;
    position: relative;
    top: 0;
    height: auto;
    overflow: initial;

    .lite-stock-picks__performance_back_button {
      display: none !important;
    }

    .ws-portfolio-ticker-info__header {
      top: 49px;
    }
  }
}

@media (max-width: 768px) {
  .lite-stock-picks__performance_main_header {
    flex-wrap: wrap;
  }

  .lite-stock-picks__performance_item {
    width: calc(50% - 8px);
    flex: 0 0 auto;
  }

  .lite-stock-picks__performance {
    .ws-portfolio-ticker-info__header {
      top: 34px;
    }
  }
}

@media (max-width: 410px) {
  .lite-stock-picks__performance_item {
    width: 100%;
  }

  .lite-stock-picks__portfolio_header {
    flex-direction: column;
  }

  .lite-stock-picks__portfolio_buttons {
    width: 100%;

    button {
      width: 50% !important;
    }
  }
}

.lite-stock-pick__ticker_change {
  &.full {
    color: green;
  }

  &.not-full {
    color: red;
  }
}

.full {
  color: green;
}

.not-full {
  color: red;
}

.lite-portfolio-tooltip-icon {
  fill: #000;
  fill-opacity: 0.56;
  cursor: pointer;
}

.custom-portfolio-tooltip {
  border-radius: 8px !important;
  background-color: #FFF !important;
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20) !important;
  padding: 16px !important;

  .MuiTooltip-arrow {
    color: #FFF !important;
  }
}

.portfolio-tooltip {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 12px !important;
}

.portfolio-tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
}

.portfolio-tooltip-header {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.portfolio-tooltip-description {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.portfolio-tooltip-link {
  padding: 4px 5px;
  color: #007BFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
}

body.dark {
  .lite-portfolio-tooltip-icon {
    fill: #fff;
  }

  .custom-portfolio-tooltip {
    background: #2C2C2C !important;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20) !important;

    .MuiTooltip-arrow {
      color: #2C2C2C !important;
    }
  }

  .portfolio-tooltip-header {
    color: #fff;
  }

  .portfolio-tooltip-description {
    color: #fff;
  }

  .portfolio-tooltip-link {
    color: #009EFF;
  }

  .lite-stock-picks__portfolio_button {
    color: #009EFF !important;

    &:disabled {
      color: rgba(255, 255, 255, 0.38) !important;
    }
  }
}