.ws-portfolio-news {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 4px;
}

.ws-portfolio-news__header {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.ws-portfolio-news-logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.ws-portfolio-news__header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.ws-portfolio-news-summarized {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-news-timestamp {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.ws-portfolio-news__content {
  width: 100%;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  height: 300px;
  overflow: auto;
}

.ws-portfolio-news-item {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:last-child {
    .ws-portfolio-news-footer {
      margin-bottom: 0;
    }
  }
}

.ws-portfolio-news-date {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-news-footer {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #007BFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    flex: 0 0 auto;
  }
}

body.dark {
  .ws-portfolio-news-summarized {
    color: #FFF;
  }

  .ws-portfolio-news-timestamp {
    color: #FFF;
  }

  .ws-portfolio-news__content {
    color: #FFF;
  }

  .ws-portfolio-news-date {
    color: #FFF;
  }

  .ws-portfolio-news-footer a {
    color: #009EFF;
  }
}
