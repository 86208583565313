* {
  box-sizing: border-box;
}

// Darkmode variables
$main: #001128;
$table: #001528;
$text: #fffffa;
$secondaryText: #cfd1ff;
//$background: #181818;
$background: #f8fafc;
$contrastBackground: #202020;
$chartBackground: #202020;
$boxShadow: 0 -2px 10px rgba(0, 0, 0, 1);
$hover: #929191;
$seperator: #363637;
$backgroundColorScroll: var(--background-color-scroll);
$direction-map: null;
$direction-map: null;
$theme-map: null;
$themes: (
  light: (colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    colorHeaderDataPicker: #6db0ff,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../../shared/img/logo/logo_light.svg),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: aliceblue,
  ),
  dark: (colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker: #063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1a,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
  ),
);

.nowrap {
  white-space: nowrap;
}

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: (
        ) !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}"
        );
      $theme-map: map-merge($theme-map,
          ($key: $value,
          )) !global;
    }

    @content;
    $theme-map: null !global;
  }
}
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #007bff;
$color-light-accent: #55e5bb;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: rgba(130, 119, 223, 1);

$color-red: #ff4861;
$color-dark-red: #721c24;
$color-light-red: #f88e7d;
$color-very-light-red: #f8d7da;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-light-blue: #87c3f7;
$color-blue-hover: darken($color-blue, 10%);

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-dusty-white: #dddddd;
$color-white: #ffffff;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-dark-fields-border: #33333a;
$color-black-background: #232329;
$color-background-body: #f2f4f7;
$color-text: #646777;
$color-hover: #fafbfe;

$directions: (
  ltr: (direction: ltr,
    right: right,
    left: left,
    margin-right: "margin-right",
    margin-left: "margin-left",
    padding-left: "padding-left",
    padding-right: "padding-right",
    border-top-left-radius: "border-top-left-radius",
    border-bottom-left-radius: "border-bottom-left-radius",
    border-top-right-radius: "border-top-right-radius",
    border-bottom-right-radius: "border-bottom-right-radius",
    border-left: "border-left",
    border-right: "border-right",
    translate: "translate(-50%, -50%);",
    mirrorY: "scale(1, 1)",
    border-right-color: "border-right-color",
    transform-sidebar: "rotate(90deg)",
    flex-flow: row nowrap,
    row: "row",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    "-": "-",
  ),
  rtl: (direction: rtl,
    right: left,
    left: right,
    margin-right: "margin-left",
    margin-left: "margin-right",
    padding-left: "padding-right",
    padding-right: "padding-left",
    border-top-left-radius: "border-top-right-radius",
    border-bottom-left-radius: "border-bottom-right-radius",
    border-top-right-radius: "border-top-left-radius",
    border-bottom-right-radius: "border-bottom-left-radius",
    border-left: "border-right",
    border-right: "border-left",
    translate: "translate(50%, -50%);",
    mirrorY: "scale(1, -1)",
    border-right-color: "border-left-color",
    transform-sidebar: "rotate(-90deg)",
    flex-flow: row-reverse nowrap,
    row: "row-reverse",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    "-": "+",
  ),
);

@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: (
        ) !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), "#{$key}"
        );
      $direction-map: map-merge($direction-map,
          ($key: $value,
          )) !global;
    }

    @content;
    $direction-map: null !global;
  }
}
}

@function directed($key) {
  @return map-get($direction-map, $key);
}

.flip-vertical {
  transform: scaleY(-1);
}

.unusual {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

.card-box-shadow {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.04), 0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 11px 15px -7px rgba(0, 0, 0, 0.04) !important;
}

.nav {
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
  }
}

.tabtext {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #a2a3a5;
}

.tabs {
  overflow: auto;

  .tabs__wrap {
    @include directify($directions) {
      text-align: directed("left");
    }

    min-width: min-content;
    //-webkit-min-width: min-content;
  }

  .nav-tabs {
    border-bottom: none;

    li {
      .nav-link {
        display: block;
        padding: 10px 20px;

        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }

        transition: background-color 0.3s;
        cursor: pointer;

        @include themify($themes) {
          color: themed("colorText");
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;

          @include themify($themes) {
            background-color: themed("colorHover");
            border-color: themed("colorHover");
            border-bottom-color: $color-dusty-white;
          }
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: transparent;

        @include themify($themes) {
          border-color: $color-dusty-white;
          border-bottom-color: themed("colorBackground");
          color: themed("colorText");
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    padding-top: 20px;
  }

  &.tabs--bordered-top {
    .nav-tabs {
      li .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      li .nav-link.active {

        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-top: 2px solid $color-accent;
        }
      }
    }
  }

  &.tabs--bordered-bottom {
    .nav-tabs {
      li .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 11px;
      }

      li .nav-link.active {

        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-color: transparent;
          border-bottom: 4px solid $color-accent;
          padding-bottom: 10px;
        }
      }
    }
  }

  &.tabs--justify {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;

      li {
        // width: 155px;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: auto;

    .tabs__wrap {
      display: flex;
    }

    .nav-tabs {
      border-bottom: none;
      width: 90px;
      height: 100%;
      flex-wrap: wrap;

      .nav-item {
        margin-bottom: 0;
      }

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          @include directify($directions) {
            #{directed('padding-right')}: 10px;
            #{directed('padding-left')}: 0px;
            #{directed('margin-right')}: 0px;
            #{directed('border-right')}: 2px solid #dddddd;
          }

          padding-top: 10px;
          padding-bottom: 10px;
          transition: background-color 0.3s;
          border-radius: 0;
          border: none;

          @include themify($themes) {
            color: themed("colorText");
          }

          &:hover,
          &:focus,
          &:active {
            outline: none;

            @include themify($themes) {
              background-color: themed("colorHover");
            }
          }
        }

        .nav-link.active,
        .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;

          @include directify($directions) {
            #{directed('border-right-color')}: $color-accent;
          }
        }

        &.disabled .nav-link {
          color: $color-additional;
        }
      }
    }

    .tab-pane {
      padding-top: 0;

      @include directify($directions) {
        #{directed('padding-left')}: 20px;
      }
    }

    &.tabs--vertical-colored {
      .nav-tabs {
        li {
          .nav-link {
            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }

            @include directify($directions) {
              #{directed('padding-left')}: 10px;
            }

            text-align: center;
          }

          .nav-link.active,
          .nav-link.active:focus,
          .nav-link.active:hover {
            background-color: #eeeeee;

            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }

            color: $color-text;
          }
        }
      }
    }
  }
}

.MuiModal-root {
  z-index: 11111;

  .MuiInputBase-multiline {
    .MuiInputBase-input {
      color: black;
    }
  }
}

.unusual-activity-table.table thead th {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
}

.tradegpt-link a {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  /* Vertically center the items */
  white-space: nowrap;
  overflow: hidden;
  /* Prevent wrapping due to overflow */
  flex-wrap: nowrap;
}

li.active {
  .new-badge {
    background-color: black;
  }
}

.new-badge {
  width: 36px;
  height: 21px;
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  background: #007bff;
  background-color: #007bff !important;
  color: white;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.006px;
  margin-left: 14px;
  /* Add some space between the badge and preceding elements */
}

.max-index {
  z-index: 161;
}

.SnackbarContainer-root {
  z-index: 1111111 !important;
}

.error-boundary-container {
  height: 100vh;
}

.text-center {
  text-align: center !important;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mt-30 {
  margin-top: 30px;
}

.free-tier-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.free-tier-icon {
  stroke: none !important;
  fill: #000000 !important;
}

li.active svg.free-tier-icon {
  fill: #fff !important;
}

li.active .upgrade-button {
  background: #000000;
}

.upgrade-button {
  width: auto;
  position: absolute;
  right: 20px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 20px;
  background: #007bff;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.006px;

  svg {
    width: 16px !important;
    height: 16px !important;
    stroke: none !important;
    margin: 0 !important;
  }
}

.appWrapper {
  background-color: #f8fafc;
}

.cant-see {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.pb-8 {
  padding-bottom: 8px;
}

.MuiDrawer-root {
  z-index: 1000000 !important;

  .MuiButton-outlined {
    background: #5eeecd1a;
    color: white;
    text-transform: none;
    height: 40px;
    width: 177px;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  overflow: visible !important;
}

.mr-31 {
  margin-right: 31px;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.home-title {
  svg {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.g-30 {
  gap: 30px;
}

.g-40 {
  gap: 40px;
}

.g-20 {
  gap: 20px;
}

.mw-n {
  max-width: none !important;
}

.br-10 {
  border-radius: 10px !important;
}

.w-70 {
  width: 70%;
}

.w-70px {
  width: 70px;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}

.zsiq_floatmain {
  display: none !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mw-930 {
  max-width: 930px;
}

.mw-800 {
  max-width: 800px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.pl-20 {
  padding-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.white-bg {
  background-color: rgb(255, 255, 255) !important;
}

.swinging-suggest-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 46px;
  color: black;
}

.find-out-more {
  position: fixed;
  top: 67px;
  right: 0px;

  .left-arrow {
    height: 32px;
    position: relative;
    right: 6px;
    top: -1px;
  }

  .MuiButton-outlined {
    background: #5eeecd1a;
    color: white;
    text-transform: none;
    padding-right: 20px;
    height: 40px;
    width: 177px;
    padding-top: 5px;
    border: none;
  }
}

.MuiBadge-badge {
  background: red !important;
  color: white;
  height: 20px;
}

.MuiSlider-valueLabelOpen {
  background: #75757582 !important;
}

.badge-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.SnackbarContainer-bottom {
  bottom: 106px !important;
  cursor: pointer;
}

.open-popover {
  overflow: hidden !important;
}

.sendbird-dropdown__menu {
  z-index: 1000000000 !important;
}

.sendbird-icon-members {
  display: none !important;
}

.MuiFab-root {
  background-color: #8d4ef9 !important;
}

.actions {
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    border-color: black !important;
    font-weight: 500;
    color: black !important;
  }
}

.chat-icon {
  width: 24px;
  height: 24px;
}

.chat-span-bottom {
  width: 84px;
  height: 15px;

  /* Trade algo web/2xs/regular */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* gray/400 */

  color: #a1a1aa;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.chat-span {
  width: 35px;
  height: 21px;

  /* Trade algo web/sm/bold */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Grays/50 */

  color: #39393d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.chat-inner-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 84px;
  height: 36px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.mui-search-dropdown-label {
  min-width: 80px;
  text-align: left;
}

.custom-chat {
  width: 45px !important;
  height: 45px !important;
  border-radius: 12px !important;
  border: none !important;
}

.tgpt-submenu {
  svg {
    color: grey !important;
  }
}

.menu-item-icon {
  svg {
    color: grey !important;
  }
}

.chat-div {
  cursor: pointer;
  height: 50px;
  width: 176px;
  display: inline-flex;
  padding-left: 10px;
  justify-content: space-around;
  align-items: center;
  border-radius: var(--borderRadius-xxl, 5000px);
  border: var(--none, 1px) solid var(--Gradient-Platinum-outlinedBorder, #5EEECD);
  background: var(--TA-background-main, #F8FAFC);
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  padding-right: 10px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}

.chat-div:hover {
  background: linear-gradient(88deg,
      rgba(94, 238, 205, 0.04) 0%,
      rgba(0, 123, 255, 0.04) 100%),
    var(--TA-background-main, #F8FAFC);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2),
    0px 8px 15px rgba(0, 0, 0, 0.15);
}

.tradegpt-text {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-family: var(--Inter, Inter);
  font-size: var(--font-size-09375-rem, 15px);
  font-weight: var(--Medium, 500);
  line-height: 26px;
  letter-spacing: 0.46px;
  transition: color 0.3s ease-in-out;
}

*::-webkit-scrollbar {
  width: 15px;
  background-color: inherit;
}

*::-webkit-scrollbar-track {
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #888;
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
  cursor: pointer;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.MuiPaper-rounded {
  overflow-y: auto !important;
}

.trade-algos-darkflow-card {
  overflow: hidden !important;
}

.disabled-link {
  cursor: pointer !important;
  pointer-events: none !important;
}

.custom-snackbar {
  border-radius: 8px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
  color: #000 !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  letter-spacing: -0.08px !important;

  #notistack-snackbar svg {
    color: #2E7D32 !important;
  }

  &.ws-lite-snackbar {
    color: rgba(0, 0, 0, 0.87) !important;
    border: none !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 166% !important;
    letter-spacing: 0.4px !important;
    border: none !important;
    border-radius: 5000px !important;
    background-color: #F2F2F5 !important;
    padding: 8px 16px !important;

    #notistack-snackbar {
      padding: 0 !important;
    }

    &.warning {
      #notistack-snackbar svg {
        color: #EF6C00 !important;
      }
    }

    &.error {
      #notistack-snackbar svg {
        color: red !important;
      }
    }
  }
}

body {
  .MuiPopover-paper {
    pointer-events: all;
  }

  .dxfeed-radar-symbol-modal {
    position: relative;
    top: 22%;
    left: 10.5%;
  }

  .ant-modal-mask {
    background-color: #6f6f6f3b !important;
  }

  .MuiDivider-root {
    border-color: rgb(111 250 130 / 88%) !important;
  }

  a:hover {
    text-decoration: none !important;
  }

  .spark-row {
    position: relative;
    left: -9px;
    width: 100%;

    @media (max-width: 1400px) {
      // left: -56px !important;
    }
  }

  .rc-virtual-list {
    position: relative;
    top: 5px;
  }

  .graph-red,
  .graph-red {
    stroke: rgb(252, 22, 22);
  }

  .graph-green,
  .graph-green {
    stroke: rgb(51, 251, 67);
  }

  .highcharts-tooltip {
    .areyu {
      fill: black;
    }
  }

  .drag-text {
    color: black;
    font-weight: bold;
  }

  .ant-dxfeed-modal-mask {
    background-color: inherit !important;
  }

  .MuiButton-containedPrimary {
    background-color: black;
    color: white;
  }

  .MuiButton-startIcon {
    .MuiSvgIcon-root {
      fill: white;
      color: white;
    }
  }

  .card {
    border-radius: 16px;
    border: none !important;
  }

  .sendbird-fileviewer {
    background: white !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
  }

  .sendbird-openchannel-conversation-scroll__container__item-container {
    overflow-x: hidden !important;
  }

  .ai-table-score {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(62, 180, 137, 0.4);
    width: 55px;
    height: 24px;
  }

  .ai-table-score.negative-score {
    background: rgba(255, 59, 48, 0.4);
  }

  .welcome-message-box {
    max-width: 360px;
    position: fixed;
    bottom: 80px;
    right: 33px;
    background: var(--background-paper-elevation-8, #FFF);
    border-radius: var(--borderRadius-s, 4px);
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 5px 5px -3px rgba(0, 0, 0, 0.20);
    padding: 16px;
    z-index: 1001;
    padding-bottom: 2px;
  }

  .welcome-message-content {
    position: relative;
  }

  .welcome-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome-new-badge {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 100px;
    background: var(--primary-blue-main, #007BFF);
    color: var(--primary-blue-contrast, #FFF);
    width: 50px;
    height: 25px;
    /* chip/label */
    font-family: var(--Inter, Inter);
    font-size: var(--font-size-08125-rem, 13px);
    font-style: normal;
    font-weight: var(--Regular, 400);
    line-height: 18px;
    /* 138.462% */
    letter-spacing: 0.16px;
  }

  .welcome-title {
    color: black;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    /* typography/body1/semibold-600 */
    font-family: var(--Inter, Inter);
    font-size: var(--fontSize-1rem, 16px);
    font-style: normal;
    font-weight: var(--Semibold, 600);
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.15px;
  }

  .inside-header {
    display: flex;
    flex-direction: row;
  }

  .welcome-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 29px;
    opacity: 0.7;
  }

  .welcome-description {
    margin-top: 12px;
  }

  .blurred {
    filter: blur(8px)
  }

  .welcome-carrot {
    position: absolute;
    bottom: -8px;
    right: 7px;
    /* Adjust to align properly */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--background-paper-elevation-8, #FFF);
  }

  .sidebar-separator {
    border-color: #0000001F !important;
  }

  .sidebar-premium-title {
    padding-left: 23px;
    padding-bottom: 7px;
    padding-top: 7px;
    color: var(--text-disabled, rgba(0, 0, 0, 0.38)) !important;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: var(--Inter, Inter);
    font-size: var(--fontSize-semantic-overline, 12px);
    font-style: normal;
    font-weight: var(--Regular, 500);
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &.dark {
    .sidebar-premium-title {
      color: var(--text-disabled, rgba(255, 255, 255, 0.38)) !important;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--Inter, Inter);
      font-size: var(--fontSize-semantic-overline, 12px);
      font-style: normal;
      font-weight: var(--Regular, 400);
      line-height: 266%;
      /* 31.92px */
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .sidebar-separator {
      border-color: #ffffff1F !important;
    }

    .MuiPagination-ul {
      li {
        button {
          color: white;
        }

        button.Mui-selected {
          background-color: black;
        }
      }
    }

    .MuiAccordion-root.MuiAccordion-rounded {
      .MuiAccordionSummary-expandIconWrapper {
        color: white !important;
      }

      background-color: black;
    }

    .MuiAccordionSummary-content {
      color: white;
    }

    .MuiSkeleton-root {
      background-color: rgb(82 82 82) !important;
    }

    .MuiPopover-root {
      .align-mail {
        color: white;
      }
    }

    .toggle-menu-item {
      svg {
        stroke: white;

        path {
          stroke: white;
          fill: white;
        }

        &.new-layout-icon path {
          fill: none;
        }
      }
    }

    #sidebar-menu {
      .trading-tools {
        path {
          fill: white;
        }
      }

      .resources-icon {
        path {
          stroke: white;
          fill: white;
        }
      }

      .ta-sidebar-icon {
        path {
          stroke: white;
          fill: white;
        }
      }

      ul {
        li:hover {
          span {
            color: white;
          }
        }

        li {
          a {
            svg {
              stroke: white;
            }

            svg.ta-products-icon {
              stroke: none;

              path {
                fill: #fff;
                stroke: none;
              }
            }
          }
        }
      }
    }

    .welcome-message-box {
      background: #00070e;

      .welcome-title {
        color: white;
      }

      .welcome-close-btn {
        color: white;
      }

      .welcome-description {
        color: white;
      }

      .welcome-carrot {
        border-top: 8px solid var(--background-paper-elevation-8, #00070e);
      }
    }

    .MuiDataGrid-row:nth-child(even) {
      background: #00070e;
    }

    .rules-list {
      background-color: black !important;

      .MuiSvgIcon-root {
        color: white !important;
      }
    }

    .close-modal {
      color: white !important;
    }

    .rules-button {
      .MuiSvgIcon-root {
        fill: white !important;
      }
    }

    .overall-sentiment {
      background-color: black;
    }

    .sentiment-overall {
      color: white;
    }

    .bb-text {
      color: white;
    }

    .ai-probability {
      color: white;
    }

    .news-section {
      background-color: black;
    }

    .events-title {
      color: white;
    }

    .published {
      color: white;
    }

    .brian {
      color: black !important;
    }

    .appWrapper {
      background-color: rgb(28, 30, 32);
    }

    &.wealth-series-atlas,
    &.wealth-series-titan,
    &.wealth-series-everest,
    &.wealth-series-lite,
    &.wealth-series-lite-signup {
      .appWrapper {
        background-color: #3C3C3F;

        .sidebar-layout-content {
          background-color: #3C3C3F !important;
        }

        .content-in-layout {
          background-color: #3C3C3F !important;
        }
      }
    }

    .home-title {
      color: white !important;
    }

    .card {
      border-radius: 16px;
      border: none !important;
      background-color: transparent !important;
    }

    .text-fix {
      color: white;
    }

    .chat-div {
      border-radius: var(--borderRadius-xxl, 5000px);
      border: var(--none, 1px) solid var(--Gradient-Platinum-outlinedBorder, #ABF6E0);
      background: var(--TA-background-main, #3C3C3F);
      box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 3px 5px -1px rgba(0, 0, 0, 0.2);
    }

    .chat-div:hover {
      background: #2c2c2f;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25),
        0px 8px 15px rgba(0, 0, 0, 0.18);
    }

    .chat-span {
      color: #f0f0ff;
    }

    .tradegpt-text {
      color: #f0f0ff;
    }

    .chat-div {
      background: #0a0a0a;
    }

    .dmode-white {
      fill: white !important;
    }

    .menu-item-icon {
      svg {
        color: white !important;
      }
    }

    .actions {
      .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
        border-color: white !important;
        font-weight: 500;
        color: white !important;
      }
    }

    .MuiButton-containedPrimary {
      background-color: white;
      color: black;
    }

    .MuiButton-startIcon {
      .MuiSvgIcon-root {
        fill: black;
        color: black;
      }
    }

    .MuiButton-endIcon {
      .MuiSvgIcon-root {
        fill: black;
        color: black;
      }
    }

    .actions .btn-outline {
      border-radius: 20px;
      border-color: #a2a2a2 !important;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff !important;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-align: right;
    }

    .ant-dxfeed-modal-mask {
      background-color: rgb(0 0 0 / 37%) !important;
    }

    .css-1tape97 {
      background-color: black;
    }

    .MuiPickersArrowSwitcher-root {
      background-color: #202020 !important;
    }

    .drag-text {
      color: white;
      font-weight: bold;
    }

    .MuiListItem-gutters>.MuiSvgIcon-root {
      fill: #fff;
    }

    .simplebar-placeholder {
      border-right: 1px solid black;
    }

    .simplebar-content {
      .MuiList-root {
        background-color: rgba(21, 23, 23, 0.5) !important;
      }

      .MuiMenu-list {
        background-color: rgba(21, 23, 23, 0.5) !important;
      }
    }

    background-color: #1b1d1e !important;

    &.wealth-series-atlas,
    &.wealth-series-titan,
    &.wealth-series-everest {
      background-color: #3C3C3F !important;
    }

    .caret.up {
      border-bottom-color: $contrastBackground;
    }

    .MuiTabs-flexContainer {
      border-block-end: 2px solid $hover;
    }

    .MuiTab-root {
      min-width: 8px !important;
    }

    .editorial-content>h3 {
      color: $text;
    }

    .embedded-dark-pool-page .content .dark-pool-datatable-container .dark-pool-datatable tbody tr:nth-child(odd) {
      background-color: $background;
    }

    .PhoneInputInput {
      background-color: $contrastBackground;
    }

    .cathie-wood-widget .phone-number-field {
      color: $text;
    }

    .cathie-wood-widget .select-option .ant-radio-wrapper span {
      color: $text;
    }

    .negative-text-color {
      color: #dc3545 !important;
    }

    .positive-text-color {
      color: #20c997 !important;
    }

    .line-clamp-one-line text-light-black {
      color: $text;
    }

    .text-light-black {
      color: $text;
    }

    .holdings>h5 {
      color: $text;
    }

    .custom-filter-box .small-label {
      color: $text;
    }

    .company-owner-popover .ant-popover-inner .ant-popover-title {
      color: $text;
    }

    .btn-primary .text-bold,
    .btn-outline .text-bold {
      color: $text;
    }

    .text-bold {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 21px;
      color: #13111a;
    }

    .closedHours {
      filter: invert(1);
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: $hover;
    }

    .ant-dropdown-menu {
      background-color: $contrastBackground;
      color: $text;

      svg {
        fill: $text;
        color: $text;
        stroke: $text;
      }
    }

    .ant-radio-button-wrapper {
      border: 1px solid $text !important;
    }

    .stock-wall-titan-card>.hidden-info>.bottom>h2 {
      color: $text;
    }

    .stock-wall-titan-card>.info>h1.titan {
      color: $text;
    }

    .ant-select-arrow {
      color: $text;
    }

    .mobile-nav {
      background-color: $main;
    }

    .pin-active {
      background-color: $background;

      &:hover {
        background-color: $background;
      }

      &:focus {
        background-color: $contrastBackground;
      }
    }

    .nav-tabs {
      .nav-item>a {
        color: $text;
      }

      .nav-link {
        color: $text;
        background-color: $contrastBackground !important;
      }

      .nav-link:hover {
        color: $secondaryText;
      }

      .nav-link.active {
        background-color: $seperator !important;
        color: $text;
      }
    }

    .nav-tabs-dark {
      .nav-item-dark>a {
        color: $text;
      }

      .nav-link-dark {
        color: $text;
        background-color: rgba(25, 27, 28, 0.5) !important;
      }

      .nav-link-dark:hover {
        color: $secondaryText;
      }

      .nav-link-dark.active {
        background-color: $seperator !important;
        color: $text;
      }
    }

    .ant-popover-content .ant-popover-inner {
      background: $hover;
      box-shadow: none !important;
      color: $text;
    }

    .ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
      border: $contrastBackground;
    }

    .custom-filter-box {
      .options-wrapper .option-value {
        background: $hover;
        color: $text;
      }

      .item {
        background: $hover;
        color: $text;
      }

      .custom-filter-box .small-label {
        background: $hover;
        color: $text;
      }
    }

    .search-wrapper .ant-select-selection-search-input {
      background: $contrastBackground;
      color: $text;
      // border: 1px solid $hover;
      // background: rgba(255, 255, 255, 0.5);
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid $hover;
      // color: #B7B4C7;
      // border: none;
    }

    .box-shadow-default {
      box-shadow: none !important;
    }

    .stock-wall-etf-card {
      background-color: $contrastBackground;
      box-shadow: $boxShadow;
      color: $text;
    }

    .portfolio-information {
      box-shadow: $boxShadow !important;
    }

    .portfolio-information-label {
      color: $text;
    }

    .portfolio-label {
      color: $text !important;
    }

    .portfolio-value {
      color: $text !important;
    }

    .ant-input-affix-wrapper {
      background-color: $hover;
    }

    .ant-table-thead>tr>th {
      background-color: $contrastBackground !important;
    }

    .account-layout>.left-menu>.ant-menu-inline {
      background-color: $contrastBackground;
    }

    .desktop-institution-holding,
    .mobile-institution-holding {
      background-color: $contrastBackground;
      color: $text;
    }

    .ant-btn-group>.ant-btn:last-child:not(:first-child),
    .ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
      background-color: $contrastBackground;
      color: $text;
    }

    .ant-btn .anticon {
      color: $text;
    }

    .ant-menu-item {
      color: $text;
    }

    .pin-btn.ant-btn {
      background-color: $contrastBackground;
    }

    .pin-btn.ant-btn:hover {
      background-color: $contrastBackground;
      color: $text;
    }

    .highcharts-background {
      fill: $chartBackground;
    }

    .highcharts-menu-wrapper {
      background-color: $chartBackground;
    }

    .ant-radio-button-wrapper {
      background-color: $contrastBackground;
      color: $text;
      border: $contrastBackground;
    }

    .ant-radio-button-wrapper:hover {
      background-color: $hover;
    }

    .ant-radio-button-wrapper-checked {
      background-color: $hover;
    }

    .highcharts-bindings-wrapper li.highcharts-separator>span.highcharts-menu-item-btn {
      display: none;
    }

    .highcharts-annotation-shapes>* {
      stroke: $secondaryText;
      stroke-width: 1;
    }

    .highcharts-bindings-wrapper li>span.highcharts-menu-item-btn {
      background-color: $contrastBackground;
      border: 1px solid $background;
    }

    .highcharts-bindings-wrapper li:hover,
    .highcharts-submenu-item-arrow:hover {
      background-color: $contrastBackground;
    }

    .highcharts-bindings-wrapper .highcharts-submenu-wrapper {
      background-color: $background;
    }

    .highcharts-toggle-toolbar {
      background-color: $background;
    }

    .highcharts-scrollbar-thumb {
      fill: $hover;
      stroke: $hover;
    }

    .highcharts-scrollbar-track {
      fill: $background;
      stroke: $hover;
    }

    .highcharts-scrollbar-button {
      stroke: $hover;
      fill: $background;
    }

    .highcharts-scrollbar-arrow {
      fill: $text;
    }

    .highcharts-axis-line {
      stroke: $hover;
    }

    .highcharts-navigator-outline {
      stroke: $hover;
    }

    .highcharts-navigator-handle {
      stroke: $secondaryText;
      fill: $hover;
    }

    .highcharts-navigator-series {
      .highcharts-point {
        fill: $hover;
      }
    }

    .bg-white {
      background: $contrastBackground !important;
      color: $text;

      &.box-shadow-secondary {
        box-shadow: none;
      }
    }

    .stock-wall-tabs>li>button {
      background-color: $hover;
      box-shadow: none;
    }

    .stock-wall-tabs>li>button.active {
      background-color: blue;
    }

    .stock-wall-stock-card>.info>h3 {
      color: $text;
    }

    .stock-wall-card:hover * {
      text-decoration: none;
    }

    .stock-wall-card h1,
    .stock-wall-card h2,
    .stock-wall-card h3,
    .stock-wall-card h4 {
      color: $text;
    }

    .stock-wall-stock-card>.hidden-info>.top>h1,
    .stock-wall-stock-card>.hidden-info>.top>h2,
    .stock-wall-stock-card>.hidden-info>.top>h3 {
      color: $text;
    }

    .stock-wall-stock-card>.hidden-info>.today>h1,
    .stock-wall-stock-card>.hidden-info>.today>h2,
    .stock-wall-stock-card>.hidden-info>.today>h3 {
      color: $text;
    }

    .stock-wall-stock-card>.hidden-info>.date>h1,
    .stock-wall-stock-card>.hidden-info>.date>h2,
    .stock-wall-stock-card>.hidden-info>.date>h3 {
      color: $text;
    }

    .stock-wall-card {
      box-shadow: none;
    }

    .user-activity-wall {
      display: flex;
      align-items: center;
      background-color: $contrastBackground;
      border-radius: 20px;
      padding: 18px;
      margin-bottom: 28px;
      justify-content: space-between;
      min-height: 90px;
      box-shadow: $boxShadow;
    }

    .user-activity-wall>.stat-wrapper>.title {
      color: #9ea7b3;
      font-size: 18px;
      margin-right: 35px;
      font-weight: 700;
    }

    .stat-wrapper {
      display: flex;
      align-items: center;
    }

    .user-activity-wall>.stat-wrapper>.stat {
      display: flex;
      align-items: center;
      /*border-right: 1px solid #e7e9ec;*/
      min-height: 34px;
    }

    .user-activity-wall>.stat-wrapper>.stat>.title.first {
      margin-left: 0;
    }

    .user-activity-wall>.stat-wrapper>.stat+.last {
      border-right: none;
    }

    .user-activity-wall>.stat-wrapper>.stat>.title {
      color: #9ea7b3;
      font-size: 15px;
      line-height: 16px;
      text-align: left;
      margin-right: 24px;
      margin-left: 24px;
    }

    .user-activity-wall>.stat-wrapper>.stat>.percent {
      color: #6dc92f;
      font-size: 20px;
      font-weight: 700;
      /*margin-right: 23px;*/
      margin-right: 0;
    }

    .user-activity-wall>.stat-wrapper>.stat>.percent.red {
      color: #ef5656;
    }

    .user-activity-wall>div>.dropdown>button {
      background: none;
      color: white;
      border: none;
      font-size: 18px;
      padding: 0;
      font-weight: 500;
    }

    .user-activity-wall>div>.dropdown>button:focus {
      color: white !important;
    }

    .user-activity-wall>div>.dropdown>button:active {
      border: none !important;
      box-shadow: none !important;
      outline: none;
      background: none !important;
      color: white !important;
    }

    .user-activity-wall>div>.dropdown>button:after {
      color: #0071ee;
      margin-left: 20px;
    }

    .user-activity-wall>.switchers {
      display: none;
    }

    .user-activity-wall>.switchers>button {
      color: #9ea7b3;
      font-size: 16px;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font-weight: 300;
    }

    .user-activity-wall>.switchers>button:focus {
      outline: none;
    }

    .user-activity-wall .switchers button:first-child {
      margin-right: 15px;
    }

    .btn-primary.follow-btn.follow-active.follow-theme-white,
    .btn-primary.follow-btn.follow-theme-white {
      background-color: $hover !important;
      padding: 0 !important;
      border: 1px solid $hover !important;
    }

    .btn-primary.follow-btn.follow-active.follow-theme-white:focus,
    .btn-primary.follow-btn.follow-theme-white:focus,
    .btn-primary.follow-btn.follow-active.follow-theme-white:active,
    .btn-primary.follow-btn.follow-theme-white:active {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid $hover !important;
    }

    .follow-theme-white span.btn-icon {
      color: #127bef !important;
      font-weight: 500 !important;
    }

    .follow-theme-white span.btn-label {
      font-size: 18px !important;
      line-height: 23px !important;
      color: white !important;
      text-transform: initial !important;
      font-weight: 400 !important;
    }

    .black-color {
      color: $text !important;
    }

    section>main {
      background-color: $background;
    }

    .main-content-light {
      background-color: $background !important;
    }

    .main-content-dark {
      background-color: #1c1e20 !important;
    }

    .insider-header .insider-icon .fa-chart-line {
      transform: none;
      margin-top: -25px;
      background-color: $background;
      box-shadow: none;
      border: 1px solid $secondaryText;
    }

    .tag-link {
      color: $text !important;
    }

    .page-link {
      color: $text;
      background-color: $background;

      &.disabled {
        color: $text;
        background-color: $background;
      }
    }

    .btn-light {
      //background-color: $contrastBackground;
      //color: $text;
      background-color: #ffffff;
      color: #13111a;
    }

    .btn-default {
      background-color: $background !important;
      color: $text !important;

      &.active {
        background-color: $background !important;
        color: $secondaryText !important;
      }
    }

    .ant-table-body {
      background-color: $contrastBackground;
    }

    .ant-table-content {
      background-color: $contrastBackground;
    }

    .ant-table-cell {
      background-color: $contrastBackground;
      color: $text;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
      background-color: $hover;
      color: $text;
    }

    .ant-input {
      background-color: $hover !important;
      color: $text !important;
    }

    .ant-input-group-addon {
      background-color: $background;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $hover;
      color: $text;
    }

    .ant-select-dropdown {
      // background-color: $background;
      color: $text;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $hover;
    }

    .rc-virtual-list-holder-inner {
      // border: 1px solid $hover;
      background-color: $contrastBackground;
    }

    .ant-select-item {
      color: $text;
    }

    .ant-input-group-addon button {
      background-color: $hover !important;
      color: $text !important;
    }

    .ant-pagination li button {
      background-color: $hover !important;
      color: $text !important;
    }

    .ant-pagination li {
      background-color: $hover !important;
      color: $text !important;
    }

    .advanced-filters {
      .ant-input {
        background-color: $contrastBackground !important;
      }

      .ant-btn {
        background-color: $contrastBackground !important;
      }
    }

    .bottom-tab {
      background: $contrastBackground !important;
      color: $text !important;
    }

    .darkflow-tabs {
      .MuiTab-wrapper {
        color: $text;
      }
    }

    .darkflowCardsHeader {
      color: $text;
    }

    .darkflowCards {
      background: $background;

      .darkflowCard {
        background: $contrastBackground !important;
        color: $text;

        &--header {
          .ticker a {
            color: $text;
          }

          .companyName a {
            color: $text;
          }
        }
      }
    }

    .strongbuy-divider {
      border-bottom: 1px solid #444;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .strongbuy-row {
      .strongbuy-name {
        a {
          color: $text;
        }
      }

      .strongbuy-chart {
        a {
          color: $text;
        }
      }
    }

    .custom-snackbar {
      border: 0.5px solid rgba(255, 255, 255, 0.12) !important;
      background: #2C2C2C !important;
      color: #FFF !important;

      #notistack-snackbar svg {
        color: #66BB6A !important;
      }

      &.ws-lite-snackbar {
        background: #2C2C2C !important;
        border: none !important;
      }
    }
  }

  &.light {
    .PhoneInputInput {
      background-color: white;
    }

    background-color: #f8fafc;

    .caret.up {
      border-bottom-color: white;
    }
  }

  &.media {
    display: block !important;
  }
}

.bottom-tab {
  background: #ddd !important;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  padding: 3px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.gauge {
  position: relative;
  width: 80px;
  height: 40px;
  overflow: hidden;
  transform: scale(0.8);
  left: -8px;
}

.ats-equity-gauge {
  position: relative;
  width: 80px;
  height: 40px;
  overflow: hidden;
}

.ats-equity-gauge:before,
.ats-equity-gauge:after,
.meter {
  position: absolute;
  display: block;
  content: "";
}

.ats-equity-gauge:before,
.meter {
  width: 80px;
  height: 40px;
}

.ats-equity-gauge:before {
  border-radius: 40px 40px 0 0;
  background: #dee1e5;
}

.ats-equity-gauge:after {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 50px;
  height: 25px;
  background: #fff;
  border-radius: 25px 25px 0 0;
}

.gauge-caption {
  position: absolute;
  top: -25px;
  font-size: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.gauge-indicator {
  width: 3px;
  height: 25px;
  background: #eecf46;
  top: -5px;
  position: absolute;
  left: 50%;
  z-index: 99999;
  transform: translateX(-50%);
}

.gauge:before,
.gauge:after,
.meter {
  position: absolute;
  display: block;
  content: "";
}

.gauge:before,
.meter {
  width: 80px;
  height: 40px;
}

.gauge:before {
  border-radius: 40px 40px 0 0;
  background: #dee1e5;
}

.gauge:after {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 50px;
  height: 25px;
  background: #fff;
  border-radius: 25px 25px 0 0;
}

.meter {
  top: 100%;
  transition: 1.5s;
  transform-origin: center top;
  border-radius: 0 0 48px 48px;
  background: deepskyblue;
}

.meter.green {
  background: #9bcb69;
}

.meter.red {
  background: #ef5854;
}

.meter.blue {
  background: rgb(78, 78, 230);
}

.meter.yellow {
  background: #eecf46;
}

@for $i from 0 through 180 {
  .gauge .meter.deg-#{$i} {
    transform: rotate(#{$i}deg);
  }

  .ats-equity-gauge .meter.deg-#{$i} {
    transform: rotate(#{$i}deg);
  }
}

.strongbuys {
  span {
    font-size: 10px;
    opacity: 0.7;
  }
}

.strongbuy-row {
  .strongbuy-name {
    .ticker {
      font-size: 16px;
      font-weight: 600;
    }

    .name {
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    a {
      color: black;
    }
  }

  .strongbuy-chart {
    a {
      color: black;
    }
  }

  .strongbuy-price {
    div {
      font-weight: 600;
      font-size: 14px;
    }

    span {
      font-size: 12px;

      &.up {
        color: #6dc92f;
      }

      &.down {
        color: #ef5656;
      }
    }
  }
}

.strongbuy-divider {
  border-bottom: 1px solid #ddd;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.darkflowCards {
  margin-top: 14px;
  background: #f8fafc;
  display: block;

  .darkflowTab {
    width: 50%;
  }

  .darkflowCard {
    width: 100%;
    background: #fff;
    border-radius: 25px;
    padding: 15px;

    &--header {
      img {
        width: 100px;
        height: 60px;
        object-fit: contain;
        padding-right: 4px;
      }

      .ticker a {
        color: black;
        font-weight: 800;
        font-size: 22px;
      }

      .companyName {
        white-space: nowrap;

        a {
          color: black;
          opacity: 0.7;
        }

        margin-top: 7px;
        height: auto;
      }
    }

    &--divider {
      border-bottom: 1px solid #88888880;
      margin: 5px;
    }

    &--chart {
      min-height: 126px;
      padding-top: 5px;

      .ohl {
        text-align: right;
      }

      .price {
        font-weight: 800;
        font-size: 20px;

        .priceChange {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          line-height: 36px;
          float: right;

          &.up {
            color: rgb(109, 201, 47);
          }

          &.down {
            color: red;
          }
        }
      }

      .chart-open {
        opacity: 0.7;
      }

      .chart-high {
        color: rgb(109, 201, 47);
      }

      .chart-low {
        color: red;
      }
    }

    &--bottom {

      .darkflow-volume,
      .darkflow-flow,
      .darkflow-total-volume {
        display: inline;

        .value {
          display: inline;
        }
      }

      .darkflow {
        // border-right: 1px solid #88888888;
      }

      .darkflow-heading {
        color: #aaa;
      }

      .darkflow-score {
        font-size: 16px;
        font-weight: 800;
      }

      .darkflow-multiplier {
        color: rgb(109, 201, 47);
        font-weight: 800;
        font-size: 15px;
      }

      .options-heading {
        color: #aaa;
      }

      .options-flow {
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
}

.companyLogo {
  &.placeholder {
    &.dark {
      filter: invert(1);
    }
  }
}

.hoverbg {
  padding: 10px;
  border-radius: 10px;
  margin: -10px;
}

.hoverbg:hover {
  background: #88888822;
}

.MuiTabs-flexContainer {
  border-block-end: 2px solid #e4e2e2;
}

.top {
  box-shadow: 0 -3px 5px -5px rgb(184, 181, 181);
}

.MuiTab-root {
  min-width: 1px !important;
}

.App {
  text-align: center;
}

ul {
  padding: 0;
  list-style-type: none;
}

/* .text-white {
  color: white;
} */

.custom-table-row {
  font-size: 14px;
}

.form-and-toggle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.markets-tickers-sidebar-container {
  padding: 0px;
}

/* .markets-index-stockbox-wrapper {
  margin-bottom: 10px;
}
.markets-index-stockbox {
  font-size: 20px;
  padding-bottom: 0px;
} */

/* .stock-box-positive {
  color: #20c997;
}

.stock-box-negative {
  color: red;
} */

.markets-index-stockname {
  margin-bottom: 0px;
}

.css-2b097c-container {
  width: 100%;
}

.insider-loading-spinner {
  font-size: 300px;
}

.container-fluid-nav {
  width: 100%;
}

.navbar-nav {
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

/* .savings-account-card:first {
  box-shadow: 0 0 5px 5px rgba(0, 255, 0, 0.2);
} */
.savings-account-card {
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  margin-bottom: 10px;
  padding: 10px;
}

/*
.savings-account-bank-name {
  font-size: 20px;
} */
/* .savings-account-img {
  max-width: 200px;
  display: block;
  margin: 15px auto;
} */

.analyst-pick-item {
  margin-bottom: 10px;
}

.savings-account-card:first-child {
  box-shadow: 0 0 5px 5px rgba(0, 255, 0, 0.2);
}

.interest-calc-bg {
  background: #22263f;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.div-calc-explanation {
  font-size: 20px;
}

.secondary-text-label {
  font-size: 15px;
}

/* .ant-input.ant-select {
  border: none;
} */

.ant-table-layout-fixed table {
  table-layout: auto;
}

.ant-select-selection-selected-value {
  width: 100%;
  padding-right: 30px;
}

.tooltip-hover,
.tooltip-hover:hover,
.tooltip-hover:focus,
.tooltip-hover:visited {
  border: none;
  padding: 0;
  margin: 0 10px;
  background: none;
  box-shadow: none;
  color: black;
}

.mutual-fund-data {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.container-xl-live {
  max-width: 100% !important;
}

.card-info-table {
  font-size: 14pt;
  text-align: center;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/*Tables Styles*/
.ant-table-small>.ant-table-content>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table {
  padding: 0;
}

.ant-table-small>.ant-table-content>.ant-table-body {
  margin: 0;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  word-break: normal;
}

.table-responsive {
  overflow: hidden;
}

.MuiPaper-root {
  overflow: hidden;
  background-color: transparent;
}

@media screen and (max-width: 525px) {
  .gauge {
    transform: scale(0.7) !important;
    left: -10px;
  }
}

.ant-select-dropdown.search-bar-dropdown.ant-select-dropdown-placement-bottomLeft {
  top: 50px !important;
  left: 0 !important;
  border-radius: 0px !important;
}

.customTooltip {
  font-size: 14px !important;
}

.stock-wall-tabs-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock-wall-filter-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 28px;
}

.stock-wall-filter-sort-by-caption {
  margin-right: 30px;
  color: #8e98a6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  letter-spacing: 2px;
  position: relative;
  top: 5px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .stock-wall-filter-label {
    font-size: 13px !important;
  }

  .stock-wall-filter-sort-by-caption {
    margin-right: 20px !important;
    font-size: 13px !important;
  }

  .gauge {
    transform: scale(0.5) !important;
    left: -20px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .strongbuy-row {
    width: 80%;
    margin: 0 auto !important;
  }

  .stock-wall-tabs-filter-wrapper {
    flex-direction: column;
  }
}

#header-search-bar {
  z-index: 1000;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.emerald-link a {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: nowrap;
}

.pagination--buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  .pagination--button {
    background-color: #007bff;
    width: 30px;
    height: 30px;

    &:hover {
      background-color: rgba(0, 123, 255, 0.4);
    }
  }

  .pagination--button .MuiSvgIcon-root {
    font-size: 20px;
    margin-bottom: 0;
    color: #fff;
  }

  .pagination--button:disabled {
    background-color: #c7c7c7;
  }

  .pagination--button:disabled .MuiSvgIcon-root {
    color: #e4e5e8;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  color: #34404a;
}

body.dark {
  .pagination--buttons-wrapper {
    .pagination--button {
      background-color: #5eeecd;

      &:hover {
        background-color: rgba(94, 238, 205, 0.4);
      }
    }

    .pagination--button .MuiSvgIcon-root {
      color: #000000;
    }

    .pagination--button:disabled {
      background-color: #1c1c1e;
    }

    .pagination--button:disabled .MuiSvgIcon-root {
      color: #e4e5e8;
    }
  }

  .MuiPickersPopper-paper svg.MuiSvgIcon-root {
    fill: #ffffff;
  }

  .MuiPickersPopper-paper button.Mui-disabled .MuiSvgIcon-root {
    fill: rgba(255, 255, 255, 0.5);
  }

  .PrivatePickersYear-yearButton.Mui-disabled {
    color: rgba(255, 255, 255, 0.26);
  }

  .MuiDataGrid-root .MuiDataGrid-cell {
    color: #fff;
  }

  .stock-wall-filter-label {
    color: #ffffff;
  }

  .MuiDataGrid-columnHeader--pinnedLeft {
    background: rgb(94, 238, 205) !important;
  }

  .MuiDataGrid-columnHeader--withRightBorder {
    border-right-color: #000000 !important;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background: #191b1c;

    .MuiDataGrid-cell--pinnedLeft {
      background: #191b1c !important;
    }
  }

  .MuiDataGrid-row:nth-child(even) {
    background: #00070e;

    .MuiDataGrid-cell--pinnedLeft {
      background: #00070e !important;
    }
  }

  .MuiDataGrid-row:hover {
    background-color: rgba(255, 255, 255, 0.04) !important;
  }

  .free-tier-icon {
    fill: #fff !important;
  }

  li.active svg.free-tier-icon {
    fill: #000 !important;
  }

  li.active .upgrade-button {
    background: #000000;
  }
}

.MuiPickersPopper-paper {
  position: relative;
  top: 20px;
}

.MuiDataGrid-columnHeader--pinnedLeft {
  background: rgb(0, 123, 255) !important;
}

.MuiDataGrid-cell--pinnedLeft {
  z-index: 10000 !important;
}

.MuiDataGrid-row:nth-child(odd) {
  background: #fff;

  .MuiDataGrid-cell--pinnedLeft {
    background: #fff !important;
  }
}

.MuiDataGrid-row:nth-child(even) {
  background: #f8fafc;

  .MuiDataGrid-cell--pinnedLeft {
    background: #f8fafc !important;
  }
}

.MuiDataGrid-virtualScrollerContent {
  margin-bottom: 20px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.padding-15-row {
  padding-left: 15px;
  padding-right: 15px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  letter-spacing: 0.3px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.d-inline-block {
  display: inline-block !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.bg-white {
  background: #fff !important;
}

.position-relative {
  position: relative !important;
}

.no-transform-scale {
  transform: none !important;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1500px;
  }

  .gauge {
    transform: scale(0.7);
    left: -10px;
  }
}

@media (max-width: 991px) {
  .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .hidden-on-mobile {
    display: none !important;
  }
}