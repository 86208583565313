.trade-algos-darkflow-cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  column-gap: 28px;
}

.trade-algos-darkflow-card {
  margin-left: 0 !important;
  margin-right: 15px !important;
  min-width: inherit !important;
  max-width: inherit !important;
  width: calc(100% / 3 - 10px);
  //z-index: 11;
}

.trade-algos-darkflow-card:nth-of-type(3n) {
  margin-right: 0 !important;
}

.trade-algos-darkflow-card-content {
  padding: 24px !important;
}

.trade-algos-darkflow-activity-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.trade-algos-darkflow-activity-caption {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #4c4663;
  margin-right: 16px;
}

.trade-algo-darkflow-sort-button {
  background:
    linear-gradient(#f8fafc, #f8fafc) padding-box,
    linear-gradient(90deg, rgba(23, 102, 255, 1) 0%, rgba(255, 73, 128, 1) 100%)
      border-box;
  border-radius: 30px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1299;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1d1a27;
}

.trade-algo-darkflow-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  padding-left: 0;
  padding-right: 0;
}

.trade-algo-darkflow-card {
  background: rgba(255, 255, 255, 0.5);
  width: calc(100% / 3 - 20px);
  padding: 32px;
  z-index: 100;
  margin-bottom: 20px;
  border-radius: 25px;
  margin-right: 30px;
}

.trade-algo-darkflow-card:nth-of-type(3n) {
  margin-right: 0;
}

.trade-algo-darkflow-card-header {
  margin-bottom: 20px;
}

.trade-algo-darkflow-card-ticker {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #4c4663;
  margin-right: 8px;
}

.trade-algo-darkflow-card-company-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #4c4663;
}

.trade-algo-darkflow-card-ratio-wrapper {
  margin-bottom: 15px;
}

.trade-algo-darkflow-card-ratio {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  margin-right: 16px;
}

.trade-algo-darkflow-card-ratio.green-color {
  color: #0dad60 !important;
}

.trade-algo-darkflow-card-ratio.red-color {
  color: #ff4949 !important;
}

.trade-algo-darkflow-card-avg {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #4c4663;
}

.trade-algo-darkflow-card-progress {
  margin-bottom: 32px;
}

.trade-algo-darkflow-card-progress-bar {
  border-radius: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.half-bar {
  width: 50% !important;
}

.green-bar {
  background: #0dad60;
}

.gray-bar {
  background: rgba(183, 180, 199, 0.5);
}

.red-bar {
  background: #ff4949;
}

.trade-algo-darkflow-card-value-wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trade-algo-darkflow-card-value {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #4c4663;
  margin-right: 8px;
}

.trade-algo-darkflow-card-percentage.green-color {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #0dad60 !important;
}

.trade-algo-darkflow-card-percentage.red-color {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ff4949 !important;
}

.trade-algo-darkflow-card-market-cap {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #4c4663;
  margin-left: 8px;
}

.trade-algo-darkflow-card-graph-wrapper {
  margin: 32px 0;
  padding-bottom: 17px;
}

.trade-algo-darkflow-card-graph {
  width: 100%;
}

.trade-algo-darkflow-card-darkpool-wrapper {
  margin-bottom: 32px;
}

.trade-algo-darkflow-card-darkpool-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.trade-algo-darkflow-card-half-width {
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trade-algo-darkflow-card-darkpool-small {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #4c4663;
}

.trade-algo-darkflow-card-darkpool-light {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #13111a;
  margin-top: 5px;
}

.trade-algo-darkflow-card-darkpool-medium {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #13111a;
  margin-top: 5px;
}

.trade-algo-darkflow-card-darkpool-value.green-color {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #0dad60 !important;
}

.trade-algo-darkflow-card-darkpool-value.red-color {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ff4949 !important;
}

.trade-algo-darkflow-card-darkpool-bar.green-color {
  height: 6px;
  width: 60px;
  background: #0dad60;
  border-radius: 44px;
}

.trade-algo-darkflow-card-darkpool-bar.red-color {
  height: 6px;
  width: 60px;
  background: #ff4949;
  border-radius: 44px;
}

.trade-algo-darkflow-card-options-flow {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4c4663;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .trade-algos-darkflow-card {
    width: calc(50% - 15px);
    max-width: inherit !important;
    max-height: inherit !important;
    margin-right: 30px !important;
  }

  .trade-algos-darkflow-card:nth-of-type(3n) {
    margin-right: 30px !important;
  }

  .trade-algos-darkflow-card:nth-of-type(2n) {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .trade-algos-darkflow-card {
    width: calc(50% - 10px);
    max-width: inherit !important;
    max-height: inherit !important;
    margin-right: 20px !important;
  }

  .trade-algos-darkflow-card:nth-of-type(3n) {
    margin-right: 20px !important;
  }

  .trade-algos-darkflow-card:nth-of-type(2n) {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .trade-algos-darkflow-card {
    width: 100%;
    max-width: inherit !important;
    max-height: inherit !important;
    margin-right: 0 !important;
  }

  .trade-algos-darkflow-card:nth-of-type(3n) {
    margin-right: 0 !important;
  }

  .trade-algos-darkflow-card:nth-of-type(2n) {
    margin-right: 0 !important;
  }
}

.trending-navigation-menu {
}

.trending-navigation-menu-item {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 15px 15px 20px;
}

.trending-navigation-menu-item.active {
  border-bottom: 4px solid #5eeecd;
}

.tradealgo-cards-shown {
  display: block;
}

.tradealgo-cards-hidden {
  display: none;
}
