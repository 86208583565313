.video-page {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.video-page__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  width: calc(65% - 10px);
}

.video-page__left__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.video-page__video-player {
  width: 100%;
}

.video-page__video {
  height: 50vh;
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
}

.video-page__left__tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #a6a6a6;
  width: 100%;
}

.video-page__left__tab {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.video-page__left__tab.active {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.video-page__left__infos {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  flex-direction: column;
}

.video-page__left__info {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  display: flex;
}

.video-page__left__description {
  padding-top: 5px;
}

.video-page__left__description__title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 11px;
}

.video-page__left__description__subTitle {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.video-page__left__description__content {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ul {
    list-style-type: disc;
    padding-left: 10px;
  }

  li {
    margin-bottom: 5px;
  }
}

.video-page__left__downloads_title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.video-page__slides {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #007bff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 6px;
  cursor: pointer;
}

.video-page__left__back {
  width: 100%;
  text-align: right;
  cursor: pointer;
  color: #007bff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.006px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.video-page__right {
  width: calc(35% - 10px);
}

.video-page__right__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
}

body.dark {
  .video-page__left__title {
    color: #fff;
  }

  .video-page__video {
    border: 1px solid #5eeecd;
  }

  .video-page__left__tab {
    color: #e4e5e8;
  }

  .video-page__left__tab.active {
    color: #fff;
  }

  .video-page__left__info {
    color: #e4e5e8;
  }

  .video-page__left__description__title {
    color: #fff;
  }

  .video-page__left__description__subTitle {
    color: #e4e5e8;
  }

  .video-page__left__description__content {
    color: #e4e5e8;
  }

  .video-page__left__downloads_title {
    color: #fff;
  }

  .video-page__slides {
    color: #5eeecd;

    svg path {
      fill: #5eeecd;
    }
  }

  .video-page__left__back {
    color: #5eeecd;
  }

  .video-page__right__title {
    color: #fff;
  }
}

@media (max-width: 1399px) {
  .video-page {
    flex-direction: column;
  }

  .video-page__video {
    height: auto;
    aspect-ratio: 1 / 0.6;
  }

  .video-page__left {
    width: 100%;
  }

  .video-page__right {
    width: 100%;
  }
}
