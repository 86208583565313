.mentorship-promo-banner-page {
  padding: 0 15px;
  margin-bottom: 12px;
  position: relative;
}

.mentorship-promo-banner-content {
  background: url(../../assets/images/promo-banner.png);
  width: 100%;
  background-size: cover;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.mentorship-promo-texts-wrapper {
  position: relative;
  left: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 160px);
}

.mentorship-promo-texts {
  max-width: 500px;
  width: auto;
  margin-right: 30px;
}

.mentorship-promo-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff;
}

.mentorship-promo-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-top: 10px;
}

.promo-sign-up-button {
  background: #007bff !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  padding: 13px 30px !important;
  text-transform: initial !important;
  box-shadow: 0px 6px 10px 0px rgba(0, 123, 255, 0.4) !important;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 123, 255, 0.4) !important;
  -moz-box-shadow: 0px 6px 10px 0px rgba(0, 123, 255, 0.4) !important;
  position: relative !important;
}

.promo-hand-icon {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 25px;
  left: 0px;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .mentorship-promo-title {
    font-size: 19px;
    line-height: 24px;
  }

  .mentorship-promo-info {
    font-size: 16px;
    line-height: 20px;
  }

  .promo-sign-up-button {
    width: 230px !important;
    padding: 13px 0 !important;
    font-size: 17px !important;
    line-height: 21px !important;
  }
}

@media (max-width: 992px) {
  .mentorship-promo-banner-content {
    padding: 20px 30px;
  }

  .mentorship-promo-texts-wrapper {
    flex-direction: column;
    align-items: end;
  }

  .mentorship-promo-texts {
    max-width: 450px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .mentorship-promo-info {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 800px) and (max-width: 992px) {
  .mentorship-promo-texts-wrapper {
    padding-right: 100px;
  }
}

@media (max-width: 700px) {
  .mentorship-promo-title {
    font-size: 14px;
    line-height: 19px;
  }

  .promo-sign-up-button {
    font-size: 17px !important;
    line-height: 21px !important;
    padding: 8px 30px !important;
  }

  .promo-hand-icon {
    width: 30px;
    height: 30px;
    top: 20px;
  }
}
