.live-trading-sessions {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  margin: 30px 0;
  padding: 0 15px;
}

.live-trading-sessions-left-part {
  flex: 1;
}

.live-trading-sessions-left-part-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.live-trading-sessions-left-part-title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.live-trading-sessions-left-part-content {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 24px;
}

.live-trading-session-item {
  width: calc(50% - 12px);
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  &.session-shown {
    display: flex;
  }
}

.live-trading-session-bg {
  width: 100%;
}

.live-trading-session-body {
  width: 100%;
  padding: 0 20px 20px 20px;
  margin-top: 14px;
}

.live-trading-session-subtitle {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2px 0;
  margin-bottom: 3px;
}

.live-trading-session-title {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.live-trading-session-author {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0;
}

.live-trading-session-time {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.live-trading-session-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 12px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;

  &.live {
    background: #ea3323;
    text-transform: uppercase;
  }

  &.upcoming {
    background: #86868b;
  }
}

.live-trading-sessions-right-part {
  width: 325px;
  flex: 0 0 auto;
}

body.dark {
  .live-trading-sessions-left-part-title {
    color: #ffffff;
  }

  .live-trading-session-item {
    border-radius: 20px;
    border: 1px solid #1c1c1e;
    background: #1c1c1e;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.75);
  }

  .live-trading-session-subtitle {
    color: #5eeecd;
  }

  .live-trading-session-title {
    color: #ffffff;
  }

  .live-trading-session-author {
    color: #e4e5e8;
  }

  .live-trading-session-time {
    color: #5eeecd;
  }
}

@media (max-width: 1400px) {
  .live-trading-sessions {
    flex-direction: column;
  }

  .live-trading-sessions-right-part {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .live-trading-sessions-left-part-content {
    flex-direction: column;
  }

  .live-trading-session-item {
    width: 100%;
  }
}
