.trading-tools-component {
  padding: 0 15px;
  margin: 30px 0;
}

.trading-tools-component__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
}

.trading-tools-component__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.trading-tools-component__content__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 120px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.trading-tools-component__content__item__icon {
  border-radius: 20px;
  background: #f1f5fe;
  padding: 26px 25.5px 25.702px 24.5px;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trading-tools-component__content__item__caption {
  color: #a6a6a6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

body.dark {
  .trading-tools-component__title {
    color: #ffffff;
  }

  .trading-tools-component__content__item__icon {
    background: #5eeecd;
  }

  .trading-tools-component__content__item__caption {
    color: #ffffff;
  }
}
