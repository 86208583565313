.signup-wrapper {
  height: 100vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 26px;
  text-align: center;
}

body {
  &.dark {
    .signup-wrapper {
      background: #161616 !important;
    }
  }
}

.ta-logo-text {
  margin-top: 43px;
  width: 220px;
  height: 56px;
}

.signup-iframe {
  width: 100%;
  min-height: 1100px;
}

.h-1750 {
  min-height: 1550px !important;
}

.create-acc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}

.already-acc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.logger-in {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #007bff;
}

.back-home {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #007bff !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.back-arrow {
  width: 21px;
  height: 14px;
  position: relative;
  top: -1px;
  right: 3px;
}
