.lite-live-stream {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
  padding: 24px 0;
}

.lite-live-stream__header {
  display: flex;
  gap: 18px;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

.lite-live-stream__left {
  width: calc(70% - 9px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.lite-live-stream__video {
  width: 100%;
  height: 50vh;
  border: 1px solid #000;
}

.lite-live-stream__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lite-live-stream-author {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.lite-live-stream-author-name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  gap: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 20px;
    height: 20px;
    color: #0071ee;
  }
}

.lite-live-stream-author-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.lite-live-stream-author-title {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.lite-live-stream__content {
  border-radius: 10px;
  background: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.lite-live-stream__content-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

.lite-live-stream__content-scheduleDate {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  padding-bottom: 8px;
}

.lite-live-stream__content-description {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.lite-live-stream__live_schedule-title {
  color:rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 14px 0;
}

.lite-live-stream__schedules {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.lite-live-stream__schedule {
  width: calc((100% - 28px) / 5);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.lite-live-stream__schedule-day {
  background: #f7f8f7;
  padding: 5px 0;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lite-live-stream__schedule-time {
  color:rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lite-live-stream__right {
  width: calc(30% - 9px);
}

.lite-live-stream-cta {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 10px;
  background: rgba(0, 149, 120, 0.12);
  cursor: pointer;
}

.lite-live-stream-cta-img {
  width: 60px;
  height: 60px;
}

.lite-live-stream-cta-texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.lite-live-stream-cta-text {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.15px;
  background: linear-gradient(269deg, #5EEECD 0%, #007BFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lite-live-stream__body {
  display: flex;
  gap: 18px;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

body.dark {
  .lite-live-stream__title {
    color: #fff;
  }

  .lite-live-stream-author-name {
    color: #fff;
  }

  .lite-live-stream-author-title {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-live-stream__content {
    background: #2C2C2C;
  }

  .lite-live-stream__content-title {
    color: #fff;
  }

  .lite-live-stream__content-scheduleDate {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-live-stream__content-description {
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-live-stream__live_schedule-title {
    color: #fff;
  }

  .lite-live-stream__schedule-day {
    color: #e4e5e8;
    background: #1c1c1e;
  }

  .lite-live-stream-author-name {
    svg {
      color: #5eeecd;
    }
  }
}

@media (max-width: 1200px) {
  .lite-live-stream {
    align-items: flex-start;
  }

  .lite-live-stream__left {
    width: 100%;
  }

  .lite-live-stream__right {
    width: 100%;
    height: 700px;

    &.lite-live-stream-cta-container {
      height: auto;
    }
  }

  .lite-live-stream__header {
    flex-direction: column;
  }

  .lite-live-stream__body {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .lite-live-stream__video {
    height: auto;
    aspect-ratio: 16 / 10;
  }

  .lite-live-stream__right {
    height: auto;
    aspect-ratio: 16 / 10;

    &.lite-live-stream-cta-container {
      aspect-ratio: inherit;
    }
  }
}

@media (max-width: 767px) {
  .lite-live-stream__schedules {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }

  .lite-live-stream__schedule {
    width: calc((100% - 40px) / 3);
  }

  .lite-live-stream__right {
    aspect-ratio: 1 / 1;
  }
}
