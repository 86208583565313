.ta-products {
  width: 100%;
}

.ta-products__container {
  display: flex;
  width: 100%;
  padding: 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.ta-products__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ta-products__title {
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.ta-products__subtitle {
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ta-products__cards {
  width: 100%;
  gap: 24px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

body.dark {
  .ta-products__title {
    color: rgba(255, 255, 255, 0.87);
  }

  .ta-products__subtitle {
    color: rgba(255, 255, 255, 0.87);
  }
}
