.unusual-activity-widget {
  margin-bottom: 40px;
  padding: 24px;
  border-radius: 16px;
}

.unusual-activity-widget-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.unusual-activity-widget-ticker {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  // color: #000000;
}

.unusual-activity-widget-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.today-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 24px;
}

span.progress-small-caption {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #94a3b8;
  white-space: nowrap;
}

span.progress-small-percentage {
  background: #eff3f8;
  border-radius: 46px;
  height: 8px;
  min-width: 15vw;
  margin: 0 12px;
}
span.progress-small-percentage-options {
  background: #eff3f8;
  border-radius: 46px;
  height: 9px;
  min-width: auto;
  margin: 0 12px;
  width: 85px;
}

.today-progress-indicator {
  height: 8px;
  background: #8277df;
  display: block;
  border-radius: 46px;
}

.avg-progress-indicator {
  height: 8px;
  background: #5eeecd;
  display: block;
  border-radius: 46px;
}

.unusual-activity-widget-table-header {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f5f9;
  padding: 16px;
}

.table-header-item {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-transform: uppercase;
  color: #94a3b8;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.open-time {
  width: 10%;
}

.last-time {
  width: 10%;
}

.total-trades {
  width: 10%;
}

.total-price {
  width: 15%;
}

.avg-price {
  width: 15%;
}

.unusual-activity-widget-table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f5f9;
  padding: 16px;
}

.table-row-item {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: #475569;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  .progress-small-percentage {
    width: 110px;
    min-width: inherit;
  }
}

.table-row-volume {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  // color: #0F172A;
}

.table-row-item.dark-color {
  color: #0f172a;
}

.unusual-activity-load-more {
  background: #f1f5f9;
  border-radius: 24px;
  padding: 12.5px 0;
  width: 100%;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94a3b8;
  margin-bottom: 100px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .open-time {
    width: 10%;
  }

  .last-time {
    width: 10%;
  }

  .total-volume {
    width: 25%;
  }

  .total-trades {
    width: 15%;
  }

  .total-price {
    width: 15%;
  }

  .avg-price {
    width: 15%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .open-time {
    width: 10%;
  }

  .last-time {
    width: 10%;
  }

  .total-volume {
    width: 25%;
  }

  .total-trades {
    width: 15%;
  }

  .total-price {
    width: 15%;
  }

  .avg-price {
    width: 15%;
  }
}
.center-div {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
