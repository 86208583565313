.notification-half-width-element {
  width: calc(50% - 10px);
}

.notification-card {
  background: #fff;
}

.notification-join-button {
  background: #007bff !important;
}

.notification-join-button-wrapper {
  text-align: right;
}

body.dark {
  .notification-card {
    background: #000;
  }

  .notification-join-button {
    background: #5eeecd !important;
  }

  .notification-half-width-element {
    a {
      color: #5eeecd;
    }
  }
}

@media (max-width: 991px) {
  .notification-half-width-element {
    width: 100%;
  }

  .notification-join-button-wrapper {
    text-align: center;
    margin-top: 20px;
  }
}