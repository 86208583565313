.real-time-trading-alerts-component {
  padding: 24px 30px;
  background: #161616;
  border-radius: 10px;
}

.real-time-trading-alerts-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.real-time-trading-alerts-navigator svg {
  color: #ffffff !important;
  fill: #ffffff !important;
  margin-right: 5px;
  cursor: pointer;
}

.real-time-trading-alerts-navigator:hover {
  color: #5eeecd;
}

.real-time-trading-alerts-navigator:hover svg {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.real-time-trading-alerts-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.real-time-trading-alerts-guide {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  margin-bottom: 30px;
}

.real-time-trading-alerts-section {
  margin-bottom: 60px;
}

.real-time-trading-alerts-section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #e4e5e8;
}

.real-time-trading-alerts-section-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  padding: 20px 15px;
  border-bottom: 0.5px solid #e4e5e8;
  cursor: pointer;
}

.real-time-trading-alerts-section-item:hover {
  background: rgba(228, 229, 232, 0.2);
}

.real-time-trading-alerts-section-item.active {
  background: #28292d;
  color: #fff;
}

.real-time-trading-alerts-section-item-main {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}

.real-time-trading-alerts-section-item svg {
  border: 1px solid #fff;
  border-radius: 20px;
  color: #ffffff !important;
  fill: #ffffff !important;
}

.real-time-trading-alerts-section-item-more {
  border-left: 1px solid #6e6e70;
  padding-left: 16px;
  margin-top: 16px;
  padding-right: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: initial;
}

.real-time-trading-alerts-section-item.active svg {
  color: #fff !important;
  fill: #fff !important;
}

a.trading-alerts-anchor {
  color: #fff;
  text-decoration: underline;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #ffffff;
}

a.trading-alerts-anchor:hover {
  text-decoration: underline !important;
}

.platinum-program-telegram-button {
  background: #007bff;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  display: inline-block;
  margin-bottom: 30px;
}

.platinum-program-telegram-button:hover {
  color: #f4f4f5;
}

body.light {
  .real-time-trading-alerts-component {
    background: #ffffff;
  }

  .real-time-trading-alerts-navigator {
    color: #7a7a7a;
  }

  .real-time-trading-alerts-navigator svg {
    color: #7a7a7a !important;
    fill: #7a7a7a !important;
  }

  .real-time-trading-alerts-navigator:hover {
    color: #5eeecd;
  }

  .real-time-trading-alerts-navigator:hover svg {
    color: #5eeecd !important;
    fill: #5eeecd !important;
  }

  .real-time-trading-alerts-title {
    color: #121416;
  }

  .real-time-trading-alerts-guide {
    color: #7a7a7a;
  }

  .real-time-trading-alerts-guide strong {
    color: #000 !important;
  }

  .real-time-trading-alerts-section-title {
    color: #000000;
  }

  .real-time-trading-alerts-section-item-main {
    color: #000000;
  }

  .real-time-trading-alerts-section-item svg {
    border: 1px solid #7a7a7a;
    border-radius: 20px;
    color: #000 !important;
    fill: #000 !important;
  }

  .real-time-trading-alerts-section-item.active {
    background: #000;

    .real-time-trading-alerts-section-item-main {
      color: #ffffff;
    }

    svg {
      border: 1px solid #ffffff;
      color: #ffffff !important;
      fill: #ffffff !important;
    }
  }
}

@media (max-width: 600px) {
  .real-time-trading-alerts-component {
    padding: 20px;
  }
}
.support-faq {
  width: 60%;

  @media (max-width: 1248px) {
    width: 100%;
  }
}
.support-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(40% - 35px);

  @media (max-width: 1248px) {
    width: 100%;
  }
}

.support-blocks {
  width: 100%;
  // height: 202px;
  background: white;
  border-radius: 10px;
  padding: 16px 26px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  // max-width: 360px;
  height: 202px;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 10px;
}
.support-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
