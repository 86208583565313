.profile-settings-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e4e5e8;
}

.profile-settings-tabs a {
  color: #a6a6a6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.profile-settings-tabs a.active {
  color: #000 !important;
}

body.dark {
  .profile-settings-tabs {
    border-bottom: 2px solid #86868b;
  }

  .profile-settings-tabs a.active {
    border-bottom-color: #5eeecd !important;
    color: #FFF !important;
  }

  .profile-settings-tabs .nav-tabs .nav-link.active {
    background-color: transparent !important;
  }
}

@media (max-width: 1082px) {
  .profile-settings-tabs {
    li.nav-item {
      width: 20%;
      flex: 0 0 auto;
    }

    .nav-tabs {
      width: 100%;
    }

    .nav-tabs li .nav-link {
      padding: 10px 0 !important;
    }
  }
}

@media (max-width: 767px) {
  .profile-settings-tabs {
    padding-bottom: 20px;

    .nav-tabs {
      flex-wrap: wrap !important;
      gap: 10px;
    }

    li.nav-item {
      width: calc(50% - 5px);
    }

    li.nav-item a {
      width: 100% !important;
    }

    a.active {
      border-bottom: none !important;
    }
  }
}

@media (max-width: 400px) {
  .profile-settings-tabs {
    li.nav-item {
      width: 100%;
    }
  }
}
.info-text {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 112.4%;
}

body {
  &.dark {
    .titles-row {
      background-color: grey;

      .title-text {
        color: white;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }

    .outlined-weight-helper-text {
      color: white;
    }

    .user-profile-wrapper {
      .MuiSvgIcon-root {
        fill: white;
      }

      .MuiTab-textColorPrimary {
        color: white !important;
      }

      padding: 0px 36px;

      .MuiTab-root {
        text-transform: none;
      }

      .Mui-selected {
        color: white !important;
      }
    }
  }

  &.light {
    .user-profile-wrapper {
      padding: 0px 36px;

      .MuiTab-root {
        text-transform: none;
      }

      .Mui-selected {
        color: #000 !important;
      }
    }
  }
}

.outlined-weight-helper-text {
  color: #7a7a7a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 2px !important;
}

.user-profile-inner {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 28px;
}

.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: #5eeecd !important;
  }
}

.password-wrapper {
  display: flex;
  align-items: end;
  gap: 15px;
}

.show-password-button {
  button {
    background: #5eeecd !important;
    text-transform: none;
    color: #000000 !important;
    height: 40px !important;
    white-space: nowrap;
  }
}

.update-profile {
  button {
    background: #5eeecd !important;
    text-transform: none;
    color: #000000 !important;
    height: 40px !important;
    white-space: nowrap;
    margin-top: 10px;
  }

  button:disabled {
    opacity: 0.5;
  }
}

.show-password-button {
  button:disabled {
    opacity: 0.5;
  }
}

.country-field {
  .MuiAutocomplete-inputRoot {
    width: 408px;
  }
}

.is-mobile {
  .MuiAutocomplete-inputRoot {
    width: 311px !important;
  }
}

.state-field {
  .MuiAutocomplete-inputRoot {
    width: 408px;
  }
}

.subscriptions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.mt-28 {
  margin-top: 28px;
}

.titles-row {
  width: auto;
  height: 45px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  justify-content: space-around;
  border-radius: 6px;
}

.title-text {
  color: #7a7a7a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.sub-table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sub-table-row {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 20px;
}

.plan-wrapper {
  display: flex;
  width: 25%;
  text-align: left;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .text-wrapp {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}

.plan-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.plan-subtitle {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 20%;
  text-align: center;
}

.inner-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.status-text {
  color: #00cb87;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.expired-status-text {
  color: #ff3b30;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.sub-status-logo {
  width: 17px;
}

.sub-exp-date {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.price-text {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.price-range {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sub-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 20%;
}

.actions-text {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.sub-actions-wrapper {
  display: flex;
  align-items: center;
  width: 25%;
}

.psl-52 {
  position: relative;
  left: 52px;
}

.psl-21 {
  position: relative;
  left: 21px;
}
