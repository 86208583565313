.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #005dc3;
}

.ant-menu-vertical > .ant-menu-item {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.ant-menu-vertical > .ant-menu-item.ant-menu-item-active {
  background-color: #005dc3;
}

.sidebar-link__title {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}
.sidebar-link {
  width: 100%;
  height: 100%;
}

.ant-menu-inline-collapsed > li > span {
  font-size: 0;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  padding: 11px 20px;
  line-height: 1;
  border-radius: 10px;
  font-size: 16px;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner,
.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow-content {
  background-color: #9ea7b3;
}
