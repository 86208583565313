.ws-portfolio-info {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-info-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.ws-portfolio-info-tier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.ws-portfolio-info-tier-logo {
  height: 20px;
}

.ws-portfolio-info-mail-icon {
  color: #0000008f;
}

.ws-portfolio-info-tier-label {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.4%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  svg {
    fill-opacity: 0.56;
  }
}

.ws-subscribe-button {
  display: flex !important;
  padding: 6px 16px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;

  &.not-subscribed {
    background: #007BFF;
  }

  &.subscribed {
    background: #2E7D32;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);

    &:hover {
      background: #2E7D32;
    }
  }
}

.ws-portfolio-info-content {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
}

.ws-portfolio-info-performance {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.03);
}

.ws-portfolio-info-performance-label {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.ws-portfolio-info-performance-value {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;

  &.negative {
    color: #F44336;
  }

  &.positive {
    color: #4CAF50;
  }
}

.ws-portfolio-info-tier-menu {
  .MuiMenu-paper {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20);

    .MuiList-root {
      padding: 0;
      min-width: 250px;

      span {
        right: 0;
        position: absolute;
      }
    }
  }

  .ws-portfolio-tier-menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .ws-portfolio-tier-menu-logo-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ws-portfolio-tier-logo {
      width: 65px;
    }
    
    .ws-portfolio-tier-no-access {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 12px;
        height: 12px;
        fill-opacity: 0.56;
      }
    }

    .ws-portfolio-tier-no-access-text {
      color: #007BFF;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 166%;
      letter-spacing: 0.4px;
    }

    &.has-access {
      pointer-events: none;
    }

    &:hover {
      background: rgba(0, 123, 255, 0.04);
    }
  }

  .ws-portfolio-tier-menu-item {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: rgba(0, 123, 255, 0.04);
    }
  }

  .ws-portfolio-tier-value-label {
    color: rgba(0, 0, 0, 0.87);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  .ws-portfolio-tier-value-percentage {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%;
    letter-spacing: 0.4px;

    &.negative {
      color: #F44336;
    }

    &.positive {
      color: #4CAF50;
    }
  }
}

.ws-portfolio-performance-tabs {
  .MuiTabs-flexContainer {
    display: block;
    text-align: center;
  }
  .MuiTab-root {
    // flex: 1 1;
    text-align: center;
  }
  .MuiTabs-indicator {
    width: 100% !important;
    left: 0px !important;
  }
}

.ws-portfolio-content-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  height: calc(100% - 76px);
  overflow: auto;
}

body.dark {
  .ws-portfolio-info-tier-label {
    color: #FFF;
  }

  .ws-subscribe-button {
    &.not-subscribed {
      background: #007BFF;
      color: #FFF;
    }

    &.subscribed {
      background: #2E7D32;
      color: #FFF;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);

      &:hover {
        background: #2E7D32;
      }
    }
  }

  .ws-portfolio-info-mail-icon {
    color: #ffffff8f;
  }

  .ws-portfolio-info-performance {
    background: #3C3C3F;
  }

  .ws-portfolio-info-performance-label {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-info-performance-value {
    &.negative {
      color: #FF9494;
    }

    &.positive {
      color: #A5D6A7;
    }
  }

  .ws-portfolio-info-tier-menu {
    .MuiMenu-paper {
      ul {
        background: #1E1E1E;
        box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20);
      }
    }

    .ws-portfolio-tier-menu-logo-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    .ws-portfolio-tier-value-label {
      color: rgba(255, 255, 255, 0.87);
    }

    .ws-portfolio-tier-value-percentage {
      &.negative {
        color: #FF9494;
      }

      &.positive {
        color: #A5D6A7;
      }
    }

    .ws-portfolio-tier-no-access-text {
      color: #009EFF;
    }
  }

  .ws-portfolio-tier-menu-item {
    &:hover {
      background: rgba(0, 158, 255, 0.08);
    }
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .ws-portfolio-info-content {
    flex-wrap: wrap;
  }

  .ws-portfolio-info-performance {
    width: calc((100% - 24px) / 4);
    flex: 0 0 auto;
  }
}

@media (max-width: 1399px) {
  .ws-portfolio-info-content {
    flex-wrap: wrap;
  }

  .ws-portfolio-info-performance {
    width: calc((100% - 24px) / 4);
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ws-portfolio-info-content {
    flex-wrap: wrap;
  }

  .ws-portfolio-info-performance {
    width: calc(50% - 4px);
    flex: 0 0 auto;
  }
}

@media (max-width: 1099px) {
  .ws-portfolio-info-performance {
    background: #FFF;
  }
}

@media (max-width: 768px) {
  .ws-portfolio-info-content {
    flex-wrap: wrap;
  }

  .ws-portfolio-info-performance {
    width: calc(50% - 4px);
    flex: 0 0 auto;
  }
}
