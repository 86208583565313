.lite-mentorship-header {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.lite-mentorship-sessions-available {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-mentorship-sessions-number {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;

  span {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
}

.lite-mentorship-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.lite-mentorship-content-left {
  width: calc(100% - 300px);

  .calendly-inline-widget {
    width: 100% !important;
    min-width: auto !important;
  }
}

.lite-mentorship-content-left-body {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.lite-mentorship-content-banner {
  padding: 24px 20px;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
  background-image: url(../../../assets/images/wealth-series-lite/mentorship/Banner.png);
}

.lite-mentorship-content-right {
  width: 300px;
  flex: 0 0 auto;
}

.lite-mentorship-content-banner-label {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.077px;
}

.lite-mentorship-content-banner-title {
  color: #FFF;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.137px;
}

.lite-mentorship-content-description {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-mentorship-purchase-button {
  border-radius: 4px !important;
  background: #007bff !important;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  padding: 8px 22px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
}

.lite-mentorship-mentors {
  width: 100%;
}

.lite-mentorship-mentors-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.lite-dark-calendar iframe {
  filter: invert(1) hue-rotate(180deg);
}

body.dark {
  .lite-mentorship-sessions-available {
    color: #fff;
  }

  .lite-mentorship-sessions-number {
    color: #fff;
  }

  .lite-mentorship-content-left-body {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #363636;
  }

  .lite-mentorship-content-description {
    color: #FFF;
  }

  .lite-mentorship-mentors-title {
    color: #fff;
  }
}

@media (max-width: 1200px) {
  .lite-mentorship-content {
    flex-direction: column;
  }

  .lite-mentorship-content-left {
    width: 100%;
    order: 2;
  }

  .lite-mentorship-content-right {
    order: 1;
  }
}

@media (max-width: 768px) {
  .lite-mentorship-content-right {
    width: 100%;
  }

  .lite-mentorship-content-banner-label {
    font-size: 16px;
  }

  .lite-mentorship-content-banner-title {
    font-size: 24px;
  }
}