.ws-portfolio-activities {
  padding-bottom: 0;
}

.ws-portfolio-activity__header {
  display: flex;
  height: 52px;
  padding: 16px 40px;
  align-items: center;
  gap: 8px;
  width: 100%;
  background: #FFF;
  justify-content: space-between;
  cursor: pointer;
  position: sticky;
  top: 122px;
  z-index: 1001;

  &.closed {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.closed:hover {
    background: rgba(0, 123, 255, 0.08);
  }
}

.ws-portfolio-activity__date {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;

  span {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    font-weight:  500;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}

.ws-portfolio-activity__table_header {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  position: sticky;
  top: 174px;
  z-index: 1001;
}

.ws-portfolio-activity__activity-portfolio {
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 16px 0px 40px;
  align-items: center;
  flex: 1 0 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  min-width: 140px;
}

.ws-portfolio-activity__activity-ticker {
  cursor: pointer;
  display: flex;
  width: 324px;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  min-width: 80px;
}

.ws-portfolio-activity__activity-price {
  cursor: pointer;
  display: flex;
  padding: 0px 16px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  min-width: 150px;
}

.ws-portfolio-activity__activity-change {
  cursor: pointer;
  display: flex;
  padding: 0px 16px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  gap: 8px;
  min-width: 180px;
}

.ws-portfolio-activity__activity-impact {
  cursor: pointer;
  display: flex;
  width: 200px;
  padding: 0px 40px 0px 16px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  min-width: 100px;
}

.ws-portfolio-activity__activity {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  height: 52px;
}

.ws-portfolio-activity__activity-ticker-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-portfolio-activity__activity-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.ws-portfolio-activity__company {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ws-portfolio-activity__activity-impact--positive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 100px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  background: #C8E6C9;
  color: #1B5E20;
}

.ws-portfolio-activity__activity-impact--negative {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 100px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  background: #FFCDD2;
  color: #C62828;
}

.ws-portfolio-activity__activity-impact--neutral {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 100px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  background: #007BFF;
  color: rgba(255, 255, 255, 0.87);
}

.ws-portfolio-activity__footer {
  display: flex;
  padding: 48px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ws-portfolio-activity__table_header,
.ws-portfolio-activity__activities {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 500000px;
  opacity: 1;
  overflow: auto;
  background: #FFF;
}

.ws-portfolio-activity__table_header::-webkit-scrollbar {
  display: none;
}

.ws-portfolio-activity__activities::-webkit-scrollbar {
  display: none;
}

.ws-portfolio-activity__table_header.closed,
.ws-portfolio-activity__activities.closed {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

body.dark {
  .ws-portfolio-activity__header {
    background: #1E1E1E;

    &.closed {
      border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    }
  
    &.closed:hover {
      background: rgba(0, 123, 255, 0.08);
    }

    svg path {
      fill: #FFF;
      fill-opacity: 0.56;
    }
  }

  .ws-portfolio-activity__date {
    color: #FFF;
  }

  .ws-portfolio-activity__date span {
    color: rgba(255, 255, 255, 0.38);
  }

  .ws-portfolio-activity__table_header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    background: #1E1E1E;
    color: rgba(255, 255, 255, 0.38);
    
    .ws-portfolio-activity__activity-portfolio {
      color: rgba(255, 255, 255, 0.38);
    }
  }

  .ws-portfolio-activity__activity {
    background: #363636;

    .ws-portfolio-activity__activity-price {
      color: #FFF;
    }

    .ws-portfolio-activity__activity-change {
      color: #FFF;
    }
  }

  .ws-portfolio-activity__activity-portfolio {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-activity__activity-ticker-icon {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-activity__company {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-activity__activity-impact--positive {
    background: #E8F5E9;
    color: #388E3C;
  }

  .ws-portfolio-activity__activity-impact--negative {
    background: #FEEBEE;
    color: #D32F2F;
  }

  .ws-portfolio-activity__activity:last-child {
    height: 52px;
    padding-bottom: 0;
  }

  .ws-portfolio-activity__footer {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-activity__activities {
    background: #363636;
  }
}

@media (max-width: 1399px) {
  .ws-portfolio-activity__activity-impact {
    width: 100px;
  }
}

@media (max-width: 1210px) {
  .ws-portfolio-activity__activity-ticker {
    width: 100px;
  }

  .ws-portfolio-activity__company {
    display: none;
  }
}

@media (max-width: 1011px) {
  .ws-portfolio-activity__activity-ticker {
    width: 50px;
  }

  .ws-portfolio-activity__activity-icon {
    display: none;
  }
}

@media (max-width: 991px) {
  .ws-portfolio-activity__header {
    top: 48px;
  }

  .ws-portfolio-activity__table_header {
    top: 100px;
  }

  .ws-portfolio-activity__activity-portfolio {
    padding-left: 16px;
  }

  .ws-portfolio-activity__activity-impact {
    padding-right: 16px;
  }

  .ws-portfolio-activity__header {
    padding: 16px;
  }
}
