.animated-mentors-dropdown {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &.dropdown-open {
    .animated-mentors-dropdown__items {
      max-height: 300px;
    }
  }

  &.dropdown-coming-soon {
    border: rgba(0, 0, 0, 0.14);
    cursor: initial;

    .animated-mentors-dropdown__title {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.animated-mentors-dropdown__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.animated-mentors-dropdown__items {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all ease 0.35s;
}

.animated-mentors-dropdown__item {
  padding: 10px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 123, 255, 0.04);
    border-radius: 4px;
  }

  &.animated-mentors-dropdown__item--selected {
    color: #007bff;
    pointer-events: none;
  }
}

body.dark {
  .animated-mentors-dropdown {
    border: 1px solid #2d2d2d;
    background: #2d2d2d;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.12),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.2);
    color: #fff;

    &.dropdown-coming-soon {
      border: rgba(255, 255, 255, 0.14);

      .animated-mentors-dropdown__title {
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }

  .animated-mentors-dropdown__title {
    color: #fff;
  }

  .animated-mentors-dropdown__item {
    color: #fff;

    &:hover {
      background: rgba(0, 158, 255, 0.08);
    }
  }

  .animated-mentors-dropdown__item--selected {
    color: #5eeecd;
  }
}
