.dxfeed-radar {
  position: relative;
  overflow: hidden !important;
  height: 90vh !important;
  width: 100%;
  border-radius: 16px;
}
.dxfeed-radar-content-limit__row {
  display: none !important;
}
.ant-dxfeed-dropdown-trigger {
  padding: 3px;
}
.dxfeed-radar-filter-tabs__tab-panel-wrapper {
  max-height: none !important;
}
