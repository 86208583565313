.delete-account-button {
  background-color: #ff0000 !important;
  color: #fff !important;
}
.profile-page {
  margin-top: 30px;
}

.profile-page__header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.profile-page__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
}

.profile-page__item-label {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: left;
}

.profile-page__item-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;
}

.profile-page__item-value {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-page__item-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.profile-page__item-action {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.profile-page__item-action.positive-color {
  color: #007bff;
}

.profile-page__item .MuiFormControl-root {
  width: 180px;
}

.profile-page .MuiInputBase-root {
  width: 180px;
}

.profile-page__item .MuiInputBase-root::before {
  border-bottom: 1px solid #e4e5e8 !important;
}

.profile-page__item .MuiInputBase-root input {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

div[aria-labelledby="profile-page__item-select-label"] {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

div[aria-labelledby="profile-page__item-select-label"]:focus {
  background-color: transparent;
}

.default-action-button {
  border-radius: 6px !important;
  border: 1px solid #007bff !important;
  background-color: #007bff !important;
  padding: 7px 20px !important;
  color: #fff !important;
  text-align: center !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: initial !important;
}

.profile-page__item-input svg.MuiSvgIcon-root {
  fill: #a6a6a6;
}

body.dark {
  .profile-page__header {
    color: #fff;
  }

  .profile-page__item-label {
    color: #fff;
  }

  .profile-page__item-action.positive-color {
    color: #5eeecd;
  }

  .default-action-button {
    border: 1px solid #5eeecd !important;
    background-color: #5eeecd !important;
    color: #000000 !important;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }

  .MuiAutocomplete-endAdornment {
    button.MuiAutocomplete-popupIndicator svg {
      color: #fff;
    }
  }

  .MuiAutocomplete-root .MuiAutocomplete-clearIndicator {
    svg {
      color: #fff;
    }
  }
}

@media (max-width: 767px) {
  .profile-page__item {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .profile-page__header {
    text-align: center;
  }

  .profile-page__item-row {
    flex-direction: column;
    gap: 10px;
    height: auto;
  }

  .profile-page__item-label {
    text-align: center;
  }

  .profile-page__item .MuiFormControl-root {
    width: 100%;
    max-width: 200px;
  }

  .profile-page .MuiInputBase-root {
    width: 100%;
    max-width: 200px;
  }

  .profile-page__item {
    margin-bottom: 40px;
  }

  .profile-page__item-center-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.delete-confirmation-modal .MuiDialogTitle-root {
  color: #000;
}

.delete-confirmation-modal .MuiDialogContentText-root {
  color: #000;
}

body.dark .delete-confirmation-modal .MuiDialogTitle-root {
  color: #fff;
}

body.dark .delete-confirmation-modal .MuiDialogContentText-root {
  color: #fff;
}

.delete-confirmation-modal .MuiButton-containedSecondary {
  background-color: #ff0000 !important;
  color: #fff !important;
}
