.popular-stocks {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(228, 229, 232, 0.25);
  width: 100%;
  height: 100%;
  padding: 18px 23px;
  position: relative;
}

.popular-stocks-title {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 6px;

  .MuiSvgIcon-root {
    color: #cccccc;
  }
}

.popular-stocks-timestamp {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.popular-stocks-content {
  position: absolute;
  width: calc(100% - 46px);
  height: calc(100% - 103px);
}

.popular-stocks-content--inner {
  height: 100%;
  overflow: auto;
}

.popular-stocks-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-right: 5px;
  gap: 5px;
}

.popular-stocks-item-left {
  cursor: pointer;
}

.popular-stocks-item-ticker {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

.popular-stocks-item-name {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popular-stocks-item-price {
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

.popular-stocks-item-change {
  color: #3eb489;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popular-stocks-item-change--positive-color {
  color: #3eb489;
}

.popular-stocks-item-change--negative-color {
  color: #ea3323;
}

.popular-stocks-item-change--neutral-color {
  color: #cccccc;
}

body.dark {
  .popular-stocks {
    border: 1px solid #5eeecd;
    background: #000;
    box-shadow: none;
  }

  .popular-stocks-title {
    color: #ffffff;
  }

  .popular-stocks-timestamp {
    color: #5eeecd;
  }

  .popular-stocks-item-ticker {
    color: #ffffff;
  }

  .popular-stocks-item-name {
    color: #e4e5e8;
  }

  .popular-stocks-item-price {
    color: #ffffff;
  }
}

@media (max-width: 1400px) {
  .popular-stocks-content {
    height: 150px;
    position: relative;
    width: 100%;
  }

  .popular-stocks-content--inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  }

  .popular-stocks-content--inner.skeleton-loading {
    gap: 0;
  }

  .popular-stocks-item {
    width: calc((100% - 60px) / 4);
    margin-bottom: 0;
  }
}

@media (max-width: 1199px) {
  .popular-stocks-item {
    width: calc((100% - 40px) / 3);
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .popular-stocks-item {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 500px) {
  .popular-stocks-item {
    width: 100%;
  }
}
