$primary-blue-main: #007bff;
$text-primary: rgba(0, 0, 0, 0.87);
$text-secondary: rgba(0, 0, 0, 0.6);
$text-disabled: rgba(0, 0, 0, 0.38);
$fontSize-semantic-h5: 24px;
$fontSize-semantic-body1: 16px;
$fontSize-semantic-body2: 14px;
$borderRadius-s: 4px;
$borderWidth-s: 1px;
$borderColor: rgba(0, 123, 255, 0.5);
body.wealth-series-lite-onboarding {
    .sidebar-layout-content{
        background: #EEF5FD !important;   
    }
    #layout-wrapper {
        background: #EEF5FD !important;   
        margin: 0 0 !important; 
    }
    background: #EEF5FD !important;
    .layout-wrapper{
        margin: 0px !important;
        background: #EEF5FD !important;
    }
    .home-header {
        background: #eef5fd !important;
    }

    &.dark {
        .header-profile-user-name {
            color: #000;
        }

        .header-profile-user-type {
            color: #979797;
        }

        .header-search {
            .MuiOutlinedInput-root {
                color: #34404A;
                border: 1px solid white;
                background-color: white;
            }
        }
    }
}
.ws-lite-onboarding-primary-button {
    padding: 8px 24px !important;
    border-radius: 4px !important;
    background: #007BFF !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20) !important;
    color: #FFF !important;
    font-family: Inter !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    letter-spacing: 0.46px !important;
    text-transform: initial !important;
}
.ws-lite-onboarding-secondary-button {
    padding: 8px 24px !important;
    border-radius: 4px !important;
    background: #007BFF !important;
    border: 1px solid rgba(0, 123, 255, 0.50);
    background: transparent !important;
    color: #007BFF !important;
    font-family: Inter !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    letter-spacing: 0.46px !important;
    text-transform: initial !important;
}
.onboarding-image{
    width: auto;
    height: 100%;
    padding-top: 200px;
}
.lite-onboarding-container {
    background: #EEF5FD;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 79vh;
    overflow: hidden;
}
.pb-40{
    padding-bottom: 40px;
}

.step-2 {
    min-height: 79vh !important;
    justify-content: space-between !important;
    height: 100%;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 15px;
    .actions-container{
        padding-left: 0px !important;
        position: relative;
        top: 15px;
    }
    .flex-col {
        gap: 37px;
    }
}

.pl-40 {
    padding-left: 40px;
}

.main-container {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.step-2 {
    .header-container {
        justify-content: center;
    }
}

.header-container {
    display: flex;
    padding-left: 40px;
    align-items: center;
    gap: 16px;
    color: $text-primary;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    justify-content: start;
    font: 300 32px/1.6 Inter, sans-serif;
}

@media (max-width: 991px) {
    .header-container {
        padding-left: 20px;
        white-space: initial;
    }
}

.image-logo {
    aspect-ratio: 1.97;
    object-fit: contain;
    object-position: center;
    width: 122px;
    border-radius: 7px;
}

.text-container {
    display: flex;
    margin-top: 32px;
    width: 565px;
    max-width: 100%;
    padding-left: 40px;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .text-container {
        padding-left: 20px;
    }
}

.title {
    color: $text-primary;
    font-feature-settings: "liga" off, "clig" off;
    font-size: $fontSize-semantic-h5;
    font-weight: 600;
    line-height: 1;
}

.step-2 {
    .title {
        max-width: 600px;
        text-align: center;
    }
    .buttons-wrapper{
        justify-content: center;
    }
    .subscription-notice{
        text-align: center;
        align-self: center;
    }
}

@media (max-width: 991px) {
    .title {
        max-width: 100%;
    }
}

.description {
    color: $text-secondary;
    font-feature-settings: "liga" off, "clig" off;
    font-size: $fontSize-semantic-body1;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 16px;
}

@media (max-width: 991px) {
    .description {
        max-width: 100%;
    }
}

.actions-container {
    display: flex;
    margin-top: 32px;
    width: 100%;
    padding-left: 40px;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .actions-container {
        padding-left: 20px;
    }
}

.buttons-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.46px;
    line-height: 26px;
    justify-content: start;
    flex-wrap: wrap;
    padding-top: 50px;
}

@media (max-width: 991px) {
    .buttons-wrapper {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .buttons-wrapper {
        padding-top: 30px;
    }
}

@media (max-width: 480px) {
    .buttons-wrapper {
        padding-top: 20px;
    }
}

.button-outline {
    border-radius: $borderRadius-s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-blue-main;
    padding: 8px 24px;
    border: $borderWidth-s solid $borderColor;
}

@media (max-width: 991px) {
    .button-outline {
        padding: 0 20px;
    }
}

.button-primary {
    border-radius: $borderRadius-s;
    background: $primary-blue-main;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 991px) {
    .button-primary {
        padding: 0 20px;
    }
}

.subscription-notice {
    color: $text-disabled;
    font-feature-settings: "liga" off, "clig" off;
    font-size: $fontSize-semantic-body2;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    margin-top: 16px;
    max-width: 480px;
}

@media (max-width: 991px) {
    .subscription-notice {
        max-width: 100%;
    }
}

video {
    width: auto;
    height: 100%;
    object-fit: cover;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.step-2 .flex-col, .step-2 .title, .step-2 .buttons-wrapper, .step-2 .subscription-notice {
  animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1);
}