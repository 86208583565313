.frame {
  background-image: url(./frame.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  max-width: 747px;
  /* max-width instead of width */
  width: 100%;
  /* Full width */
  min-height: 670px;
}

.tradegpt-container {
  display: flex;
  justify-content: center;
}

.content-container {
  padding-left: 51px;
  padding-top: 80px;
  padding-right: 31px;
  padding-bottom: 80px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 28px;

  .tradegpt-title {
    color: #000;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .line {
    width: 66px;
    height: 1px;
    background: #86868b;
  }

  .para-text {
    color: #86868b;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .jump-start {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .tradegpt-button {
    display: flex;
    width: 285px;
    height: 58.5px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    text-transform: none;
    border-radius: 5px;
    background: linear-gradient(
      269.49deg,
      #5eeecd 0%,
      #007bff 100%
    ) !important ;
    color: #fff !important;
    font-family: Inter !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal;
    -webkit-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
    -moz-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
    box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  }
}

body.dark {
  .frame {
    background: #000;
  }

  .content-container .tradegpt-title {
    color: #fff;
  }

  .content-container .line {
    background: #a6a6a6;
  }

  .content-container .para-text {
    color: #a6a6a6;
  }

  .content-container .jump-start {
    color: #fff;
  }
}
