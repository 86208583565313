
.ws-book-mentorship {
  padding-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 30px;

  &.ws-dark-calendar {
    iframe {
      filter: invert(1) hue-rotate(180deg);
    }    
  }

  .calendly-inline-widget {
    flex: 1 1 auto;
  }
}

.ws-book-mentorship__selector {
  width: 160px;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px !important;
  }
}

body.dark {
  .ws-book-mentorship {
    color: rgba(255, 255, 255, 0.8);

    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
    }

    .MuiInputBase-root {
      svg {
        color: rgba(255, 255, 255, 0.54);
      }
    }
  }

  .ws-book-mentorship__selector { 
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #FFF !important;
    }
  }
}

@media (max-width: 991px) {
  .ws-book-mentorship {
    flex-direction: column;
    gap: 20px;

    .calendly-inline-widget {
      width: 100%;
    }
  }

  .ws-book-mentorship__selector {
    width: 100%;
  }
}