.subscribe-plan-item {
  border-radius: 10px;
  padding: 25px;
  max-width: 420px;
  background-size: cover !important;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 380px;
}
.plat-sub-item {
  max-width: 804px;
}
.subscribe-plan-item-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 36px;
  margin-bottom: 10px;
}

.subscribe-plan-item-includes-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.subscribe-plan-item-includes-entries {
  list-style-type: none;
  margin-bottom: 15px;
  flex: 1 1 auto;
}

li.subscribe-plan-item-includes-entry {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

li.subscribe-plan-item-includes-entry::before {
  content: "•";
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-right: 10px;
}

.subscribe-plan-item-price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 20px;
}

.subscribe-plan-item-button {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #232323 !important;
  padding: 6px 23px !important;
  text-transform: initial !important;
  border-radius: 7px !important;
  width: 140px !important;
}

.starter-item {
  color: #ffffff;

  &.subscribe-plan-item {
    background: url(../../../assets/images/starter.png);
  }

  .subscribe-plan-item-button {
    background: #5eeecd !important;
  }

  .subscribe-plan-item-type {
    color: #5eeecd;
  }
}

.intermediate-item {
  color: #ffffff;

  &.subscribe-plan-item {
    background: url(../../../assets/images/growth.png);
  }

  .subscribe-plan-item-button {
    background: #00dd9c !important;
  }

  .subscribe-plan-item-type {
    color: #00dd9c;
  }
}

.advanced-item {
  color: #ffffff;

  &.subscribe-plan-item {
    background: url(../../../assets/images/advanced.png);
  }

  .subscribe-plan-item-button {
    background: #31f3ff !important;
  }

  .subscribe-plan-item-type {
    color: #31f3ff;
  }
}

.platinum-item {
  color: #ffffff;
  position: relative;

  &.subscribe-plan-item {
    background: url(../../../assets/images/pro.png);
  }

  .subscribe-plan-item-button {
    background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%) !important;
  }

  .subscribe-plan-item-type {
    background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .most-recommended-badge {
    position: absolute;
    right: 0;
    top: 0;
    width: 120px;
  }
}

.subscribe-plan-item-small-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* White */

  color: #ffffff;
}
