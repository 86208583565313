.ws-portfolio-header {
  display: flex;
  padding: 0px 24px 8px 24px;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
}

.ws-portfolio-header__back {
  display: flex;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1C1C1E;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    fill: #000;
    fill-opacity: 0.56;
  }
}

.ws-portfolio-header__timestamp {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  letter-spacing: -0.13px;
}

.ws-portfolio-content {
  display: flex;
  padding: 0px 24px 16px 24px;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-content-fixed {
  width: calc(33% - 8px);
  display: flex;
  padding: 16px 0 0 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: #ffffff;
  height: calc(100vh - 185px);
}

.ws-portfolio-content-container {
  width: calc(67% - 8px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: auto;
  height: calc(100vh - 185px);

  &::-webkit-scrollbar {
    display: none;
  }
}

.ws-portfolio-content-container__top {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.ws-portfolio-content-container__top__half {
  width: calc(50% - 4px);
  border-radius: 8px;
  background: #FFF;
}

.ws-scroll-element::-webkit-scrollbar {
  width: 7px;
  padding: 0 4px;
}

.ws-scroll-element::-webkit-scrollbar-thumb {
  background: #BDBDBD;
  border-radius: 4px;
}

.ws-scroll-element::-webkit-scrollbar-track {
  background: #EEE;
  border-radius: 4px;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.12) inset;
}

.ws-portfolio-content-container__bottom {
  width: 100%;
}

.ws-portfolio-disclaimer {
  color: rgba(0, 0, 0, 0.38);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  padding: 0 24px 16px 24px;
  position: fixed;
  bottom: 0;
}

body.dark {
  .ws-portfolio-header__back {
    color: #F2F2F5;

    svg {
      fill: #FFF;
    }
  }

  .ws-portfolio-header__timestamp {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-content-fixed {
    background: #2C2C2C;
  }

  .ws-portfolio-content-container__top__half {
    background: #2C2C2C;
  }
  
  .ws-scroll-element::-webkit-scrollbar-track {
    background: #616161;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.12) inset;
  }

  .ws-portfolio-disclaimer {
    color: #FFFFFF61;
  }
}

@media (max-width: 1499px) {
  .ws-portfolio-content-fixed {
    width: calc(50% - 8px);
    height: calc(100vh - 200px);
  }

  .ws-portfolio-content-container {
    width: calc(50% - 8px);
    height: calc(100vh - 200px);
  }

  .ws-portfolio-content-container__top {
    flex-direction: column;
  }

  .ws-portfolio-content-container__top__half {
    width: 100%;
  }
}

@media (max-width: 1099px) {
  .ws-portfolio-header__timestamp {
    padding-left: 16px;
  }

  .ws-portfolio-content {
    flex-direction: column;
    gap: 0;
  }

  .ws-portfolio-content-fixed {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: auto;
    background: transparent;
  }

  .ws-portfolio-content-container {
    width: 100%;
    border-radius: 0;
    height: auto;
  }

  .ws-portfolio-content-container__top__half {
    border-radius: 0;
    padding-top: 20px;
    background: transparent;
  }

  .ws-portfolio-news-wrapper {
    display: none;
  }

  .ws-portfolio-content-container__bottom {
    display: none;
  }

  .ws-portfolio-disclaimer {
    position: relative;
  }
}

@media (max-width: 991px) {
  .ws-portfolio-header {
    padding-left: 32px;
  }
  
  .ws-portfolio-header__timestamp {
    padding-left: 0;
  }
}