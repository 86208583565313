.lite-leaderboard__mobile_content {
  border-radius: 8px;
}

.color-green {
  color: #4CAF50 !important;
}

.color-red {
  color: #F44336 !important;
}

.lite-leaderboard__mobile_row {
  border-bottom: 1px solid #0000001F;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
}

.lite-leaderboard__mobile_row_basic {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.lite-leaderboard_mobile_row_basic_main_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.lite-leaderboard__mobile_row_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;

  button {
    padding: 0 !important;
    min-width: auto !important;

    svg {
      fill: #000;
      fill-opacity: 0.56;
    }
  }
}

.lite-leaderboard__mobile_row_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.lite-leaderboard__mobile_row_data_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.lite-leaderboard__mobile_row_data_row-label {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.lite-leaderboard__mobile_row_data_row-value {
  color:rgba(0, 0, 0, 0.87);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  display: flex;
  justify-content: flex-start;

  &.stocks_included {
    position: relative;
    left: -10px;
  }
}

.lite-leaderboard__mobile_row_basic_action {
  width: 100%;
  text-align: center;

  button {
    padding: 0 !important;
    min-width: auto !important;
  }

  svg {
    fill: #000;
    fill-opacity: 0.56;
  }
}


.lite-leaderboard__mobile_row {
  .lite-leaderboard__expanded-body {
    width: 100%;
  }

  .lite-leaderboard__expanded_details {
    overflow: auto;
  }

  .lite-leaderboard__expanded_details::-webkit-scrollbar {
    display: none;
  }

  .lite-leaderboard__expanded_details_ph {
    width: 170px;
  }
  
  .lite-leaderboard__expanded_details_current_price {
    width: 110px;
    min-width: auto;
    flex: initial;
  }

  .lite-leaderboard__expanded_details_tracked_price {
    width: 110px;
    min-width: auto;
    flex: initial;
  }

  .lite-leaderboard__expanded_details_overall_performance {
    width: 150px;
    min-width: auto;
    flex: initial;
  }

  .lite-leaderboard__expanded_details_period_performance {
    width: 110px;
    min-width: auto;
    flex: initial;
  }

  .lite-leaderboard__expanded_details_header {
    gap: 8px;
    width: auto;
  }

  .lite-leaderboard__expanded_details_row {
    gap: 8px;
    width: auto;
  }

  .lite-leaderboard__expanded-content.expanded {
    max-height: 800px;
  }
}

body.dark {
  .lite-leaderboard__mobile_content {
    box-shadow: 1px 1px 15px 0px rgba(242, 242, 245, 0.08);
  }

  .lite-leaderboard__mobile_row_header {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  .lite-leaderboard__mobile_row_data_row-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .lite-leaderboard__mobile_row_data_row-value {
    color: #fff;
  }

  .color-green {
    color: #A5D6A7 !important;
  }

  .color-red {
    color: #EF9A9A !important;
  }

  .lite-leaderboard__mobile_row_basic_action svg {
    fill: #fff;
  }

  .lite-leaderboard__mobile_row {
    border-bottom: 1px solid #FFFFFF1F;
  }
}