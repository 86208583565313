.emerald-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.emerald-page__header {
  width: 100%;
}

.emerald-page__logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;

  svg {
    width: 100%;
    height: auto;
  }
}

.emerald-page__logo {
  width: 60px;
  flex: 0 0 auto;
  cursor: pointer;
}

.emerald-page__text-logos {
  display: flex;
  flex-direction: column;
  width: 175px;
  gap: 10px;
  flex: 0 0 auto;
  cursor: pointer;
}

.emerald-page__logo-splitter {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}

@media (max-width: 767px) {
  .emerald-page__logo-container {
    flex-direction: column;
  }
}
