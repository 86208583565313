.emerald-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  width: 100%;
}

.emerald-tabs__tab {
  color: #a6a6a6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.emerald-tabs__tab.active {
  color: #000000;
  border-bottom: 4px solid #5eeecd;
}

body.dark {
  .emerald-tabs__tab.active {
    color: #ffffff;
  }
}

@media (max-width: 991px) {
  .emerald-tabs__tab {
    width: calc((100% - 75px) / 4);
  }
}

@media (max-width: 767px) {
  .emerald-tabs {
    flex-wrap: wrap;
  }

  .emerald-tabs__tab {
    width: calc((100% - 25px) / 2);
  }
}
