.outer-modal-container {
  text-align: left;
  width: 50%;
  /* height: 900px; */
  position: absolute;
  top: 8%;
  left: 26%;
  padding: 40px;
}

.logo-position {
  position: absolute;
  top: 20px;
  left: 20px;
}

.x-icon-position {
  position: absolute;
  top: 20px;
  right: 19px;
}

.rectangle-1 {
  width: 695px;
  height: 260px;
  background: #141414;
}

.ta-logo-modal {
  width: 20px;
  height: 20px;
}

.ta-logo-modal-black {
  width: 30px;
  height: 30px;
}

.important-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.welcome-text {
  margin-top: 9px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

.top-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.ensure-success {
  position: relative;
  padding: 5px;
  top: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #141414;
}

.checkmark {
  width: 16px;
}

.checkbox-labels {
  background: #ffffff;
  border: 1px solid #686868ab;
  border-radius: 8px;
  margin: 10px;
  padding: 9px;
}

.span-check {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */
  text-align: left;
  color: #474747;
}

.modal-title-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.modal-gradient-text {
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-gradient-text-2 {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-desc {
  color: #a3a3a3 !important;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first-modal-title {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  padding-left: 20px;
  padding-top: 12px;
}

.gap-15 {
  gap: 15px;
}

.welcome-blue {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main-power-text {
  color: #a3a3a3;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.align-start {
  align-items: start;
}

.next-button {
  width: 101px;
  height: 22px;
  border-radius: 5px;
  background: linear-gradient(269deg, #5eeecd 0%, #007bff 100%);
}
