.platinum-program-page-real-time-trading-content {
  margin-top: 10px;
  position: relative;
}

.platinum-program-page-real-time-trading-info {
  background: #161616;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.platinum-program-page-real-time-trading-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 8px;
}

.platinum-program-page-real-time-trading-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #e4e5e8;
  margin-bottom: 20px;
}

.platinum-program-page-real-time-trading-join-button {
  background: linear-gradient(
    101.16deg,
    #3183ff -7.41%,
    #0751bf 110.44%
  ) !important;
  background-color: #121416 !important;
  padding: 8px 16px !important;
  cursor: pointer !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #ffffff !important;
  width: 230px !important;
  text-transform: initial !important;
  border-radius: 20px !important;
}

.platinum-program-page-real-time-trading-body {
  padding: 24px;
  width: calc(100% - 114px);
}

.trading-image-wrapper {
  width: 114px;
  position: relative;
}

.trading-image {
  width: 100%;
}

body.light {
  .platinum-program-page-real-time-trading-info {
    background: #ffffff;
  }

  .platinum-program-page-real-time-trading-title {
    color: #000;
  }

  .platinum-program-page-real-time-trading-description {
    color: #7a7a7a;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .platinum-program-page-real-time-trading-title {
    font-size: 22px;
  }

  .platinum-program-page-real-time-trading-description {
    font-size: 14px;
  }

  .trading-image {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .platinum-program-page-real-time-trading-body {
    width: 100%;
  }

  .trading-image-wrapper {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .platinum-program-page-real-time-trading-title {
    font-size: 22px;
  }

  .platinum-program-page-real-time-trading-description {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .platinum-program-page-real-time-trading-body {
    width: 100%;
  }

  .trading-image-wrapper {
    display: none;
  }

  .trading-image {
    display: none;
  }
}
