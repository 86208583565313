.live-options-settings-page {
  margin-top: 30px;
}

.live-options-settings-page__header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}

.live-options-settings-page__subheader {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.live-options-settings-page__content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
}

.live-options-settings-page__menu__item {
  width: 250px;
}

.live-options-settings-page__menu__item__title {
  color: #000;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.settings-page-menu-item {
  padding: 6px !important;
  border-bottom: 2px solid #e4e5e8 !important;
}

.settings-page-menu-item:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #007bff !important;
}

.settings-page-menu-item svg:last-of-type {
  fill: #ccc;
}

.live-options-settings-page-custom-menu-item {
  width: 170px !important;
  padding-left: 10px !important;
  border-radius: 0 !important;
  left: 0 !important;
}

.live-options-settings-page-custom-list-item-text span {
  right: 5px !important;
}

.settings-page-done-checked-icon {
  min-width: auto !important;
}

body.dark {
  .live-options-settings-page__header {
    color: #fff;
  }

  .live-options-settings-page__subheader {
    color: #86868b;
  }

  .live-options-settings-page__menu__item__title {
    color: #fff;
  }
}

.live-options-settings-page__menu__item.search-box {
  .selected-tickers {
    flex-wrap: wrap;
  }
}
