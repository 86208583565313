.all-videos-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-height: calc(100vh - 290px);
  overflow: auto;
}

.all-videos-list__video {
  width: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;

  &:hover {
    background: #e4e5e8;
  }

  &.active {
    background: #e4e5e8;
  }
}

.all-videos-list__video__thumbnail {
  width: calc(50% - 7.5px);
  position: relative;

  img {
    width: 100%;
    border-radius: 10px;
  }
}

.all-videos-list__video__now_playing {
  border-radius: 0px 0px 10px 10px;
  background: rgba(166, 166, 166, 0.5);
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #e4e5e8;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 13px;
}

.all-videos-list__video__info {
  width: calc(50% - 7.5px);
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.all-videos-list__video__title {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.all-videos-list__video__length {
  color: #86868b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

body.dark {
  .all-videos-list__video.active {
    background: #000;
  }

  .all-videos-list__video:hover {
    background: #28292d;
  }

  .all-videos-list__video__title {
    color: #fff;
  }

  .all-videos-list__video__length {
    color: #e4e5e8;
  }
}

@media (max-width: 1399px) {
  .all-videos-list {
    flex-direction: row;
    max-height: initial;
    align-items: stretch;
  }

  .all-videos-list__video {
    width: calc((100% - 30px) / 3.5);
    flex: 0 0 auto;
    flex-direction: column;
  }

  .all-videos-list__video__thumbnail {
    width: 100%;
  }

  .all-videos-list__video__info {
    width: 100%;
  }

  .all-videos-list__video__title {
    font-size: 18px;
  }

  .all-videos-list__video__length {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .all-videos-list__video {
    width: calc((100% - 20px) / 2.5);
  }

  .all-videos-list__video__title {
    font-size: 20px;
  }

  .all-videos-list__video__length {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .all-videos-list__video {
    width: calc((100% - 10px) / 1.5);
  }
}

@media (max-width: 500px) {
  .all-videos-list__video {
    width: 100%;
  }
}
