.sendbird-conversation {
  border: none;
}

.sendbird-chat-header__info {
  display: none !important;
}

/* To set max-height and width for thumbnail image */
/* .sendbird-thumbnail__img {
    max-width: 240px !important;
    max-height: 240px !important;
  }
   */
/* Cosmetic touches */
.sendbird-conversation__footer {
  padding: 0px 12px 8px 12px !important;
}
