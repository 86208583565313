.chart-settings-page {
  margin-top: 30px;
}

.chart-settings-page__header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}

.chart-settings-page__subheader {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chart-settings-page__content {
  margin-top: 30px;
  display: flex;
  gap: 40px;
}

.chart-settings-page__content__menu {
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.chart-settings-page__content__menu__item {
  width: 100%;
}

.chart-settings-page__content__menu__item__title {
  color: #000;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.chart-settings-page__content__menu__item__data {
  width: 100%;
}

.chart-settings-page__content__chart_preview {
  width: 75%;
  flex: 0 0 auto;
  padding: 10px;
}

.chart-settings-page__content__chart_preview__header {
  color: #000;
  text-align: left;
  font-family: Inter;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  top: -10px;
}

.chart-settings-page__content__chart_preview__chart {
  width: 100%;
}

.settings-page-menu-item {
  padding: 6px !important;
  border-bottom: 2px solid #e4e5e8 !important;
}

.settings-page-menu-item:hover {
  background-color: transparent !important;
  border-bottom: 2px solid #007bff !important;
}

.settings-page-menu-item svg:last-of-type {
  fill: #ccc;
}

.settings-page-custom-menu-item {
  width: auto !important;
  padding-left: 10px !important;
  border-radius: 0 !important;
  left: 0 !important;
}

.settings-page-custom-list-item-text span {
  right: 23px !important;
}

.settings-page-done-checked-icon {
  min-width: auto !important;
}

body.dark {
  .chart-settings-page__header {
    color: #fff;
  }

  .chart-settings-page__subheader {
    color: #86868b;
  }

  .chart-settings-page__content__menu__item__title {
    color: #fff;
  }

  .chart-settings-page__content__chart_preview__header {
    color: #fff;
  }

  .chart-settings-page__content__chart_preview__chart {
    color: #86868b;
  }

  .settings-page-menu-item:hover {
    border-bottom: 2px solid #5eeecd !important;
  }
}

@media (max-width: 767px) {
  .chart-settings-page__content {
    flex-direction: column;
    gap: 50px;
  }

  .chart-settings-page__content__menu {
    max-width: 100%;
  }

  .chart-settings-page__content__chart_preview {
    width: 100%;
  }

  .menu__item-space {
    margin-bottom: 0;
  }
}
