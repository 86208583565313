.om-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.om-tab {
  padding: 9px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
  }
}

body.dark {
  .om-tab {
    color: rgba(255, 255, 255, 0.6);

    &.active {
      color: #5eeecd;
      border-bottom: 2px solid #5eeecd;
    }
  }
}
