.sign-up-hint {
  padding: 17px 24px;
}

.sign-up-hint-big-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 40px;
}

.sign-up-hint-green-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #5eeecd;
}

.sign-up-hint-small-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  color: #f5f5f5;
  margin-bottom: 40px;
}

a.sign-up-hint-anchor {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #00dc0a;
  margin-bottom: 5px;
  display: block;
}

.sign-up-hint-ul {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 180%;
  color: #f5f5f5;
  list-style-type: disc;
  padding-left: 22px;
}

.platinum-program-method {
  color: #00dc0a;
}

body.light {
  .sign-up-hint-big-text {
    color: #000;
  }

  .sign-up-hint-small-text {
    color: #7a7a7a;
  }

  .sign-up-hint-ul {
    color: #6e6e70;
  }

  .sign-up-hint-green-text {
    color: #007bff;
  }
}
