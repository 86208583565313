.slider-tooltip {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100% !important;
}

.p-5 {
  padding: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.slider-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mt-15 {
  margin-top: 15px;
}

.top-title-o-wrap {
  display: flex;
  flex-direction: column;
}

.min-prem-light {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.min-prem-dark {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  color: white;
}

.min-prem-value-light {
  color: #007bff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.min-prem-value-dark {
  color: #007bff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.exp-within-light {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.exp-within-dark {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  color: white;
}

.exp-within-value-light {
  color: #007bff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.exp-within-value-dark {
  color: #007bff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.center-flex {
  display: flex;
  justify-content: flex-start;
}

.grid-temp {
  display: grid;
  grid-template-columns: 565px 1fr;
  grid-template-rows: 150px 1fr;
  max-height: 50px;
  position: relative;
  top: -10px;
}

.filter-cards {
  height: max-content;
  margin-bottom: 20px;
  width: calc(100% - 30px);
  position: relative;
  left: 15px;
}

.flexin-end {
  display: flex;
  justify-content: flex-end;
}

.calendar-button {
  position: relative;
  top: -3px;
}

.dates {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
}

.dates-label {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.062px;
}

.dates-label-light {
  color: #000;
}

.dates-label-dark {
  color: #fff;
}

.summary-blocks {
  margin-top: 30px;
  margin-bottom: 24px;
}

.main-filters {
  display: flex;
  align-items: center;
}

.customDatePicker {
  .ant-picker-range-separator {
    display: none;
  }

  .ant-picker-input {
    cursor: pointer;

    input {
      display: none;
    }
  }

  .ant-picker-clear {
    display: none;
  }

  .ant-picker-suffix {
    pointer-events: all;
  }
}

.datePickerFooter {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

tr#transparent {
  background: transparent !important;
}

.contents {
  position: relative;
  //width: calc(100% - 240px);
  width: calc(100%);
  display: flex;
  flex-direction: column;

  .contents-title {
    margin-top: 26px;
    padding-left: 24px;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.062px;
  }

  .contents-title-light {
    color: #000;
  }

  .contents-title-dark {
    color: #fff;
  }

  .summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    width: 100%;

    .block {
      flex: 0 0 25%;
      max-width: calc(25% - 0.5rem);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;

      .block-label {
        font-size: 11px;
        letter-spacing: 1.428px;
      }

      .block-value {
        font-size: 20px;
        line-height: 30px;
      }

      .ant-progress-text {
        font-size: 18px;
        line-height: 18px;
        color: #9ea7b3;
        font-weight: 700;
      }
    }
  }

  .top-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main-filters {
      .filter-item {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .advanced-filters {
      display: flex;

      .ant-space-item {
        width: 300px;
      }

      .ant-input {
        border-radius: 15px 0 0 15px;
        border-color: transparent;
      }

      span.ant-input-group-addon {
        border-color: transparent;
        border-radius: 0 15px 15px 0;

        button.ant-input-search-button {
          border-color: transparent;
          border-radius: 0 15px 15px 0;

          span.anticon-search {
            position: relative;
            top: -3px;
            color: #0167ff;
          }
        }
      }

      .manage-filters {
        font-size: 13px;
        padding: 10px 16px;
        border: 1px solid #b5bcc5;
        border-radius: 15px;
        margin-left: 10px;
        margin-right: 35px;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          margin-right: 7px;
        }
      }

      .manage-filters.off {
        color: #9ea7b3 !important;
        background: transparent;
      }

      .manage-filters.on {
        color: #ffffff !important;
        background: #9ea7b3;
      }
    }
  }

  .top-filter.mobile-dark-liquidity {
    display: none;
    width: 100%;
  }

  .top-filter.desktop-dark-liquidity {
    display: flex;
    width: 100%;
  }

  .dark-pool-datatable-container {
    display: flex;
    flex: 1;
    overflow: auto;
    width: 100%;

    .dark-pool-datatable {
      border-collapse: separate;
      border-spacing: 0 20px;
      text-align: left;
      position: relative;

      th.text-boldest.cursor-pointer {
        // background: #f6f6f6;
        position: sticky;
        top: 0;
      }

      th,
      td {
        padding: 10px;
        border-bottom: unset;
        border-top: unset;
        user-select: none;
        vertical-align: baseline;
      }

      tr {
        transition: background-color 1000ms linear;
      }

      tr.new {
        transition: background-color 0ms linear;
        background-color: #ffff005e !important;
      }

      thead {
        line-height: 0;
      }

      .blue-text {
        color: #0071ee;
      }

      .green-text {
        color: #7dcb4f;
      }

      .BLOCK {
        width: 13px;
        height: 13px;
        border: 3px solid red;
        margin-right: 5px;
      }

      .SWEEP {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 3px solid #9b59b6;
        margin-right: 5px;
      }
    }
  }

  .bottom-filter {
    display: flex;

    .filter-button {
      border-radius: 5px;
      padding: 10px 1rem;
      background: #9ea7b3;
      cursor: pointer;
      margin-right: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    .filter-button.empty {
      background: transparent;
      color: #000000;
      padding: 10px 1rem;
    }
  }

  .bottom-filter.desktop-bottom-filter {
    display: flex;
  }

  .bottom-filter.mobile-bottom-filter {
    display: none;
  }
}

.filters {
  width: 250px;
  border-radius: 0 15px 15px 15px;
  padding: 1rem 0 1rem 0;
  z-index: 999;
  position: absolute;
  right: 0;
  height: calc(100vh - 100px);

  .icons-block {
    padding: 1rem 1rem 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span.circle-icon {
      border: 3px solid #dee1e5;
      color: #dee1e5;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    span.circle-icon.active {
      border: 3px solid #0071ee;
      color: #0071ee;
    }

    .fa-ellipsis-h {
      font-size: 20px;
      color: #dee1e5;
      cursor: pointer;
    }
  }

  .filter-blocks {
    height: calc(100vh - 220px);
    overflow-y: auto;
    padding: 0 1rem;

    .filter-block {
      padding: 2rem 0;
      border-top: 1px solid #dee1e5;

      .ticker-logo {
        font-size: 34px;
        width: 34px;
        display: flex;
        margin-right: 10px;
      }

      .ticker {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
      }

      .company {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
      }

      .date {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
      }

      .time {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: #9ea7b3;
      }

      .splitter {
        width: 1px;
        height: 22px;
        background: #dee1e5;
      }

      .info-block {
        .info-label {
          font-size: 12px;
          line-height: 12px;
          color: #9ea7b3;
          font-weight: 400;
        }

        .info-value {
          font-size: 12px;
          line-height: 22px;
          color: #000000;
          font-weight: 600;
        }
      }
    }
  }

  .second-filter {
    .clear-all {
      padding: 10px 30px;
      background: #f9fafb;
      color: #dee1e5;
      margin-right: 5px;
      width: calc(50% - 5px);
      text-align: center;
      cursor: pointer;
    }

    .run {
      padding: 10px 30px;
      background: #f9fafb;
      color: #000000;
      width: calc(50% - 5px);
      text-align: center;
      cursor: pointer;
    }

    .filter-item {
      border-radius: 5px;
      padding: 10px 0;
      background: #f0f4f5;
      margin-bottom: 5px;
      cursor: pointer;
      text-align: center;
    }

    .right-filter-list {
      height: calc(100vh - 180px);
      overflow-y: auto;
    }
  }
}

// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   //box-shadow: inset 0 0 5px transparent;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #0071ee;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #0071ee;
// }

.ticker-search-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  margin-bottom: 40px;
}

.live-options-filter-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  flex-direction: column;
  gap: 50px;
  padding: 0 24px;
  margin-bottom: 40px;
}

.live-options-filter-container.rangepicker-open {
  margin-bottom: 190px;
}

.up-7 {
  position: relative;
  top: -7px;
}

.live-option-date-selector {
  display: flex;
  padding: 6px 18px;
  align-items: center;
  gap: 25px;
  border-radius: 4px;
  border: 0.5px solid #007bff;
  color: #007bff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  svg path {
    fill: #007bff;
  }
}

.live-option-date-rangepicker {
  position: absolute;
  top: 38px;
  left: 150px;
  z-index: 9999999;
}

.live-options-slider-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 70px;
  padding: 0 24px;

  .MuiSlider-valueLabel {
    background: #5b5b5d !important;
  }
}

.live-options-slider {
  width: calc((100% - 140px) / 3);
}

.live-option-slider-filter {
  width: 100%;
}

.live-option-ticker-filter-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.live-option-search-filter {
  width: calc((100% - 80px) / 3);
}

.option-ticker-search {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
  flex-direction: column;
}

.option-ticker-search-label {
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.selected-tickers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  overflow-y: auto;
  margin-top: 6px;
  width: 100%;
  flex-wrap: wrap;
}

.selected-ticker-chip {
  display: flex !important;
  height: 24px !important;
  padding: 3px 4px !important;
  align-items: center !important;
  border-radius: 100px !important;
  background: #007bff !important;
  border: none !important;

  .MuiChip-label {
    padding: 0 6px !important;
    color: #fff !important;
    font-family: Inter !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0.16px !important;
  }

  svg {
    margin: 0 !important;
    width: 16px;
    height: 16px;
  }
}

.clear-all-chips-button {
  display: flex;
  height: 24px;
  padding: 3px 10px;
  align-items: center;
  border-radius: 100px;
  background: transparent;
  color: #007bff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  border: 1px solid #007bff;
  cursor: pointer;
}

.option-ticker-search-input {
  width: 100%;

  .MuiAutocomplete-popper {
    top: -2px !important;
  }
}

.bid-ask-select-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  overflow-y: auto;
  width: 100%;
  flex-direction: column;

  button {
    width: 100%;
    height: 36px;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    background: transparent;
    padding: 0 10px 0 0;
  }
}

.bid-ask-select {
  width: 100%;

  .MuiOutlinedInput-input {
    color: rgba(0, 0, 0, 0.38);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    padding: 11.5px 14px 5.5px 0 !important;
  }

  fieldset {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #007bff !important;
    border-width: 1px !important;
  }
}

.bid-ask-selected-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 6px;
}

.bid-ask-chip {
  display: flex !important;
  height: 24px !important;
  padding: 3px 4px !important;
  align-items: center !important;
  border-radius: 100px !important;
  border: none !important;

  .MuiChip-label {
    padding: 0 6px !important;
    color: #fff !important;
    font-family: Inter !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0.16px !important;
  }

  svg {
    margin: 0 !important;
    width: 16px;
    height: 16px;
  }
}

$charade: #282a37;
$bluebell: #979fd0;

.pure-css-loader {
  display: flex;
  justify-content: center;
  position: relative;
  top: -72px;

  div {
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: $bluebell;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

.option-ticker-search-input-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
body.dark {
  .option-ticker-search-label {
    color: #fff;
  }

  .option-ticker-search-input-field {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
  }

  .bid-ask-select {
    .MuiOutlinedInput-input {
      color: rgba(255, 255, 255, 0.6);
    }

    fieldset {
      border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    }

    svg path {
      fill: #fff;
    }
  }

  .selected-ticker-chip {
    background: #5eeecd !important;
    color: #000 !important;

    .MuiChip-label {
      color: #000 !important;
    }

    svg path {
      fill: #000 !important;
    }
  }

  .clear-all-chips-button {
    border: 1px solid #5eeecd;
    color: #5eeecd;
  }

  .MuiPaper-elevation {
    background: #1c1c1c;

    ul {
      border: none !important;
      color: #fff;
    }
  }

  .live-option-date-selector {
    border: 0.5px solid #5eeecd;
    color: #5eeecd;

    svg path {
      fill: #5eeecd;
    }
  }

  .live-options-slider-container {
    .MuiSlider-thumb {
      background-color: #5eeecd;
      color: #5eeecd;
      box-shadow: none;
    }
  }

  .min-prem-value-dark {
    color: #5eeecd;
  }

  .exp-within-value-dark {
    color: #5eeecd;
  }

  .MuiSlider-track {
    background-color: #5eeecd !important;
    border: 1px solid #5eeecd !important;
  }
}

@media (max-width: 1399px) {
  .live-options-slider-container {
    flex-wrap: wrap;
    padding: 0 40px;
    gap: 40px;
  }

  .live-options-slider {
    width: calc((100% - 40px) / 2);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .contents {
    position: relative;

    .summary {
      .block {
        flex: 0 0 50% !important;
        max-width: calc(50% - 0.25rem);
        margin-bottom: 0.5rem;
        border-radius: 16px;
      }
    }

    .top-filter {
      .main-filters {
        .filter-item {
          font-size: 16px;
        }
      }

      .advanced-filters {
        .ant-space-item {
          width: 220px;
        }
      }
    }

    .bottom-filter.desktop-bottom-filter {
      display: none;
    }

    .bottom-filter.mobile-bottom-filter {
      display: flex;
      width: calc(100% - 20px);

      .ant-carousel {
        width: 100%;
      }

      .filter-button {
        line-height: 45px;
      }

      .slick-slider {
        .slick-prev {
          left: -5px;
        }

        .slick-arrow {
          top: 20px !important;
        }

        .slick-arrow::before {
          color: #9ea7b3 !important;
        }

        .slick-arrow.slick-disabled::before {
          opacity: 0 !important;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .contents {
    position: relative;

    .summary {
      .block {
        flex: 0 0 50%;
        max-width: calc(50% - 0.25rem);
        margin-bottom: 0.5rem;
        border-radius: 16px;
      }
    }

    .top-filter {
      .main-filters {
        //margin-bottom: 20px;
      }

      .advanced-filters {
        .ant-space-item {
          width: 440px;
        }
      }
    }

    .top-filter.desktop-dark-liquidity {
      display: flex;
    }

    .dark-pool-datatable-container {
      tbody {
        gap: 15px;
        display: flex;
        flex-direction: column;
      }

      .ticker-name {
        font-size: 20px;
        line-height: 30px;
      }

      tr {
        display: flex;
        justify-content: space-between;

        td:last-child {
          padding-right: 40px !important;
        }

        td.tablet-view {
          padding: 15px;
          display: flex;
          flex-direction: column;
          font-size: 12px;

          .row-entity-heading {
            color: #9ea7b3;
            font-weight: 600;
          }
        }
      }
    }

    .dark-pool-datatable-container thead {
      display: none;
    }

    .desktop-view {
      display: none !important;
    }

    .bottom-filter.desktop-bottom-filter {
      display: none;
    }

    .bottom-filter.mobile-bottom-filter {
      display: flex;
      width: calc(100% - 20px);

      .ant-carousel {
        width: 100%;
      }

      .filter-button {
        line-height: 45px;
      }

      .slick-slider {
        .slick-prev {
          left: -5px;
        }

        .slick-arrow {
          top: 20px !important;
        }

        .slick-arrow::before {
          color: #9ea7b3 !important;
        }

        .slick-arrow.slick-disabled::before {
          opacity: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .filters {
    display: none;
  }

  .contents {
    position: relative;
    width: 100%;
    height: auto;

    .summary {
      .block {
        height: 100px;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 6px;
        padding: 0 35px 0 25px !important;
        border-radius: 16px;

        .block-label {
          font-size: 14px;
        }

        .block-value {
          font-size: 24px;
        }
      }
    }

    .top-filter {
      .filter-input-box {
        display: flex;

        .ant-space-vertical {
          width: 100%;

          .ant-space-item {
            width: 100%;
          }

          .ant-input {
            border-radius: 10px 0 0 10px;
            border-color: transparent;
          }
        }

        span.ant-input-group-addon {
          border-color: transparent;
          border-radius: 0 10px 10px 0;

          button.ant-input-search-button {
            border-color: transparent;
            border-radius: 0 10px 10px 0;

            span.anticon-search {
              position: relative;
              top: -3px;
              color: #0167ff;
            }
          }
        }

        .manage-filters {
          font-size: 13px;
          padding: 10px 16px;
          border: 1px solid #b5bcc5;
          border-radius: 15px;
          margin-left: 10px;
          margin-right: 35px;
          cursor: pointer;
          height: 40px;
          display: flex;
          align-items: center;

          img {
            width: 15px;
            margin-right: 7px;
          }
        }

        .manage-filters.off {
          color: #9ea7b3 !important;
          background: transparent;
        }

        .manage-filters.on {
          color: #ffffff !important;
          background: #9ea7b3;
        }
      }

      .manage-filter-button {
        text-transform: uppercase;
        font-size: 22px;
        line-height: 45px;
        color: #ffffff;
        background: #9ea7b3;
        text-align: center;
        cursor: pointer;
      }

      .main-filters {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        .filter-item {
          margin-right: 0;
        }

        .filter-item:first-child {
          margin-right: 1rem;
        }
      }
    }

    .top-filter.mobile-dark-liquidity {
      display: block;
    }

    .mobile-dark-liquidity {
      div.ant-select-auto-complete {
        width: 100%;
      }

      input.ant-input {
        border-color: transparent;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    .top-filter.desktop-dark-liquidity {
      display: none;
    }

    .dark-pool-datatable-container thead {
      display: none;
    }

    .dark-pool-datatable-container {
      .ticker-name {
        font-size: 27px;
        line-height: 30px;
      }

      .grayed {
        color: #9ea7b3;
      }

      .row-entity-heading {
        color: #9ea7b3;
        font-weight: 600;
      }

      tr td {
        font-size: 14px;
      }

      tr td:first-child {
        border-radius: 5px;
      }
    }

    .desktop-view {
      display: none !important;
    }

    .bottom-filter {
      display: none !important;
    }
  }

  .live-options-slider-container {
    flex-direction: column;
    gap: 30px;
  }

  .live-option-date-filter {
    width: calc(50% - 40px);
  }

  .live-option-slider-filter {
    width: 100%;
  }

  .live-option-search-filter {
    width: 100%;
  }

  .live-option-ticker-filter-wrapper {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .live-option-date-filter {
    width: calc(50% - 40px);
  }

  .live-option-slider-filter {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .live-options-slider-container {
    gap: 20px;
    padding: 0 20px;
  }

  .live-options-slider {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ticker-search-container {
    flex-direction: column;
  }

  .live-option-search-filter {
    width: 100%;
  }

  .live-option-date-filter {
    width: 100%;
  }

  .live-option-slider-filter {
    width: 100%;

    .row {
      flex-direction: column;
    }

    .col-6 {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .option-ticker-search {
    flex-direction: column;
    align-items: flex-start;
  }

  .selected-tickers {
    flex-wrap: wrap;
  }

  .live-option-search-filter {
    width: 100%;
  }

  .bid-ask-select-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .bid-ask-selected-options {
    flex-wrap: wrap;
  }
}
