.trade-algo-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(228, 229, 232, 0.25);
  padding: 20px 22px;
  width: calc((100% - 56px) / 3);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.trade-algo-card__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.trade-algo-card__header__top {
  width: 100%;
  min-height: 115px;
}

.trade-algo-card-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.trade-algo-card-type svg {
  width: 18px;
  height: 18px;
}

.trade-algo-card-company-ticker {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2px;
  display: block;
}

.trade-algo-card-company-name {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.trade-algo-card-company-price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.trade-algo-card-company-price span {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.trade-algo-card-company-performance {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 22px;
  padding: 2px 8px;
}

.trade-algo-card-company-performance--negative {
  background: rgba(234, 51, 35, 0.1);
  color: #ea3323;
}

.trade-algo-card-company-performance--positive {
  background: rgba(62, 180, 137, 0.1);
  color: #3eb489;
}

.trade-algo-card__header__bottom {
  width: 100%;
}

.trade-algo-card__sub-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.trade-algo-card__sub-header__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.trade-algo-card__sub-header__item__title {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.trade-algo-card__sub-header__item__value {
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.trade-algo-card__sub-header__item__value--negative {
  color: #ea3323 !important;
}

.trade-algo-card__sub-header__item__value--positive {
  color: #3eb489 !important;
}

.trade-algo-card__seperator {
  width: 100%;
  height: 1px;
  background: #e4e5e8;
}

.trade-algo-card__circular-progressbars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.trade-algo-card__circular-progressbars__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trade-algo-card__circular-progressbars__item-title {
  color: #94a3b8;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.trade-algo-card__news_sentiment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.trade-algo-card__news_sentiment_progressbar {
  width: calc(100% - 139px);
}

.trade-algo-card__news_sentiment__title {
  color: #94a3b8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 94px;
  flex: 0 0 auto;
}

.trade-algo-card__news_sentiment__value {
  color: #94a3b8;
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 25px;
  flex: 0 0 auto;
}

.trade-algo-card__news_sentiment__value--negative {
  color: #ea3323 !important;
}

.trade-algo-card__news_sentiment__value--positive {
  color: #3eb489 !important;
}

.trade-algo-card__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trade-algo-card__footer__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.trade-algo-card__footer__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.trade-algo-card__footer__item__title {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.trade-algo-card__footer__item__value {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.trade-algo-card__footer__item__value--negative {
  color: #ea3323 !important;
}

.trade-algo-card__footer__item__value--positive {
  color: #3eb489 !important;
}

body.dark {
  .trade-algo-card {
    border: 1px solid #5eeecd;
    background: #000;
  }

  .trade-algo-card-type {
    color: #86868b;
  }

  .trade-algo-card-company-ticker {
    color: #fff;
  }

  .trade-algo-card-company-name {
    color: #86868b;
  }

  .trade-algo-card-company-price span {
    color: #fff;
  }

  .trade-algo-card-company-performance--positive {
    background: rgba(62, 180, 137, 0.4);
    color: #5eeecd;
  }

  .trade-algo-card-company-performance--negative {
    background: rgba(234, 51, 35, 0.4);
    color: rgb(234 51 35);
  }

  .trade-algo-card__sub-header__item__value {
    color: #fff;
  }

  .trade-algo-card__seperator {
    background: #86868b;
  }

  .trade-algo-card__circular-progressbars__item-title {
    color: #fff;
  }

  .trade-algo-card__news_sentiment__title {
    color: #fff;
  }

  .trade-algo-card__news_sentiment__value--positive {
    color: #5eeecd !important;
  }

  .trade-algo-card__news_sentiment__value--negative {
    color: #ea3323 !important;
  }

  .trade-algo-card__footer__item__value {
    color: #fff;
  }
}

@media (max-width: 1299px) {
  .trade-algo-card {
    width: calc((100% - 28px) / 2);
  }
}

@media (max-width: 767px) {
  .trade-algo-card {
    width: 100%;
  }
}
