body.wealth-series-lite {
  .sidebar-layout-content{
    background: linear-gradient(360deg, rgba(227, 163, 150, 0.12) 0.53%, rgba(0, 123, 255, 0.12) 230.43%);
  }
  .home-header {
    background: #EAEEF5;
  }
}

.last-updated-text.last-updated-text-mobile {
  display: none;
}

.ws-lite-telegram-cta {
  border-bottom: none;
  border-radius: 4px;
  padding-right: 0;

  button.MuiButton-root {
    border: 1px solid rgba(0, 123, 255, 0.50) !important;
  }

  &.ws-lite-telegram-cta-mobile {
    display: none;
    margin-top: 16px;
    width: 100%;

    button.MuiButton-root {
      width: 100% !important;
      background: rgba(0, 123, 255, 0.05) !important;
      border: none !important;
    }
  }
}

.ws-lite-stock-picks {
  font-family: Inter;
  padding: 0 25px;
  min-height: calc(100vh - 96px);

  .MuiTabs-flexContainer {
    border-block-end: none;
  }
}

.ws-lite-overview__tabs {
  position: sticky;
  top: 72px;
  background: #EAEEF5;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e2e2;
  margin-top: 16px;
}

.ws-lite-overview__content {
  padding: 24px 0;
}

body.dark {
  &.wealth-series-lite .sidebar-layout-content {
    background: linear-gradient(130deg, rgba(252, 237, 236, 0.12) 0.53%, rgba(0, 158, 255, 0.12) 230.43%);
  }
  
  .ws-lite-overview__tabs {
    background: #505154;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .ws-lite-stock-picks {
    .last-updated-text {
      color: rgba(255, 255, 255, 0.70);
    }

    .ws-telegram-cta {
      border-bottom: none;
    }

    .ws-lite-telegram-cta button.MuiButton-root {
      border: 1px solid #5eeecd80 !important;
    }
  }
}

@media (max-width: 1210px) {
  .last-updated-text {
    display: none;

    &.last-updated-text-mobile {
      display: block;
      margin-top: 16px;
    }
  }
}

@media (max-width: 991px) {
  .ws-lite-overview__tabs {
    top: 0;
  }
}

@media (max-width: 768px) {
  .ws-lite-overview__content {
    padding: 16px 0;
  }
  
  .ws-lite-telegram-cta {
    display: none;

    &.ws-lite-telegram-cta-mobile {
      display: flex;
    }
  }

  .ws-lite-overview__tabs {
    border: none !important;
  }

  .ws-lite-tabs {
    min-height: auto !important;

    .MuiTabScrollButton-horizontal {
      display: none;
    }

    .MuiTab-root {
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      padding: 6px 8px;
      min-height: auto;

      &.Mui-selected {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.08);
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  body.dark .ws-lite-tabs .MuiTabs-flexContainer {
    border-block-end: none !important;
  }
}