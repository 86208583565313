.trade-alerts-signals {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.trade-alerts-signals-title {
  color: #081131;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trade-alerts-signals-description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.trade-alerts-signals-telegram-icon {
  width: 61px;
  height: 61px;
}
.trade-alerts-signals-join-community {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.trade-alerts-signals-signal-text {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.trade-alerts-signals-button {
  background: #007bff;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  display: inline-block;
}

.trade-alerts-signals-button:hover {
  color: #f4f4f5;
}

body.dark {
  .trade-alerts-signals {
    background: #000;
    border: 1px solid #5eeecd;
  }

  .trade-alerts-signals-title {
    color: #fff;
  }

  .trade-alerts-signals-join-community {
    color: #fff;
  }

  .trade-alerts-signals-signal-text {
    color: #a6a6a6;
  }
}
