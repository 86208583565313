.video-popup-content {
  max-width: 900px;
  width: calc(100% - 10px);
  min-height: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 45px 45px 20px 45px;
  user-select: none;
  background: #28292d;
  border-radius: 20px;

  &:focus-visible {
    outline: none;
  }
}

.video-popup-close-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}

.video-popup-close-button svg {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.video-popup-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 25px;
}

.video-popup-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #e4e5e8;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.video-popup-index {
  min-width: 130px;
  text-align: right;
}

.video-popup-progress-bar {
  background: #7a7a7a;
  border-radius: 100px;
  height: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.video-popup-progress-bar-inner {
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  height: 10px;
  border-radius: 100px;
}

.video-popup-ele {
  border-radius: 10px;
  overflow: auto;
  margin-bottom: 30px;
}

.video-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-popup-footer-action-button {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #5eeecd !important;
  text-transform: initial !important;
}

.video-popup-footer-action-button svg {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.visibility-hidden {
  visibility: hidden;
}

body.light {
  .video-popup-content {
    background: #ffffff;
  }

  .video-popup-title {
    color: #000000;
  }

  .video-popup-description {
    color: #7a7a7a;
  }

  .video-popup-close-button svg {
    color: #000000 !important;
    fill: #000000 !important;
  }

  .video-popup-footer-action-button {
    color: #bfbfbf !important;
  }

  .video-popup-footer-action-button svg {
    color: #bfbfbf !important;
    fill: #bfbfbf !important;
  }
}
