/* start fontawesome overrides */
.fa,
.far {
  /* color: #e0dddde3; */
  font-size: 15px;
}

/* start bootstrap overrides */

.popover {
  border-color: #e9ebed !important;
  /* padding: 6px; */
  max-width: 400px;
  font-family: "Poppins", sans-serif !important;
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  border-right-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  border-bottom-color: #e9ebed !important;
}

.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  border-left-color: #e9ebed !important;
}

.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 0 10px #00000026;
}

.dropdown .dropdown-item {
  color: #212529;
}

.dropdown .dropdown-item:hover,
.dropdown .dropdown-item:active {
  background-color: #f5f5f5;
  color: #212529;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #f5f5f5;
  color: #212529;
}
.btn:focus {
  box-shadow: none;
}

.btn.btn-lg {
  padding: 0.4rem 1rem;
}

.search-label {
  width: 100%;
}

.filter-label {
  margin-bottom: 0;
  width: 60%;
}

._loading_overlay_overlay {
  background-color: #0000004d !important;
  /* background-color: rgba(255, 255, 255, 0.8)!important; */
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0062cc !important;
  border-color: #005cbf !important;
}
/* end bootstrap overrides */

/* start bootstrap search overrides */

.custom-b-search-bar input[type="text"],
.custom-b-search-bar .form-control {
  border: none;
  color: #424242;
}

.custom-b-search-bar .dropdown-item.active,
.custom-b-search-bar .dropdown-item:active {
  color: #212529;
  background-color: #f5f5f5;
}

.custom-b-search-bar .close {
  font-weight: 400;
}

.rbt-aux .rbt-close {
  margin-top: 2px;
}

.rbt-highlight-text {
  font-weight: 400;
  color: #007bff;
}

.rbt-menu > .dropdown-item {
  color: #000000d9;
}

/* end bootstrap search overrides */
