.ws-overview {
  font-family: Inter;
  padding: 0 16px;
}

.ws-lite-logo {
  width: 100px;
  height: 39px;
}

.ws-overview__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 32px;
}

.ws-overview__logo {
  height: 36px;
}

.simulated {
  color: rgba(0, 0, 0, 0.60);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 13px;
}

.ws-overview__header-text {
  color: rgba(0, 0, 0, 0.60);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
}

.ws-overview-portfolios {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;
  padding-bottom: 24px;

  &.ws-overview-portfolios--mobile {
    flex-direction: column;

    .ws-overview-portfolio {
      width: 100% !important;
    }
  }
}

.ws-overview-portfolio {
  width: calc((100% - 32px) / 3);
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.ws-overview-portfolio--everest {
    width: calc((100% - 64px) / 5);
  }
}

.ws-overview__tabs {
  position: sticky;
  top: 72px;
  background: #F8FAFC;
  z-index: 1001;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #e4e2e2;

  .ws-overview-tabs--mobile {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.ws-mobile-telegram-cta {
  padding: 6px 0 16px 0;
}

.ws-overview-tabs--mobile {
  .MuiTabScrollButton-horizontal {
    display: none;
  }

  .MuiTabs-flexContainer {
    border-bottom: none;
    border-block-end: none;
  }

  .MuiTab-root {
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.46px;
    color: rgba(0, 0, 0, 0.87) !important;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: auto;
  }

  .MuiTab-root.Mui-selected {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
  }

  .MuiTabs-indicator {
    display: none;
  }
}
.fit-content {
  width: fit-content;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
body.dark {
  .simulated {
    color: white;
  }

  .ws-overview__header-text {
    color: rgba(255, 255, 255, 0.70);
  }

  .ws-overview__tabs {
    background: #3C3C3F;

    .ws-portfolio-ticker-info-tabs .MuiTabs-flexContainer {
      border-block-end: 2px solid rgba(255, 255, 255, 0.12);
    }
  }

  .ws-overview-tabs--mobile {
    .MuiTabScrollButton-horizontal {
      display: none;
    }

    .MuiTabs-flexContainer {
      border-bottom: none !important;
      border-block-end: none !important;
    }

    .MuiTab-root {
      color: rgba(255, 255, 255, 0.8) !important;
    }

    .MuiTab-root.Mui-selected {
      background: rgba(255, 255, 255, 0.08);
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
}

@media (max-width: 1399px) {
  .ws-overview-portfolios {
    flex-wrap: wrap;
    gap: 16px;
  }

  .ws-overview-portfolio {
    &.ws-overview-portfolio--everest {
      width: calc((100% - 32px) / 3);
    }
  }
}

@media (max-width: 1199px) {
  .ws-overview-portfolio {
    width: calc(100% / 2 - 8px);
  }
}

@media (max-width: 991px) {
  .ws-overview__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 6px;
  }

  .ws-overview__tabs {
    top: 0px;
  }
}

@media (max-width: 500px) {
  .ws-overview {
    padding: 0;
  }

  .ws-overview__logo {
    height: 24px;
  }

  .ws-overview__header-text {
    font-size: 12px;
    line-height: 20px;
  }
}

.subscribe-button {
  color: #007BFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  height: 41px;
  border-radius: 6px;
}
.last-updated-text {
  color: rgba(0, 0, 0, 0.60);
  display: flex;
  align-self: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
}
