.past-videos-private-session-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

body.dark {
  .past-videos-private-session-content {
    color: #5eeecd;
    background: initial;
    -webkit-text-fill-color: initial;
  }
}
