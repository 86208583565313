.ws-portfolio-tickers {
  width: 100%;
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.ws-portfolio-tickers__header {
  display: flex;
  padding: 4px 24px 4px 16px;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-tickers__header__cell {
  flex: 1 0 0;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.ws-portfolio-tickers__body {
  display: flex;
  padding-right: 8px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
}
.ws-portfolio-tickers__body__row.active {
  background: rgba(0, 123, 255, 0.08);
}
.ws-portfolio-tickers__body__row {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: rgba(0, 123, 255, 0.08);
  }
}

.ws-portfolio-tickers__body__row__cell {
  flex: 1 0 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-ticker-data {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  cursor: pointer;
}

.ws-portfolio-ticker-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}


body.dark {
  .ws-portfolio-tickers__header__cell {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-tickers__body__row {  
    &:hover {
      background: #009EFF29;
    }
  }

  .ws-portfolio-tickers__body__row__cell {
    color: #FFF;
  }
}