.video-cards-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  gap: 20px;

  @media (min-width: 1200px) {
    max-width: 1020px; // 4 cards per row for large screens
  }
}

.video-card {
  position: relative;
  font-family: "Inter";
  width: calc((100% - 60px) / 4);
  height: 360px;
  border-radius: 20px;
  padding: 18px;
  box-sizing: border-box;
  cursor: pointer;
}

.advanced-bg {
  background-size: cover;
  background-image: url("./assets/Advanced-BG.png");
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: url("./assets/Advanced-BG-Hover.png");
  }
}

.cash-secured-puts-bg {
  background-size: cover;
  background-image: url("./assets/CSP-BG.png");
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: url("./assets/CSP-BG-Hover.png");
  }
}

.leaps-bg {
  background-size: cover;
  background-image: url("./assets/Leaps-BG.png");
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: url("./assets/Leaps-BG-Hover.png");
  }
}

.intermediate-bg {
  background-size: cover;
  background-image: url("./assets/Intermediate-BG.png");
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: url("./assets/Intermediate-Hover.png");
  }
}

.fundamentals-bg {
  background-size: cover;
  background-image: url("./assets/Fundamentals-BG.png");
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: url("./assets/Fundamentals-BG-Hover.png");
  }
}

.content-card-wrap {
  gap: 8px;
}

.course-tab-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.course-title-card {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  line-height: 39px;
}

.course-length {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.view-video {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  bottom: 23px;
  left: 18px;
}

@media (max-width: 1199px) {
  .video-card {
    width: calc((100% - 40px) / 3);
  }
}

@media (max-width: 767px) {
  .video-card {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 480px) {
  .video-card {
    width: 100%;
    background-size: 100% 100%;
  }
}
