.all-price-plans-page {
  padding: 28px 0 0 28px;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.all-price-plans-page__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.all-price-plans-big-label {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.09px;
}

.all-price-plans-small-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011px;
  margin-bottom: 16px;
}

.all-price-plans-plans {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 12px;
}

.all-price-plans-plan {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
  width: calc((100% - 24px) / 3);
  cursor: pointer;
  border: 2px solid #e4e4e4;

  &:hover {
    border: 2px solid rgba(0, 123, 255, 0.5);
  }
}

.all-price-plans-plan__name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.062px;
}

.all-price-plans-plan__description {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.all-price-plans-plan__price {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.062px;
  min-height: 28px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.011px;
    margin-right: 5px;
    text-transform: uppercase;
  }
}

.all-price-plans-plan__purchase-button {
  width: 100% !important;
  border-radius: 6px !important;
  background: #007bff !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
  text-transform: initial !important;
  &.purchased {
    opacity: 0.7;
  }

  &:disabled {
    color: #fff !important;
  }
}

.all-price-plans-premium-plans {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 12px;
}

.all-price-plans-premium-plan {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(50% - 6px);
}

.all-price-plans-premium-plan-content {
  border-radius: 8px;
  border: 2px solid #e4e4e4;
  background: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;

  &:hover {
    border: 2px solid #5eeecd;
  }

  &.platinum-content {
    background-image: url(../../../assets/images/OM-Platinum.png);
    background-size: 100% 100%;
  }

  &.emerald-content {
    background-image: url(../../../assets/images/OM-Emerald.png);
    background-size: 100% 100%;
  }
}

.all-price-plans-premium-plan__name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.062px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-transform: capitalize;
}

.all-price-plans-premium-plan__new-label {
  border-radius: 4px;
  background: #007bff;
  padding: 4px 6px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.006px;
}

.all-price-plans-premium-plan__benefits {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.all-price-plans-premium-plan__benefit {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.all-price-plans-premium-plan__purchase-button {
  width: 200px !important;
  border-radius: 6px !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
  text-transform: initial !important;

  &.platinum-button {
    background: linear-gradient(87deg, #5eeecd 0%, #007bff 100%) !important;
    color: #fff !important;
  }

  &.purchased {
    opacity: 0.7;
  }

  &.emerald-button {
    background: linear-gradient(87deg, #000 0%, #55efc4 100%) !important;
    color: #fff !important;
    z-index: 9;
  }
}

.premium-plan-description {
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  .highlighted-text {
    color: #007bff;
  }
}

body.dark {
  .all-price-plans-big-label {
    color: rgba(255, 255, 255, 0.87);
  }

  .all-price-plans-small-label {
    color: rgba(255, 255, 255, 0.6);
  }

  .all-price-plans-plan {
    background: rgba(0, 0, 0, 0.87);

    &:hover {
      border: 2px solid rgba(0, 123, 255, 0.5);
    }
  }

  .all-price-plans-plan__name {
    color: #fff;
  }

  .all-price-plans-plan__description {
    color: #fff;
  }

  .all-price-plans-plan__price {
    color: #fff;
  }

  .all-price-plans-premium-plan-content {
    background: #1c1c1e;

    &:hover {
      border: 2px solid #5eeecd;
    }
  }

  .all-price-plans-plan__purchase-button {
    background: #5eeecd !important;

    &:disabled {
      color: #000 !important;
    }
  }

  .premium-plan-description {
    color: rgba(255, 255, 255, 0.6);

    .highlighted-text {
      color: #5eeecd;
    }
  }

  .all-price-plans-premium-plan-content {
    &.platinum-content {
      background-image: url(../../../assets/images/OM-Platinum-dark.png);
      background-size: 100% 100%;
    }

    &.emerald-content {
      background-image: url(../../../assets/images/OM-Emerald-dark.png);
      background-size: 100% 100%;
    }
  }

  .all-price-plans-premium-plan__name {
    color: #fff;
  }

  .all-price-plans-premium-plan__benefit {
    color: #fff;
  }
}

@media (max-width: 1200px) {
  .all-price-plans-premium-plans {
    flex-direction: column;
  }

  .all-price-plans-premium-plan {
    width: 100%;
    aspect-ratio: 1 / 0.5;
  }

  .all-price-plans-premium-plan-content {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .all-price-plans-plans {
    flex-direction: column;
  }

  .all-price-plans-plan {
    width: 100%;
  }
}
