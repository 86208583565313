.sendbird-openchannel-user-message__right__bottom__message {
  word-break: break-word !important;
}
.bronco-orange {
  background-color: rgb(255 162 22 / 99%) !important;
  color: black !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.oa-chat {
  display: flex;
  flex-direction: row;
}
.item-1 {
  flex: 0 0 65%;
  .sendbird-openchannel-conversation {
    max-height: 831px;
    min-height: 790px;
  }
}
.item-2 {
  flex: 1; /* flex-grow */
}
.trade-dropdown {
  position: relative;
  top: -5px;
}

@keyframes blinkingBackgroundYellow {
  0% {
    background-color: #ffee8e;
  }
}

@keyframes blinkingBackgroundGreen {
  0% {
    background-color: #8bff9194;
  }
}

.first-chat-wrapper,
.second-chat-wrapper {
  overflow-x: auto; // This will add scroll if content overflows
}

.first-chat-wrapper {
  flex: 1 1 67%; // Adjust this value
  height: 100%;
}

.second-chat-wrapper {
  flex: 1 1 33%; // Adjust this value
  display: flex;
  flex-direction: column;
}

.top-block-wrapper {
  display: flex;
  gap: 10px;
}

@media (max-width: 1520px) {
  .first-chat-wrapper {
    flex: 1 1 55%; // Adjust this value
  }

  .second-chat-wrapper {
    flex: 1 1 45%; // Adjust this value
  }
}

@media (max-width: 990px) {
  .top-block-wrapper {
    flex-direction: column;
  }

  .first-chat-wrapper,
  .second-chat-wrapper {
    flex: 1;
    width: 100%;
  }
}

.trade-closed {
  background-color: #bfbfbf !important;
}

.trade-open {
  background-color: #3eb489 !important;
}

.trade-running {
  background-color: #ffab00 !important;
}

.trade-text-running {
  color: #000 !important;
  font-weight: 900;
}

.trade-time-running {
  color: #000000a4 !important;
  font-weight: 900;
}

body.dark {
  .analyst-rules {
    color: white !important;
  }

  .bullets-rule {
    color: white !important;
  }

  .rules-section {
    background: #161616 !important;
  }

  .chat-description {
    background: #161616 !important;
  }

  .openchannel-description {
    background: #161616 !important;
  }

  .guide-title {
    color: white !important;
  }

  .openchannel-app-trades-guide {
    background: #161616 !important;
  }

  .trade-name {
    color: white !important;
  }

  .description-blocks {
    .title {
      color: white !important;
    }

    .inner-title {
      color: white !important;
    }

    background: #161616 !important;

    .inner-description {
      color: #b9b9b9 !important;
    }
  }

  .sendbird-openchannel-app {
    .sendbird-openchannel-conversation-header__right {
      .MuiSwitch-track {
        background-color: #aab4be !important;
      }

      .MuiSwitch-switchBase.Mui-checked {
        color: #5EEECD !important;
      }
    }
  }
}

.rules-section {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 27px;
  height: fit-content !important;
  border-radius: 10px;
  margin-bottom: 0 !important;

  .top-notes {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;

    .wh-27 {
      width: 27px;
      height: 27px;
    }

    .analyst-rules {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    .desc-style {
      list-style-type: disc !important;

      .bullets-rule {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #7a7a7a;
      }
    }
  }
}

.default-chat-name {
  font-weight: 900;
  color: rgba(0, 0, 0, 0.5);
  padding: 1.2px;
  padding-right: 5px;
  font-size: 12.5px;
  border-radius: 9px;
}

body {
  &.dark {
    .default-chat-name {
      font-weight: 900;
      color: hsla(0, 0%, 100%, 0.5);
      padding: 1.2px;
      padding-right: 5px;
      font-size: 12.5px;
      border-radius: 9px;
    }
  }
}

.brian {
  font-weight: 900;
  color: black;
  padding: 1.2px;
  background: rgb(99 255 128 / 99%);
  padding-right: 5px;
  font-size: 12.5px;
  border-radius: 9px;
  padding-left: 4px;
}

.ross {
  font-weight: 900;
  color: white;
  padding: 1.2px;
  background: rgb(156 17 255 / 91%);
  padding-right: 5px;
  font-size: 12.5px;
  border-radius: 9px;
  padding-left: 4px;
}

.fullscreen-chat-ui {
  height: 95vh !important;
  width: 95vw !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}
.fullscreen-widget-ui {
  height: 89vh !important;
  width: 95vw !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}

.bottom-section {
  padding-top: 9px;
}

.sendbird-openchannel-app {
  position: relative;
  height: auto;
  display: flex;
  padding-bottom: 45px;
  float: left;

  .sendbird-openchannel-app__channel {
    position: relative;
    width: 100%;
    max-height: 866px;
    height: auto;

    .sendbird-openchannel-conversation-header__right {
      width: inherit;
    }
  }

  .sendbird-openchannel-app__settings {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }

  .sendbird-icon-info {
    display: none;
  }
}

.openchannel-app-trades-guide {
  position: relative;
  height: 92px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  float: right;
  background: white;
  padding: 12px;

  .guide-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    margin-bottom: 5px;
  }

  .trade-colors {
    display: flex;
    justify-content: flex-start;
    gap: 28px;
    flex-direction: row;
  }

  .color-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .green-bar {
    width: 60px;
    height: 7px;
    background: #3eb489;
  }

  .green-trade {
    color: #3eb489;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .yellow-bar {
    width: 60px;
    height: 7px;
    background: #ffab00;
  }

  .yellow-trade {
    color: #ffab00;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .red-bar {
    width: 60px;
    height: 7px;
    background: #bfbfbf;
  }

  .red-trade {
    color: #bfbfbf;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}

.sendbird-openchannel-app-trades {
  position: relative;
  min-height: 764px;
  max-height: 772px;
  width: 100%;
  display: flex;
  float: right;
  background: white;
  margin-bottom: 12px;

  .sendbird-openchannel-conversation-scroll {
    overflow-x: hidden;
  }

  .sendbird-openchannel-app__channel {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sendbird-openchannel-app__settings {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }

  .sendbird-icon-info {
    display: none;
  }
}

.sendbird-place-holder {
  .sendbird-place-holder__body {
    height: auto;

    .sendbird-place-holder__body__icon {
      margin: 10px 0 0 0;

      .icon-chat_svg__fill {
        animation: loading 1.5s linear infinite;
      }

      &-timestamp {
        font-family:
          Helvetica Neue,
          sans-serif;
        font-size: 11px;
        line-height: 141%;
        color: white;
        margin-left: 6px;
      }
    }

    .str-chat__li:hover .str-chat__message-text-inner {
      background: rgb(0 0 0 / 97%);
    }

    .str-chat__message-simple-text-inner p {
      padding-top: 4px !important;
      color: white !important;
    }

    .str-chat__li:hover .str-chat__message-simple__actions {
      background: #000000;
    }

    .str-chat__list {
      background: #00000026;
    }

    .str-chat__modal__inner {
      background: black;
    }

    .str-chat.dark .str-chat__edit-message-form textarea {
      background: #00000026 !important;
      border: 1px solid blue;
    }

    .str-chat__header-livestream {
      padding: var(--xs-p) var(--xl-p);
      min-height: 70px;
      display: flex;
      align-items: center;
      font-family: var(--second-font);
      border-radius: 3px;
      background: #000000;
      box-shadow:
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        0px 4px 5px 0px hsla(0, 0%, 0%, 0.14),
        0px 1px 10px 0px hsla(0, 0%, 0%, 0.12),
        0px 2px 4px -1px hsla(0, 0%, 0%, 0.2);
    }
  }
}

.sendbird-loader {
  display: none;
}

.sendbird-openchannel-app-notifications {
  .sendbird-openchannel-conversation-header__left__title {
    position: absolute;
    left: 23px;
    top: 12px;
  }

  .sendbird-openchannel-conversation-header__left__sub-title {
    // position: absolute;
    // left: 26px;
    // top: 40px;
    display: none;
  }

  .sendbird-openchannel-conversation-header__left__cover-image {
    display: none;
  }

  .sendbird-openchannel-conversation-header {
    border-bottom: none;
  }

  .sendbird-frozen-channel-notification {
    display: none;
  }

  .sendbird-openchannel-conversation-header__right {
    display: none;
  }

  .sendbird-openchannel-footer {
    display: none;
  }

  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  border-radius: 12px;

  .sendbird-openchannel-app__channel {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sendbird-openchannel-app__settings {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
  }
}

body {
  .sendbird-dropdown__menu-item:hover {
    background-color: #330057 !important;
  }

  .sendbird-dropdown__menu {
    background-color: #742ddd !important;
  }

  .sendbird-dropdown__menu-item__text {
    color: white !important;
  }

  .sendbird-modal__content {
    background-color: #742ddd !important;
  }

  .sendbird-modal__header {
    color: white !important;
  }

  .important-stuff {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    /* Primary Blue */
    color: #007bff;
  }

  .important-stuff-red {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    /* Primary Blue */
    color: #ff0000b9;
  }

  .important-stuff-green {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #0aff16;
  }

  .important-stuff-yellow {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #f8d302f6;
  }

  .anal-notes {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  .full-notes {
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #007bff;
  }

  .bullets {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Gray */

    color: #7a7a7a;
  }

  &.dark {
    .brian-iframe {
      background: #161616 !important;
    }

    .sendbird-button__text {
      color: black !important;
    }

    .sendbird-button {
      background: white !important;
    }
  }

  &.light {
    sendbird-label {
      color: white !important;
    }

    .sendbird-button__text {
      color: white !important;
    }

    .sendbird-button {
      background: #330057 !important;
    }
  }

  .sendbird-openchannel-user-message__right__bottom {
    -webkit-user-select: all;
    /* Safari */
    -ms-user-select: all;
    /* IE 10 and IE 11 */
    user-select: all;
    /* Standard syntax */
  }

  .trade-name {
    color: #000000 !important;
    font-weight: 900 !important;
  }
}

.brian-iframe {
  height: 821px;
  background: white;
  width: 100%;
  padding: 20px;
}

.sendbird-openchannel-app {
  /* Existing styles... */

  .openchannel-description {
    display: block;
    background: #ffffff;
    /* Change this to the color you want */
    padding: 10px;
    padding-left: 15px;
    margin-top: 4px;
    border-radius: 5px;
    font-size: 14px;
    color: #000000;
    /* Change this to the color you want for the text */
  }
}

.chat-container {
  display: flex;
  flex-wrap: wrap;
}

.chat-section {
  flex: 1;
  margin: 10px;
}

.chat-section:first-child {
  flex: 2;
}

@media (min-width: 768px) {
  .chat-section {
    margin: 20px;
  }

  .chat-section:first-child {
    flex: 2;
  }
}

.description-block-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.w-470 {
  width: 470px !important;
  max-width: 470px !important;
}

.mr-20 {
  margin-right: 20px;
}

.description-blocks {
  // width: calc(50% - 10px);
  width: 100%;
  margin-top: 15px;
  padding: 16px 26px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 10px;

  .bell {
    width: 61px;
    height: 61px;
  }

  .image-title-description {
    display: flex;
    justify-content: flex-start;
  }

  .title-description {
    display: flex;
    flex-direction: column;
    margin: 1px;
    padding-left: 10px;
  }

  .inner-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Black */

    color: #000000;
  }

  .inner-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    /* Gray */

    color: #7a7a7a;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #081131;
  }
}

.chat-main {
  order: 2;
}

.chat-description {
  width: 100%;
  background: #ffffff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  overflow: auto;
  order: 3;
}

.chat-open-trades {
  order: 1;
}

.chat-trade-board {
  order: 4;
}

.pl-15 {
  padding-left: 15px;
}

.first-chat-wrapper {
  .sendbird-openchannel-conversation-scroll__container__item-container {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .sendbird-openchannel-conversation-scroll__container__item-container::-webkit-scrollbar {
    display: block !important;
  }
}

.visually-hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.no-max-height {
  max-height: none !important;
}

@media (max-width: 1400px) {
  .top-block-wrapper {
    flex-direction: column;
  }

  .sendbird-openchannel-app-trades {
    min-height: 400px;
    max-height: 500px;
  }
}

@media (max-width: 768px) {
  .description-block-wrapper {
    flex-direction: column;
  }

  .description-blocks {
    width: 100%;
  }
}
