.mentorship-paywell-confirm-page {
  background: url(../../assets/images/paywell-confirm-1.png);
  background-size: cover;
  padding: 30px 60px;
  min-height: calc(100vh - 95px);
}

.mentorship-paywell-confirm-page-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 10px;
  background: linear-gradient(101.16deg, #3183ff -7.41%, #0751bf 110.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mentorship-paywell-confirm-page-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #7a7a7a;
  margin-bottom: 40px;
}

.mentorship-paywell-confirm-page-options-basic-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  /* Background Blue */
  background: linear-gradient(180deg, #007bff -13.33%, #000000 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mentorship-paywell-confirm-page-options-basic-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #7a7a7a;
}

.mb-23 {
  margin-bottom: 23px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mentorship-paywell-confirm-page-options-plat-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* Gradient */
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mentorship-paywell-confirm-page-options-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 30px;
  background: linear-gradient(101.16deg, #3183ff -7.41%, #0751bf 110.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mentorship-paywell-confirm-page-option-row {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 53px;
  overflow-y: auto;
  // flex-wrap: wrap;
  // width: 100%;
  // max-width: 860px;
}

.blue-txt {
  /* Gradient Blue */
  background: linear-gradient(101.16deg, #3183ff -7.41%, #0751bf 110.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

body.dark {
  .mentorship-paywell-confirm-page-options-basic-title {
    background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .mentorship-paywell-confirm-page-title {
    color: #ffffff;
    background: none;
    -webkit-text-fill-color: initial;
  }

  .mentorship-paywell-confirm-page-subtitle {
    color: #e4e5e8;
  }

  .mentorship-paywell-confirm-page-options-title {
    color: #e4e5e8;
    background: none;
    -webkit-text-fill-color: initial;
  }

  .mentorship-paywell-confirm-page {
    background: url(../../assets/images/diamond-bg.png);
    background-size: cover;
  }
}

@media (max-width: 991px) {
  .mentorship-paywell-confirm-page-title {
    text-align: center;
  }

  .mentorship-paywell-confirm-page-subtitle {
    text-align: center;
  }

  .mentorship-paywell-confirm-page-options-title {
    text-align: center;
  }

  .mentorship-paywell-confirm-page-option-row {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .mentorship-paywell-confirm-page {
    padding: 30px;
  }

  .mentorship-paywell-confirm-page-title {
    font-size: 40px;
    line-height: 48px;
  }

  .mentorship-paywell-confirm-page-subtitle {
    font-size: 15px;
    line-height: 19px;
  }

  .mentorship-paywell-confirm-page-options-title {
    font-size: 28px;
    line-height: 34px;
  }
}
