.security-page {
  .up {
    color: #0dad60;
  }

  .down {
    color: #dc3545;
  }

  .card-body {
    padding-top: 0px !important;
    overflow: hidden;
    height: 750px;
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.tab-header-wrapper {
  width: 100%;
  margin-bottom: 26px;
  padding: 0 15px;
}

.tab-header-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
}

.company-info {
  width: 45%;
  border-radius: 10px;
  padding: 28px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

.flex {
  display: flex;
}

.pad {
  padding: 1rem;
}

.company-info-light {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(228, 229, 232, 0.25);
}

.company-info-dark {
  background: rgba(21, 23, 23, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(70, 73, 73, 0.25);
}
.performance-summary-content-item {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: space-between;
  gap: 4px;
}

.company-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.company-symbol {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.company-symbol-light {
  color: #000;
}

.company-symbol-dark {
  color: #fff;
}

.market-status {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 2px;
}

.company-name {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
}

.company-price-info {
  margin-top: 5px;
}

.company-price-item {
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
  align-items: flex-end;
  justify-content: flex-start;
}

.company-price-item p {
  margin-bottom: 0;
}

.company-price-usd-symbol {
  margin-left: 4px;
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  top: -5px;
}

.company-price-change {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.company-price-percentage-change {
  border-radius: 22px;
  padding: 4px 10px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.company-price-change-amount {
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.company-info-chart img {
  width: 100%;
}

.performance-summary {
  width: 55%;
  border-radius: 10px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
}

.performance-summary-light {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(228, 229, 232, 0.25);
}

.performance-summary-dark {
  background: rgba(21, 23, 23, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(70, 73, 73, 0.25);
}

.performance-summary-header-title {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.performance-summary-header-title-light {
  color: #000;
}

.performance-summary-header-title-dark {
  color: #fff;
}

.performance-summary-header-date {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
}

.performance-summary-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.performance-summary-content-item {
  padding: 0 14px;
  border-right: 1px solid #e4e5e8;
}

.performance-summary-content-item:first-child {
  padding-left: 0;
}

.performance-summary-content-item:last-child {
  border-right: none;
}

.performance-summary-content-item-title {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.performance-summary-content-item-value {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.performance-summary-content-item-value.negative-color {
  color: #ea3323;
}

.performance-summary-content-item-value.positive-color {
  color: #3eb489;
}

.stock-wrapper {
  width: calc((100% / 3) * 2);
  padding: 0 15px;
  margin-bottom: 15px;
}

.fun-joyride-target {
  width: calc((100% / 3) * 1);
  padding: 0 15px;
  margin-bottom: 15px;
}

@media (max-width: 1400px) {
  .tab-header-container {
    flex-direction: column;
  }

  .company-info {
    width: 100%;
  }

  .performance-summary {
    width: 100%;
  }

  .stock-wrapper {
    width: 100%;
  }

  .fun-joyride-target {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .company-info {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .company-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .company-info-chart {
    width: 100%;
    overflow: visible;
    padding: 5px;
  }

  .performance-summary-content {
    flex-wrap: wrap;
    gap: 20px;
  }

  .performance-summary-content-item {
    width: calc(50% - 10px);
    border: none;
    padding: 0;
  }
}

@media (max-width: 473px) {
  .performance-summary-header {
    width: 100%;
    text-align: center;
  }

  .performance-summary-content-item {
    width: 100%;
    text-align: center;
  }
}
