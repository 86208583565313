.bid-ask-base {
  width: 19px;
  height: 17px;
  padding-left: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.BB {
  color: #2563eb;
}

.AA {
  color: #0d9488;
}

.LA {
  color: #db2777;
}

.LB {
  color: #ea580c;
}

.A {
  color: rgb(255, 149, 0);
}

.AB {
  color: rgb(0, 122, 255);
}

.BBi {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.total-price {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #0f172a !important; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 7px;
}

.avg-price {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #0f172a !important; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 5px;
}

.unusual-time {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  /* color: #475569; */
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  margin-left: 8px;
}

.table-headers {
  font-size: 15px;
  color: white;
  font-weight: 600;
}

.table-headers2 {
  font-size: 15px;
  color: white;
  font-weight: 600;
  padding-left: 32px;
}

.large-table {
  position: relative;
  left: 35px;
}

.spot {
  position: relative;
  left: 35px;
}

.strike {
  position: relative;
  left: 8px;
}

.cp {
  position: relative;
  left: 6px;
  font-weight: 600;
}

.red-put {
  color: red;
}

.green-call {
  color: #0dad60;
}

.ppc {
  position: relative;
  left: 9px;
  color: #4c4663;
}

.type-cell {
  font-weight: 700;
  position: relative;
  left: 6px;
}

.prem-cell {
  color: #0dad60;
  position: relative;
  left: 8px;
  font-weight: 500;
  font-size: 15px;
}

.data-grid-wrap {
  width: 100%;
  height: fit-content;
}

.bottom-height-table {
  height: 4px;
}

.ticker {
  position: relative;
  left: 9px;
  font-weight: 600;
  /* color: black !important; */
}

body.dark .table-headers {
  color: black;
}

body.dark .table-headers2 {
  color: black;
}
