.fallback-span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */
  text-align: center;
  letter-spacing: -0.02em;
  color: #a2a3a5;
}
.oops {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a2a3a5;
}

.fallback-div {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
}
