.om-overview {
  width: 100%;
}

.om-overview__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.om-overview-big-area {
  width: calc(100% - 300px);
}

.om-overview-small-area {
  width: 300px;
  flex: 0 0 auto;
}

.om-overview-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}

.om-overview-header__title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.om-overview-header-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.om-overview-header-item {
  display: flex;
  align-items: inherit;
  justify-content: flex-start;
  gap: 4px;
}

.om-overview-header-item__value {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.077px;
}

.om-overview-header-item__title {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.om-overview__levels {
  width: 100%;
  display: flex;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 52px;
}

.om-overview__level {
  width: 100%;
  border: none;
  background: transparent;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-right: none;
  }

  &.om-overview__level--selected {
    background: rgba(0, 123, 255, 0.08);
  }
}

.om-overview__content {
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.om-overview__content-banner {
  padding: 24px 20px;
  background-size: 100% 100%;
  width: 100%;
  position: relative;

  &.starter-banner {
    background-image: url(../../../assets/images/OM/starter.png);
  }

  &.intermediate-banner {
    background-image: url(../../../assets/images/OM/intermediate.png);
  }

  &.advanced-banner {
    background-image: url(../../../assets/images/OM/advanced.png);
  }

  &.platinum-banner {
    background-image: url(../../../assets/images/OM/platinum.png);
  }

  &.emerald-banner {
    background-image: url(../../../assets/images/OM/emerald.png);
  }
}

.om-overview__content-banner-label {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.077px;
}

.om-overview__content-banner-title {
  color: #fff;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.137px;
  margin-top: 5px;
}

.om-overview__content-banner-mentors {
  position: absolute;
  width: 300px;
  top: 24px;
  right: 20px;
  display: none;
}

.om-overview__content-description {
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.om-overview__content-channel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  span {
    color: #1c1c1e;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    letter-spacing: 0.15px;
  }

  a {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 6px 16px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
}

.om-overview__mentorship-purchase {
  border-radius: 4px !important;
  background: #007bff !important;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  padding: 8px 22px !important;
  color: #fff !important;
  font-feature-settings:
    "liga" off,
    "clig" off !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
}

.om-overview__mentors {
  width: 100%;
}

.om-overview__mentors-title {
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.om-overview__content {
  .calendly-inline-widget {
    width: 100%;
    top: 0 !important;
    min-height: 600px !important;
    max-height: 800px !important;
  }
}

.om-dark-calendar iframe {
  filter: invert(1) hue-rotate(180deg);
}

body.dark {
  .om-overview-header__title {
    color: rgba(255, 255, 255, 0.87);
  }

  .om-overview-header-item__value {
    color: rgba(255, 255, 255, 0.87);
  }

  .om-overview-header-item__title {
    color: rgba(255, 255, 255, 0.87);
  }

  .om-overview__levels {
    border: 1px solid #5eeecd;
  }

  .om-overview__level {
    color: rgba(255, 255, 255, 0.87);
  }

  .om-overview__level--selected {
    background: #5eeecd;
    color: #000;
  }

  .om-overview__content {
    border: 1px solid #5eeecd;
    background: #1c1c1e;
  }

  .om-overview__content-description {
    color: rgba(255, 255, 255, 0.87);
  }

  .om-overview__content-channel span {
    color: #fff;
  }

  .om-overview__content-channel a {
    border: 1px solid #fff;
    color: #fff;
  }

  .om-overview__mentorship-purchase {
    background: #5eeecd !important;
    color: #000 !important;
  }

  .om-overview__mentors-title {
    color: rgba(255, 255, 255, 0.87);
  }
}

@media (max-width: 1300px) {
  .om-overview-small-area {
    display: none;
  }

  .om-overview-big-area {
    width: 100%;
  }

  .om-overview__content-banner-mentors {
    display: block;
  }
}

@media (max-width: 767px) {
  .om-overview__content-banner-mentors {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    margin-top: 20px;
  }

  .om-overview__content-channel a {
    padding: 6px;
    font-size: 12px;
    line-height: 18px;
  }

  .om-overview__mentorship-purchase {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .om-overview-header-items {
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .om-overview__levels {
    margin-bottom: 30px;
  }

  .om-overview__level {
    padding: 4px;
  }
  
  .om-overview__content-channel {
    flex-direction: column;

    span {
      font-size: 14px;
      text-align: center;
    }
  }
}
