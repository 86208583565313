.fallback-ticker-empty-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #BDBDBD;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

body.dark {
  .fallback-ticker-empty-icon {
    background: #757575;
    color: #121212;
  } 
}