.ws-portfolio-ticker-info__header {
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
}

.ws-portfolio-ticker-general-info {
  display: flex;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  padding: 16px 16px 0px 16px;
}

@media (min-width: 1400px) {
  .ws-portfolio-ticker-general-info {
    max-height: 48px;
    min-height: 48px;
  }
}

.ws-portfolio-ticker-name {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.ws-portfolio-ticker-company {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.60);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ws-portfolio-ticker-price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.ws-portfolio-ticker-price__value {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ws-portfolio-ticker-current_price {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-ticker-usd {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-ticker-price__change {
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.ws-portfolio-ticker-price__change-value {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-chip {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  border-radius: 100px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;

  &.positive {
    background: #C8E6C9;
    color: #1B5E20;
  }

  &.negative {
    background: #FECDD2;
    color: #C62828;
  }
}

.ws-portfolio-ticker-info__content {
  background: #fff;
  padding-bottom: 55px;
  padding-top: 16px;
}

.ws-portfolio-ticker-performances {
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-ticker-performance {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.ws-portfolio-ticker-performance__title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.ws-portfolio-ticker-performance__value {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-divider {
  background: rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 1px;
  margin: 16px 0;
}

#ws-portfolio-ticker-financials {
  height: 750px;
}

#ws-portfolio-ticker-about {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: #FFF;
}

.ws-portfolio-ticker-about__title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.ws-portfolio-ticker-about__description {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-portfolio-ticker-about__infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.ws-portfolio-ticker-about__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ws-portfolio-performance-tabs {
  .MuiTab-root {
    color: black;

    &.Mui-selected {
      color: black
    }
  }

  .MuiTabs-indicator {
    background-color: #b8b8b873;
  }
}


.ws-portfolio-ticker-about__info-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-portfolio-ticker-about__info-value {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.ws-portfolio-ticker-info-tabs {
  .MuiTab-root {
    text-transform: capitalize;

    &.Mui-selected {
      color: black;
    }
  }
}

body.dark {
  .ws-portfolio-ticker-info__header {
    background: #2C2C2C;
  }

  .ws-portfolio-ticker-name {
    color: #fff;
  }

  .ws-portfolio-ticker-company {
    color: rgba(255, 255, 255, 0.7);
  }

  .ws-portfolio-ticker-current_price {
    color: #fff;
  }

  .ws-portfolio-ticker-usd {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-chip {
    &.positive {
      background: #E8F5E9;
      color: #388E3C;
    }

    &.negative {
      color: #D32F2F;
      background: #FEEBEE;
    }
  }

  .ws-portfolio-ticker-info-tabs {
    .MuiTabs-flexContainer {
      border-block-end: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  .ws-portfolio-performance-tabs {
    .ws-portfolio-ticker-info-tabs {
      .Mui-selected {
        color: rgb(243, 243, 243) !important;
      }
    }
  }

  .ws-portfolio-ticker-info-tabs {
    .MuiTabs-flexContainer {
      border-block-end: 1px solid rgba(255, 255, 255, 0.12) !important;
    }

    .MuiTab-root {
      color: #FFFFFFB2;

      &.Mui-selected {
        color: #FFF;
      }
    }
  }

  .ws-portfolio-ticker-info__content {
    background: #2C2C2C;
  }

  .ws-portfolio-divider {
    background: rgba(255, 255, 255, 0.12);
  }

  .ws-portfolio-ticker-performance__title {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-ticker-performance__value {
    color: rgba(255, 255, 255, 1);
  }

  #ws-portfolio-ticker-financials {
    height: auto;
  }

  #ws-portfolio-ticker-about {
    background: #2C2C2C;
  }

  .ws-portfolio-ticker-about__title {
    color: #fff;
  }

  .ws-portfolio-ticker-about__description {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-ticker-about__info-title {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-ticker-about__info-value {
    color: rgba(255, 255, 255, 1);

    a {
      color: #009EFF;
    }
  }
}

@media (max-width: 1399px) {
  .ws-portfolio-ticker-performances {
    flex-wrap: wrap;
  }

  .ws-portfolio-ticker-performance {
    width: calc(50% - 8px);
    flex: 0 0 auto;
  }
}

@media (max-width: 1199px) {
  .ws-portfolio-ticker-general-info {
    flex-direction: column;
    min-height: 80px;
    max-height: 80px;
  }
}