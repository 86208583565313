.options-academy-sign-up-result-icon-wrapper {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.MuiCircularProgress-svg {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.titles-wrapper {
  padding-left: 49px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: relative;
}

.ta-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
}

.oa-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.new-title {
  font-size: 36px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.oa-inner-subtitle {
  padding-top: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  max-width: 931px;
  color: #7a7a7a;
}

.courses-title-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.course-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.course-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #007bff;
  padding-top: 2px;
}

.options-academy-signup-wrapper {
  background: url("../../assets/images/optionsAcademy/bg-option-academy.png");
  height: 91vh;
  background-size: cover;
  overflow: hidden;

  .oa-subtitle {
    padding-top: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    max-width: 949px;
    color: #7a7a7a;
  }

  .price-wrapper {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 20px;
    align-items: baseline;

    .price {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: #000000;
    }

    .ppm {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #a6a6a6;
    }
  }

  .intro-to-options {
    width: 311px;
    position: absolute;
    bottom: 0px;
    right: 0;
  }

  .get-it-now {
    position: relative;
    margin-top: 26px;
    width: 240.6px;
    height: 45px;
    background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
    border-radius: 5px;
    color: white;
    text-transform: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
  }

  .get-it-now::after {
    content: "";
    z-index: 4;
    position: absolute;
    left: 0rem;
    bottom: -1rem;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%);
    opacity: 0.4;
    filter: blur(10px);
    border-radius: 8px;
  }

  .advanced-training {
    width: 416px;
    position: absolute;
    bottom: 0px;
    right: 264px;
  }

  @media (max-width: 992px) {
    .oa-title {
      font-size: 66px !important;
    }

    .advanced-training {
      display: none;
    }

    .intro-to-options {
      display: none;
    }
  }
}

.video-page-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.video-section {
  width: 60%;
}

.playlist-section {
  width: 40%;
  padding: 5px;
}

/* Add this media query */
@media screen and (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .video-section,
  .playlist-section {
    width: 100%;
  }
}

.course-event-video {
  height: 41vh;
  width: 100%;
  padding-right: 15px;
}

.video-course-title {
  color: #000;
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
}

.course-tabs-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: baseline;
}

.course-tabs {
  color: #7a7a7a;
  font-size: 16px;
  font-family: Inter;
  cursor: pointer;
}

.active-course-tab {
  cursor: pointer;
  color: #000;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
}

.seperator {
  width: 98%;
  margin-top: 10px;
  height: 1px;
  background: #a6a6a6;
  margin-bottom: 10px;
}

.video-description-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: #86868b;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
}

.playlist-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.current-playlist {
  color: #000;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
}

.overview-section {
  color: #7a7a7a;
  font-size: 16px;
  font-family: Inter;
}

.tut-descript {
  color: #0d0e0f;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
}

.course-ul {
  list-style-type: disc;
}

.course-ul li:first-of-type {
  margin-top: 10px;
}

.course-li {
  margin-left: 20px;
}

.video-block {
  cursor: pointer;
  height: 188px;
  width: 100%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
}

.video-preview {
  position: relative;
  /* background-color: black; */
  width: 63%;
  height: 100%;
  border-radius: 10px;
}

.video-text-container {
  padding-left: 15px;
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.video-text-title {
  color: #000;
  font-size: 20px;
  font-family: Inter;
  font-weight: 600;
}

.video-text-duration {
  color: #86868b;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
}

.now-playing {
  position: absolute;
  height: 27px;
  bottom: 0px;
  background: rgba(122, 122, 122, 0.5);
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.live-chat-video-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 637px;
}

.livestream-wrap {
  flex: 2;
  height: 100%;

  .live-stream-event-video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.chat-wrap {
  flex: 1;
  height: 100%;
}

.livestream-chat {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .live-chat-video-wrapper {
    flex-direction: column;
    height: 937px;
  }
}

.support-livestream-container {
  padding: 20px;
}

.card-bg {
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: start;
  align-items: start !important;
  gap: 15px;
  flex: 1.9;
  margin-right: 14px;
}

.bottom-flex-container {
  display: flex;
  flex-direction: row;
  align-items: start !important;
  flex-wrap: wrap;
}

.trade-alerts-container {
  flex: 1;
  height: 100%;
}

.brian-profile {
  position: relative;
  bottom: 8px;
}

#intro {
  background-image: url("./assets/Fundamentals/1.png");
  background-size: cover;
}

#optionStrat {
  background-image: url("./assets/Fundamentals/2.png");
  background-size: cover;
}

#optionPricing {
  background-image: url("./assets/Fundamentals/3.png");
  background-size: cover;
}

#optionRisks {
  background-image: url("./assets/Fundamentals/4.png");
  background-size: cover;
}

#techAnal {
  background-image: url("./assets/Fundamentals/5.png");
  background-size: cover;
}

#funAnal {
  background-image: url("./assets/Fundamentals/6.png");
  background-size: cover;
}

#tradePlat {
  background-image: url("./assets/Fundamentals/7.png");
  background-size: cover;
}

#tradePsych {
  background-image: url("./assets/Fundamentals/8.png");
  background-size: cover;
}

#tradingPlan {
  background-image: url("./assets/Fundamentals/9.png");
  background-size: cover;
}

#putTogether {
  background-image: url("./assets/Fundamentals/10.png");
  background-size: cover;
}

#order-entry {
  background-image: url("./assets/Intermediate/1.png");
  background-size: cover;
}

#order-entry-for-options {
  background-image: url("./assets/Intermediate/1.png");
  background-size: cover;
}

#options-greek {
  background-image: url("./assets/Intermediate/2.png");
  background-size: cover;
}

#merger-and-acquisitions {
  background-image: url("./assets/Intermediate/3.png");
  background-size: cover;
}

#economic-events {
  background-image: url("./assets/Intermediate/4.png");
  background-size: cover;
}

#volatility-trading {
  background-image: url("./assets/Intermediate/5.png");
  background-size: cover;
}

#trading-psychology {
  background-image: url("./assets/Intermediate/6.png");
  background-size: cover;
}

#portfolio-management {
  background-image: url("./assets/Intermediate/7.png");
  background-size: cover;
}

#option-tweaks {
  background-image: url("./assets/Intermediate/8.png");
  background-size: cover;
}

#synthetics {
  background-image: url("./assets/Intermediate/9.png");
  background-size: cover;
}

#advanced-trading-tactics {
  background-image: url("./assets/Intermediate/10.png");
  background-size: cover;
}

#intro-leaps {
  background-image: url("./assets/LEAPS/1.png");
  background-size: cover;
}

#etp-leaps {
  background-image: url("./assets/LEAPS/2.png");
  background-size: cover;
}

#etp-leaps-plus {
  background-image: url("./assets/LEAPS/3.png");
  background-size: cover;
}

#best-market-for-leaps {
  background-image: url("./assets/LEAPS/4.png");
  background-size: cover;
}

#cash-secured-puts {
  background-image: url("./assets/CSP/1.png");
  background-size: cover;
}

#benefits-of-cash-secured-puts {
  background-image: url("./assets/CSP/2.png");
  background-size: cover;
}

#risks-and-safety-measures {
  background-image: url("./assets/CSP/3.png");
  background-size: cover;
}

#enhanced-cash-secured-puts {
  background-image: url("./assets/CSP/4.png");
  background-size: cover;
}

#tools-for-trading-cash-secured-puts {
  background-image: url("./assets/CSP/5.png");
  background-size: cover;
}

#applied-options-moneyness {
  background-image: url("./assets/Advanced/1.png");
  background-size: cover;
}

#the-vixs-ta {
  background-image: url("./assets/Advanced/2.png");
  background-size: cover;
}

#advanced-trading-strategies-comparisons {
  background-image: url("./assets/Advanced/3.png");
  background-size: cover;
}

#sector-options-analysis-ta {
  background-image: url("./assets/Advanced/4.png");
  background-size: cover;
}

#heuristics-and-options-trading {
  background-image: url("./assets/Advanced/5.png");
  background-size: cover;
}

#building-a-trading-system {
  background-image: url("./assets/Advanced/6.png");
  background-size: cover;
}

#fair-price-for-an-option {
  background-image: url("./assets/Advanced/7.png");
  background-size: cover;
}

#trading-journals-and-performance-metrics {
  background-image: url("./assets/Advanced/8.png");
  background-size: cover;
}

#advanced-options-indicators {
  background-image: url("./assets/Advanced/9.png");
  background-size: cover;
}

#advanced-options-scanning {
  background-image: url("./assets/Advanced/10.png");
  background-size: cover;
}

body {
  &.dark {
    .MuiOutlinedInput-root {
      color: white;
    }

    .video-text-duration {
      color: #dcdce7;
    }

    .video-text-title {
      color: white;
    }

    .video-block {
      background: #161616 !important;
    }

    .current-playlist {
      color: white;
    }

    .overview-section {
      color: white;
    }

    .tut-descript {
      color: white;
    }

    .video-description-wrapper {
      color: #dcdce7;
    }

    .course-tabs {
      color: #fbfbfbf0;
    }

    .active-course-tab {
      color: white !important;
    }

    .video-course-title {
      color: white;
    }

    .card-bg {
      background: #161616 !important;
    }

    .oa-inner-subtitle {
      color: #ebeaea;
    }

    .course-title {
      color: white;
    }
  }
}

.playlist-video-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vertical-line {
  height: 100%;
  width: 1;
  background-color: #909090;
}

.options-academy-signup-wrapper {
  .MuiDivider-root {
    margin-top: 20px;
    border-color: #0003 !important;
  }
}
.logo-title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  .ta-logo-oa {
    width: 44px;
    height: 47px;
  }
}
