.up-strikes {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #9ca3af;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.current-strike {
  position: relative;
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #4b5563;
  text-align: center;
}

.option-scanner-filter-box-part1 {
  display: flex;
  align-items: center;
}

.symbol {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-right: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.calls {
  .strike-higher-light {
    background: white;
  }

  .strike-lower-light {
    background: #dcbdf1 !important;
  }
}

.calls {
  .strike-higher-dark {
    background: white;
  }

  .strike-lower-dark {
    background: #14031f !important;
  }
}

.puts {
  .strike-higher-dark {
    background: #14031f !important;
  }

  .strike-lower-dark {
    background: white;
  }
}

.puts {
  .strike-higher-light {
    background: #dcbdf1 !important;
  }

  .strike-lower-light {
    background: white;
  }
}

.option-scanner {
  .MuiAutocomplete-popper {
    z-index: 1000;
  }

  .MuiPickersDay-today {
    border: none !important;
  }

  .data-tables {
    > h5 {
      font-family: POPPINS;
      font-weight: bold !important;
      color: #000000;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }
  }
}

.option-scanner-filter-box {
  // background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dashboard-datepicker {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  .ndatepicker {
    height: 44px;
    background: #f1f5f9;
    border-radius: 24px;
    padding: 8.5px 15px;

    svg {
      path {
        stroke: #555555;
      }
    }
  }
}

.option-scanner-date-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-scanner-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
}

.option-scanner-timelines {
  display: flex;
  justify-content: flex-start;
  margin-left: 50px;
  position: relative;
  top: -30px;
}

.option-scanner-timeline-up-light.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}

.option-scanner-timeline-up-dark.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}

.option-scanner-timeline-down-light.active {
  background: #b8040a;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ede7e7;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}

.option-scanner-timeline-down-dark.active {
  background: #b8040a;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ede7e7;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  top: -4px;
}

.option-scanner-timeline-down-dark {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffab;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-timeline-down-light {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #100e0eab;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-timeline-up-dark {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffffab;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-timeline-up-light {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-button {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #b7b4c7;
  cursor: pointer;
  padding: 0 16px;
}

.option-scanner-button.active {
  background: #5eeecd;
  border-radius: 12px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  margin-left: 16px;
  margin-right: 16px;
}

.option-scanner-symbol-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.option-scanner-price-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-scanner-robin-hood-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1539px) {
  .dashboard-datepicker {
    .symbol {
      margin-left: 0 !important;
    }
  }

  .option-scanner-filter-box-part1 {
    justify-content: center !important;
  }

  .option-scanner-filter-box {
    flex-direction: column;

    div.option-scanner-filter-box-part1 {
      width: 100%;
      margin-bottom: 20px;
    }

    .option-scanner-date-area {
      width: 100%;
      justify-content: center;
    }
  }

  .option-scanner-filter-box {
    flex-direction: row;

    div.option-scanner-filter-box-part1 {
      width: 100%;
      margin-bottom: 0;
    }

    .option-scanner-date-area {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1399px) {
  .dashboard-datepicker {
    .symbol {
      margin-left: 0 !important;
    }
  }

  .option-scanner-filter-box {
    flex-direction: column;

    div.option-scanner-filter-box-part1 {
      width: 100%;
      margin-bottom: 20px;
    }

    .option-scanner-date-area {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .option-scanner-filter-box-part1 {
    flex-direction: column;
  }

  .option-scanner-symbol-selector {
    margin-bottom: 20px;
  }

  .dashboard-datepicker {
    justify-content: center;
    margin-bottom: 20px;
  }

  .option-scanner-date-area {
    flex-direction: column;
  }

  .option-scanner-buttons {
    width: 100%;
    margin-left: 0;
  }
}

body {
  &.dark {
    .symbol {
      color: white;
    }

    .top-title-o-wrap {
      .Mui-disabled {
        background-color: #202020 !important;
        color: white !important;
      }
    }

    .Mui-disabled.MuiPickersDay-root {
      background-color: #1c1c1e;
      color: rgba(255, 255, 255, 0.26);
      border: none;
    }

    .MuiPickersDay-root {
      background-color: #1c1c1e;
      color: white;
    }

    .Mui-selected.MuiPickersDay-root {
      background-color: #1976d2;
    }

    .MuiPickersDay-root:hover {
      background-color: #1976d2;
    }

    .MuiTypography-root {
      color: white;
    }

    // .MuiSvgIcon-fontSizeMedium{
    //   fill: white;
    //   color: white;
    // }
    .MuiCalendarPicker-root {
      background: #202020;
      color: white;
    }

    .MuiAutocomplete-paper {
      background-color: #202020 !important;

      .Mui-focused {
        background-color: #3a3a3d !important;
      }

      .MuiAutocomplete-option {
        background-color: rgb(16 9 22 / 8%);
        color: white;
        border: 1px solid black;
      }

      .MuiAutocomplete-noOptions {
        color: white;
      }

      .MuiAutocomplete-loading {
        color: white;
      }
    }
  }
}

/* New Stylings */
.option-scanner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.option-scanner-company-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-scanner-company-info-left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 20px;
  border-right: 1px solid #cccccc;
}

.option-scanner-company-info-ticker {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.option-scanner-company-info-name {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 215px;
}

.option-scanner-company-info-right {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.option-scanner-company-price-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px;
}

.option-scanner-company-live-price {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.option-scanner-company-price-symbol {
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: -3px;
}

.option-scanner-company-price-change {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.option-scanner-company-performance {
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.option-scanner-company-performance--positive {
  color: #3eb489;
}

.option-scanner-company-performance--negative {
  color: #ea3323;
}

.option-scanner-company-percent-value {
  border-radius: 22px;
  padding: 4px 10px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.option-scanner-company-percent-value--positive {
  color: #3eb489;
  background: rgba(62, 180, 137, 0.1);
}

.option-scanner-company-percent-value--negative {
  color: #ea3323;
  background: rgba(234, 51, 35, 0.1);
}

.option-scanner-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.option-scanner-datepicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.option-scanner-datepicker-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.option-scanner-datepicker .ndatepicker {
  padding: 8.5px 15px;
  border-radius: 20px;
  border: 0.5px solid #e4e5e8;
  background: transparent;
}

.option-scanner-datepicker .ndatepicker input {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: none;
  background: transparent;
}

.option-scanner-datepicker .ndatepicker input:focus {
  outline: none;
}

.option-scanner-filters-strike-items {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
}

.option-scanner-filters-strike-item {
  color: #ccc;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  height: 44px;
}

.option-scanner-filters-strike-current-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.option-scanner-filters-strike-current-item-value {
  height: 44px;
  display: flex;
  align-items: center;
}

.option-scanner-filters-types {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.option-scanner-filter-type {
  padding: 4px 10px;
  border-bottom: 3px solid transparent;
  color: #ccc;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.option-scanner-filter-type.active {
  color: #007bff;
  border-bottom: 3px solid #007bff;
}

.separator-borders {
  width: 1px;
  background: #cccccc;
  height: 27px;
}

body.dark {
  .option-scanner-company-info-left {
    border-color: #86868b;
  }

  .option-scanner-company-info-ticker {
    color: #fff;
  }

  .option-scanner-company-info-name {
    color: #e4e5e8;
  }

  .option-scanner-datepicker-title {
    color: #fff;
  }

  .option-scanner-filters-strike-current-item {
    color: #fff;
  }

  .option-scanner-datepicker .ndatepicker input {
    color: #cccccc;
  }

  .option-scanner-company-percent-value--positive {
    color: #5eeecd;
  }

  .option-scanner-filter-type.active {
    color: #5eeecd;
    border-bottom: 3px solid #5eeecd;
  }
}

.swing-trades-date-nav {
  width: calc(100% / 3);
}

@media (max-width: 1200px) {
  .option-scanner-filters {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }
}

@media (max-width: 1060px) {
  .option-scanner-datepicker {
    order: 0;
  }

  .option-scanner-filters-strike-items {
    order: 2;
  }

  .option-scanner-filters-types {
    order: 1;
    top: 10px;
  }
}

@media (max-width: 767px) {
  .option-scanner-header {
    flex-direction: column;
    gap: 20px;
  }

  .option-scanner-header-search-wrapper {
    width: 100%;
  }
}

@media (max-width: 509px) {
  .option-scanner-datepicker {
    width: 100%;
    align-items: center;
  }

  .option-scanner-filters-strike-items {
    order: 1;
  }

  .option-scanner-filters-types {
    order: 2;
  }
}
