.ws-contact-support-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  border-bottom: 2px solid #e4e2e2;
  padding: 6px 24px 6px 0;
  cursor: pointer;
}

.ws-contact-support-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #1C1C1E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;

  svg {
    width: 24px;
    height: 24px;
    color: #000000;
    fill-opacity: 0.56;
  }
}

.ws-contact-support-chip {
  padding: 3px 10px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
}

body.dark {
  .ws-contact-support-button {
    border-bottom: 2px solid rgba(255, 255, 255, 0.12);
  }

  .ws-contact-support-button__wrapper {
    color: #F2F2F5;

    svg {
      color: #FFFFFF;
    }
  }

  .ws-contact-support-chip {
    background: rgba(255, 255, 255, 0.16);
    color: #FFFFFF;
  }
}

@media (max-width: 1199px) {
  .ws-contact-support-button {
    display: none;

    &.ws-contact-support-button--mobile {
      display: flex;
      border-bottom: none !important;
    }
  }
}


@media (max-width: 500px) {
  .ws-contact-support-button__wrapper {
    font-size: 13px;
    line-height: 20px;
  }
}