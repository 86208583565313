.ws-telegram-cta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  border-bottom: 2px solid #e4e2e2;
  padding-right: 24px;

  button {
    padding: 6px;
    border-radius: 4px;
    gap: 8px;
    text-transform: inherit;
    color: #007BFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;

    & > span {
      margin: 0;
    }
  }

  &.ws-telegram-mobile {
    width: 100%;
    border-bottom: none;
    border-radius: 8px;
    background: rgba(0, 123, 255, 0.05);
    padding: 2px 16px;

    button {
      width: 100% !important;
    }
  }
}

body.dark {
  .ws-telegram-cta {
    border-bottom: 2px solid rgba(255, 255, 255, 0.12);

    button {
      color: #5eeecd;
      
      svg path {
        fill: #5eeecd;
      }
    }

    &.ws-telegram-mobile {
      border-bottom: none !important;
      background: #5eeecd26;
    }
  }
}