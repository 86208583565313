.past-videos-wrapper {
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 97%;
    position: relative;
    right: 6px;
    bottom: 3px;
  }
}

body {
  &.dark {
    .past-videos-wrapper {
      .MuiSelect-iconOutlined {
        fill: white !important;
      }
      .MuiList-root {
        background-color: white !important;
      }

      .MuiMenu-list {
        background-color: white !important;
      }
    }
  }
}

.past-videos-dropdown {
  padding: 24px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  cursor: pointer;
  flex-direction: column;
  background: #191b1c;
  box-shadow: 0px 0px 0px 1px #e4e4e4;
  border-radius: 8px;
}

.past-videos-list {
  width: 100%;
  padding-top: 20px;
  display: none;
}

.past-videos-dropdown:hover .past-videos-list {
  display: block;
}

.past-videos-dropdown-option {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  padding: 5px 0;
  cursor: pointer;
}

.past-videos-dropdown-option.selected {
  font-weight: 700;
}

span.past-videos-dropdown-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.past-videos-dropdown svg {
  fill: #bcbcbc !important;
  color: #bcbcbc !important;
  width: 30px !important;
  height: 30px !important;
}

.past-videos-big-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 40px;
}

.past-videos-small-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 150%;
  color: #f5f5f5;
}

.past-videos-blue-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  color: #5eeecd;
}

.past-videos-blue-title svg {
  color: #007bff !important;
  fill: #007bff !important;
}

.past-videos-top-section {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 16px;
  min-height: 520px;
}

.past-videos-chat-widget {
  margin-top: 10px;
  margin-bottom: 10px;

  iframe {
    border-radius: 10px;
    height: calc(50vh + 24px);
  }
}

.past-videos-key-resources {
  margin-top: 17px;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  padding: 16px;

  .past-videos-small-title {
    margin-bottom: 30px;
  }
}

.past-videos-private-session {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: rgba(94, 238, 205, 0.1);
  border-radius: 10px;
  padding: 28px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

img.social-care-img {
  width: 60px;
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-106 {
  margin-top: 106px !important;
}
.give-padding-top {
  margin-top: 14px;
}

body.dark {
  .past-videos-top-section {
    background: #191b1c;
  }

  .past-videos-private-session {
    background: #1a1c1d;
    border: 1px solid #5eeecd;
  }

  .past-videos-private-session-content {
    color: #5eeecd;
    background: initial;
    -webkit-text-fill-color: initial;
  }

  .past-videos-key-resources {
    background: #191b1c;
  }
}
