@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
@font-face {
  font-family: "Futura Bold";
  src:
    local("Futura Bold"),
    url(../assets/fonts/Futura.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Medium";
  src:
    local("Futura Medium"),
    url(../assets/fonts/Futura_medium.ttf) format("truetype");
}

/* start common styles */
.no-padding-full {
  padding: 0 !important;
}

@media (min-width: 992px) {
  .no-padding {
    padding: 0 !important;
  }
}

.no-row-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* custom container */
@media (min-width: 1200px) {
  .custom-container {
    max-width: 1200px !important;
    margin: 0 auto !important;
  }
}

/* custom width */
@media (min-width: 992px) {
  .custom-width {
    width: 600px;
    margin: auto;
  }
}
.no-margin {
  margin: 0 !important;
}
.checkout-logo {
  width: 174px;
  height: 45px;
}
.checkout-header {
  display: flex;
  align-items: center;
  height: 60px;
  z-index: 1;
  -webkit-box-shadow: 0 2px 33px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 2px 33px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 33px 0 rgba(0, 0, 0, 0.09);
}
.wrapper {
  padding-top: 25px;
}

@media (min-width: 992px) {
  .wrapper {
    padding-top: 50px;
  }
}

.inline-list-items li {
  display: inline;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.flex-grow {
  flex: 1 1 auto;
}

.same-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 2px solid #007bff;
  padding: 3px;
}

.avatar-sm {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 2px solid #007bff;
  padding: 3px;
}

.border-radius-default {
  border-radius: 5px;
}

.border-radius-secondary {
  border-radius: 3px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-default {
  border: 1px solid#e9ebed;
}

.border-primary {
  border: 1px solid #dddfe2;
}

.box-shadow-bottom {
  box-shadow: 0 9px 9px -9px #00000021;
}

.box-shadow-default {
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  box-shadow: 0 0 10px #e9ebed !important;
  /* box-shadow: 0 0 5px #00000029; */
}

.box-shadow-primary {
  box-shadow: 0 1px 2px #00000033;
}

.box-shadow-secondary {
  box-shadow:
    #e0e1e2 0px 1px 2px 0px,
    #e0e1e2 0px 1px 3px 1px;
}

.card-box-shadow {
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);
}

.box-shadow-ghost {
  box-shadow:
    #172b4d33 0px 1px 1px,
    #172b4d33 0px 0px 1px;
}

.border-bottom-default {
  border-bottom: 1px solid #bad4ff;
}

.border-bottom-primary {
  border-bottom: 3px solid #007bff;
}

.border-top-secondary {
  border-bottom: 1px solid#e9ebed;
}

.border-bottom-secondary {
  border-bottom: 1px solid#e9ebed;
}

.border-bottom-ghost {
  border-bottom: 1px solid #dde0e4;
}

.border-bottom-0 {
  border-bottom: none;
}

.text-gray {
  color: #979ba0;
}

.text-shuttle-gray {
  color: #646777;
}

.text-blue {
  color: #007bff;
}

.text-dark-blue {
  color: #144597;
}

.text-pale-blue {
  color: #b9d3ff;
}

.text-summer-sky {
  color: #21bbee;
}

.text-light-black {
  color: #424242;
}

.text-black {
  /* color: #212529; */
  color: #000000 !important;
}

.text-xsmall {
  font-size: 15px;
}

.text-small {
  font-size: 16px;
}

.text-medium {
  font-size: 17px;
}

.text-large {
  font-size: 19px;
}

.text-xlarge {
  font-size: 23px;
}

.text-xxlarge {
  font-size: 27px;
}

.text-xxxlarge {
  font-size: 35px;
}

.text-huge {
  font-size: 40px;
}

.text-xhuge {
  font-size: 50px;
}

.text-weight-light {
  font-weight: 400 !important;
}

.text-bold {
  font-weight: 500 !important;
}

.text-bolder {
  font-weight: 600 !important;
}

.text-boldest {
  font-weight: 700 !important;
}

.line-height-secondary {
  line-height: 1.4;
}

.text-ellipsis {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-break {
  word-break: break-all;
}

.bg-summer-sky {
  background-color: #45b4f1 !important;
}

.bg-blue {
  background-color: #4285f4 !important;
}

.bg-light-blue {
  background-color: #2d88ff1a !important;
}

.bg-dark-blue {
  background-color: #144597;
}

.bg-transparent-secondary {
  background-color: #00000030;
}

.bg-gradient-primary {
  background-image: linear-gradient(90deg, #4185f3 0%, #2159b7 100%);
}

.first-letter-capitalize::first-letter {
  text-transform: capitalize;
}

.text-decoration-none {
  text-decoration: none;
}

.text-underline {
  text-decoration: none;
}

.text-underline-position-under {
  text-underline-position: under;
}

.line-through {
  text-decoration: line-through;
}

.line-clamp-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-short {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-long {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-up-positive {
  transform: rotate(360deg);
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #18bb5c transparent transparent;
  font-size: 0;
  margin-bottom: 5px;
}

.arrow-down-negative {
  transform: rotate(360deg);
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent #ff433d transparent;
  font-size: 0;
  margin-bottom: 5px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.z-index-1 {
  z-index: 1;
}

.opacity-hover:hover {
  opacity: 0.8;
}

.animate-up {
  transition: all linear 0.2s !important;
  -webkit-transition: all linear 0.2s !important;
}

.animate-up:hover {
  transform: translate3d(0, -3px, 0);
  -webkit-transform: translate3d(0, -3px, 0);
  transition: all linear 0.2s !important;
  -webkit-transition: all linear 0.2s !important;
}

.schedule-demo-button {
  background: #0071ee !important;
}
/* end common styles */

/* start link styles */

.default-link:hover {
  text-decoration: none;
}

a.secondary-link {
  color: #212529;
}

a.secondary-link:hover {
  color: #212529;
  /* text-decoration: underline; */
}

.tag-link {
  background-color: #d1d5da38;
  padding: 5px 10px;
  border-radius: 5px;
}

/* end link styles */

/* start button styles */

.btn-loader {
  line-height: 10px;
}

.btn-loader .default-spinner {
  font-size: 10px;
}

.btn-w-100 {
  width: 100%;
}

@media (min-width: 768px) {
  .btn-w-md-auto {
    width: auto;
  }
}

@media (min-width: 992px) {
  .btn-w-lg-auto {
    width: auto;
  }
}

.btn.btn-action-link {
  background-color: #007bff;
  color: #fff;
  font-size: 0.9rem;
}

.btn.btn-action-link:hover {
  color: #fff;
  text-decoration: none;
}

.btn.btn-action-link:focus {
  text-decoration: none;
}

.btn-link.btn {
  padding: 0;
}

.btn-link.btn:hover {
  text-decoration: none;
}

.btn-icon-only:focus {
  box-shadow: none;
}

/* start tag button styles */
.btn-tag {
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: #212529 !important;
}

.btn-tag:hover {
  color: #007bff !important;
  border-color: #007bff !important;
}

.btn-tag.active {
  background-color: #e8f0fe !important;
  border-color: #e8f0fe !important;
  color: #007bff !important;
}

.btn-tag:focus {
  box-shadow: 0 0 0 0.2rem #e8f0fe;
}
/* end tag button styles */

.btn-default {
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: #212529 !important;
}

.btn-default:hover {
  color: #007bff !important;
  border-color: #007bff !important;
}

.btn-default.active {
  color: #007bff !important;
  border-color: #007bff !important;
}

.btn-ghost {
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: #212529 !important;
}

.btn-ghost:focus {
  box-shadow: none;
}

/* start btn primary control styles */
.btn-primary-control.btn-md {
  border-radius: 17px;
  height: 30px;
  width: 30px;
}

.btn-primary-control.btn-lg {
  border-radius: 19px;
  height: 40px;
  width: 40px;
}

.btn-primary-control {
  background-color: #e0e3eb3d !important;
  color: #787b86;
}

.btn-primary-control:hover {
  filter: brightness(0.8);
}

.btn-primary-control.active {
  filter: brightness(0.8);
}
/* end btn primary control styles */

/* start btn secondary control styles */
.btn-secondary-control.btn-md {
  border-radius: 17px;
  height: 30px;
  width: 30px;
}

.btn-secondary-control.btn-lg {
  border-radius: 19px;
  height: 40px;
  width: 40px;
}

.btn-secondary-control {
  background-color: #e0e3eb87 !important;
  color: #787b86;
}

.btn-secondary-control:hover {
  filter: brightness(0.8);
  color: #787b86;
}

.btn-secondary-control.active {
  filter: brightness(0.8);
  color: #787b86;
}
/* end btn secondary control styles */

.btn-wave {
  background-color: #45b4f1 !important;
  color: #fff !important;
}

.btn-wave:hover {
  background-color: #fff !important;
  color: #45b4f1 !important;
}

.btn-gradient-primary {
  background-image: linear-gradient(90deg, #4185f3 0%, #2159b7 100%);
  color: #fff !important;
  border: none !important;
}

.btn-primary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer !important;
  margin-left: 20px;
  padding: 10px 30px !important;
  /* background: linear-gradient(101.16deg, #991CA6 -7.41%, #0032ED 110.44%)!important; */
  background: #3183ff !important;
  border-radius: 12px !important;
  border: none !important;
}

.btn-primary:hover {
  border-color: transparent;
}

.btn-gray {
}

.btn-outline {
  color: #ffffff !important;
  /*border-color: #007bff !important;*/
}

.btn-transparent {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btn-transparent:hover {
  border-color: transparent !important;
  background-color: #144597 !important;
  color: #fff !important;
}

/* .custom-icon-btn {
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.custom-icon-btn:hover {
  border-color: transparent !important;
}

.custom-icon-btn:focus {
  box-shadow: none !important;
} */

.btn .icon-right {
  margin-left: 5px;
}

.btn .icon-left {
  margin-right: 5px;
}

.btn-icon {
  line-height: 9px;
}

.btn-label-right {
  margin-right: 5px;
}

.btn-label-left {
  margin-left: 5px;
}

.btn-text:hover {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  z-index: 9999;
}

.btn.btn-sm.btn-with-icon .btn-label {
  font-size: 0.8rem;
  line-height: 21px;
}

.btn.btn-md {
  padding: 8px 12px;
}

.btn.btn-md .btn-label {
  font-size: 0.9rem;
  line-height: 20px;
}

.btn.btn-lg .btn-label {
  font-size: 1rem;
  line-height: 35px;
}

/* end button styles */

.label.label-custom {
  margin-bottom: 0;
  color: #007bff;
  text-transform: uppercase;
  font-weight: 400;
}

.positive-text-color {
  color: rgba(84, 202, 19, 1);
}

.blue-positive-text-color {
  color: #0071ee;
}

.negative-text-color {
  color: red;
}

.positive-bg {
  background-color: #20c997;
}

.negative-bg {
  background-color: #dc3545;
}

.wave-border {
  width: 100%;
  height: 8px;
  background-color: #3f7de6;
  background-image: linear-gradient(
    90deg,
    #4484ed 0%,
    #4185f3 35%,
    #00c1cf 100%
  ) !important;
  border-radius: 3px;
}

.fa-cc-visa {
  color: #007bff !important;
}

.dot-default {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

/* start tabs common styles */

.custom-nav-tabs {
  border-bottom: none !important;
}

.custom-nav-tabs .nav-item {
  flex-basis: auto !important;
}

.custom-nav-tabs .nav-link {
  border-color: transparent !important;
  /* border-bottom: 2px solid transparent !important; */
  background-color: #fff;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  transition: all ease-in 0.3s;
  background-color: #e0f0ff !important;
}

.custom-nav-tabs .nav-link:hover {
  background-color: #787b86 !important;
  border-bottom-color: #bad4ff !important;
}

.custom-nav-tabs .nav-link.active {
  background-color: #ffffff !important;
  border-bottom-color: #bad4ff !important;
  font-weight: 500;
  transition: all ease-in 0.3s;
}

/* start primary */
.nav-tabs-primary {
  border-bottom: none;
  /* justify-content: flex-end; */
}

.nav-tabs-primary .nav-item {
  margin-bottom: 0;
  /* max-width: 200px; */
}

.nav-tabs-primary .nav-link {
  color: #212529;
  border: none;
  border-bottom: 2px solid #d9d9d9 !important;
  transition: all ease-in 0.3s;
}

.nav-tabs-primary .nav-link:hover {
  color: #212529;
}

.nav-tabs-primary .nav-link.active {
  border-color: #007bff !important;
  transition: all ease-in 0.3s;
}

/* end primary */

/* start tab buttons */
.nav-tabs-buttons {
  border-bottom: none;
  /* justify-content: flex-end; */
}

.nav-tabs-buttons .nav-item {
  margin-bottom: 0;
  /* max-width: 200px; */
}

.nav-tabs-buttons .nav-link {
  background-color: #fff;
  margin-right: 10px;
  border-radius: 3px;
  border: none;
  transition: all ease-in 0.3s;
}

.nav-tabs-buttons .nav-link.active {
  color: #fff;
  background-color: #007bff;
  transition: all ease-in 0.3s;
}
/* end tab buttons */

/* end tabs common styles */

.v-scroll {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  white-space: nowrap;
}

.h-scroll {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.h-scroll::-webkit-scrollbar {
  display: none;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

@media (max-width: 575px) {
  .sticky-sm {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
}

.slide-down {
  transform: translateZ(0);
  transition: all 0.5s;
}

.slide-down-active {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  animation: slide-down 0.7s;
  opacity: 0.9;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

.fade-animation {
  transition: all 0.5s;
}

.fade-animation.active {
  animation: fade 0.7s;
}

@keyframes fade {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 0.9;
    height: 100%;
  }
}

/* @keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 0.9;
    transform: translateX(0);
  }
} */

.sticky-node {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.sticky-node.hidden {
  transform: translateY(-110%);
}

/* start dnd styles */
/* .draggable-list {
  background-color: rgb(235, 236, 240);
  display: flex;
  flex-direction: column;
  opacity: inherit;
  padding: 8px 8px 0px;
  border: 8px;
  transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
  user-select: none;
} */
/* end dnd styles */

/* start css transition group styles */

.enter {
  opacity: 0;
  transform: scale(0.9);
}

.enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.exit {
  opacity: 1;
}

.exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}

/* end css transition group styles */

/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(-5px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-5px);
    transform: translateX(-2px);
  }
  60% {
    -ms-transform: translateX(-5px);
    transform: translateX(-2px);
  }
}
/* /right bounce */

.trade-algo-logo-wrapper {
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif !important;
}

.black-logo-text {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #1d1a27;
}

.white-logo-text {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #fff;
}

.trade-algo-logo-wrapper span {
  font-family: Poppins, sans-serif !important;
  letter-spacing: 0.1px !important;
}

.btn {
  background: transparent;
}
