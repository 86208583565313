.header .search-bar .ant-input {
  font-size: 15px;
}

.search-bar-dropdown .search-dropdown-link {
  font-size: 13px;
  cursor: pointer;
}

.search-bar-dropdown .search-dropdown-link:hover {
  text-decoration: underline;
}

.search-bar-dropdown .search-dropdown-label {
  min-width: 80px;
  margin-right: 10px;
  text-align: left;
}

@media (min-width: 992px) {
  .search-bar-dropdown .search-dropdown-label {
    width: 80px;
  }
}

@media (max-width: 575px) {
  .search-bar-dropdown .ant-select-item-option-grouped {
    padding-left: 10px;
  }
}
