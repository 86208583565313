.pie-chart-for-sentiment {
  border-radius: 50%;
  height: 170px;
  width: 170px;
  position: relative;
}

.pie-chart-for-sentiment:before {
  content: "";
  position: absolute;
  height: 152px;
  width: 152px;
  background-color: white;
  border-radius: 50%;
  left: 9px;
  top: 9px;
}

.pie-chart-for-sentiment__center {
  position: absolute;
  height: 134px;
  width: 134px;
  background-color: #f1f5f9;
  filter: drop-shadow(0px 0px 12px rgba(62, 180, 137, 0.2));
  box-shadow: rgba(62, 180, 137, 0.2) 0px 0px 12px 6px;
  border-radius: 50%;
  left: 18px;
  top: 18px;
}

.pie-chart-for-sentiment-information {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.pie-chart-for-sentiment-information-title {
  color: #a6a6a6;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pie-chart-for-sentiment-information-percentage {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pie-chart-for-sentiment-information-label {
  border-radius: 20px;
  padding: 4px 12px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label--bullish {
  color: #4caf50;
  background: rgba(76, 175, 80, 0.12);
}

.label--bearish {
  color: rgb(255, 59, 48);
  background: rgba(255, 59, 48, 0.1);
}

.label--neutral {
  color: #000;
  background: rgba(0, 0, 0, 0.1);
}

body.dark {
  .pie-chart-for-sentiment::before {
    background-color: #000;
  }

  .pie-chart-for-sentiment__center {
    background-color: #1c1c1e;
    filter: none;
    box-shadow: none;
  }

  .pie-chart-for-sentiment-information-title {
    color: #e4e5e8;
  }

  .pie-chart-for-sentiment-information-percentage {
    color: #ffffff;
  }

  .label--bullish {
    background: rgba(62, 180, 137, 0.4);
    color: #5eeecd;
  }

  .label--neutral {
    color: #e4e5e8;
    background: rgba(255, 255, 255, 0.1);
  }

  .news-sentiment-details-title {
    color: #ffffff;
  }
}
