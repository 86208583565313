.previous-videos-page {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 15px;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.previous-video {
  width: calc(100% / 3 - 15px);
  cursor: pointer;
}

.previous-video-inner-content {
  padding: 20px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
  transition: box-shadow 0.2s ease-in-out;

  &.active-video-content {
    background-color: #e7e7e7 !important;
  }

  &:hover {
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.55);
  }
}

.previous-video-thumbnail {
  border-radius: 10px;
  height: auto;
  width: 100%;
  cursor: pointer;
}

.previous-video-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.previous-video-length {
  color:rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 5px;
}

.previous-videos-pagination {
  margin-bottom: 30px;
}

body.dark {
  .previous-video-title {
    color: #ffffff;
  }

  .previous-video-length {
    color: #c3c3c3;
  }

  .previous-video-inner-content {
    background-color: rgba(21, 23, 23, 0.5);
    
    &.active-video-content {
      background-color: #000 !important;
    }
  }
}

@media (max-width: 1300px) {
  .previous-video-title {
    font-size: 14px;
  }

  .previous-video-length {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .previous-video {
    width: calc(50% - 15px);
  }
}

@media (max-width: 767px) {
  .previous-video {
    width: 100%;
  }

  .previous-video-title {
    font-size: 16px;
  }

  .previous-video-length {
    font-size: 16px;
  }
}
