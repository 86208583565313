@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

body {
  font-family: Poppins, sans-serif !important;
  overflow: auto !important;
  /* color: #2f2f2f; */
}

body.scroll-freeze {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appWrapper {
  width: 100%;
}

#root {
  /*background: #000000;*/
}
