.dashboard-top-banner {
  padding: 0 15px;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-image: url("../../../src/assets/images/dashboard-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.logo-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.logo-dashboard {
  display: flex;
  align-items: center;
  gap: 6px;
}

.logo-img {
  width: 120px;
  height: 30px;
}

.dashboard-title {
  color: #5eeecd;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date-time {
  font-size: 0.9em;
  color: #fff;
  display: flex;
  gap: 10px;
  align-items: center;
}

.welcome-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.welcome-title {
  color: white;

  .name {
    color: #5eeecd;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.hello-text {
  color: #fff;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trading-buttons {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.trading-buttons button {
  border: none;
  border-radius: 4px;
  white-space: nowrap;
}

.time {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
}

.date {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 0.5px solid #fff;
  border-radius: 38px;
  padding: 6px 18px;
}

.info {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.separator {
  width: 280px;
  height: 1px;
  background: #a6a6a6;
  margin: 0;
}

.trading-button {
  background-color: transparent !important;
  border-radius: 6px !important;
  border: 1px solid #fff !important;
  padding: 6px 20px !important;
  text-transform: none !important;
  color: #fff !important;
  text-align: center;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
}

.trading-button.active {
  border: 1px solid #5eeecd !important;
  background: #5eeecd !important;
  color: black !important;
  font-weight: 500 !important;
}

@media (max-width: 767px) {
  .welcome-titles {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .welcome-title {
    text-align: center;
  }

  .hello-text {
    font-size: 32px;
  }

  .welcome-title .name {
    font-size: 32px;
  }

  .info {
    text-align: center;
  }

  .separator {
    margin: 0 auto;
  }

  .trading-buttons {
    justify-content: center;
  }
}
