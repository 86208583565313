.ws-portfolio-item {
  width: 100%;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.55);
  }
}

.ws-portfolio-item__header {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1 1 auto;
  gap: 8px;
}

.ws-portfolio-item__header-info {
  width: calc(100% / 2 - 4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.ws-portfolio-item__title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.ws-portfolio-item__performance {
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.ws-portfolio-item__performance_value {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 143%;
  letter-spacing: 0.17px;

  &.positive {
    color: #4CAF50;
  }

  &.negative {
    color: #F44336;
  }
}

.ws-portfolio-item__header-sparklink {
  width: calc(100% / 2 - 4px);
  position: relative;
}

.ws-portfolio-item__content {
  min-height: 247px;
  padding-bottom: 8px;
}

.ws-portfolio-holdings-label {
  display: flex;
  padding: 16px 20px 4px 20px;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.ws-portfolio-holding {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.ws-portfolio-holding__ticker {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-holding__icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.ws-portfolio-holding__price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ws-portfolio-holding__percentage {
  display: flex;
  max-width: 75px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

body.dark {
  .ws-portfolio-item {
    background: #2C2C2C;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.12);
      background: #2C2C2C;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.55);
    }
  }

  .ws-portfolio-item__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .ws-portfolio-item__title {
    color: #FFF;

    svg path {
      fill: #FFF;
      fill-opacity: 0.56;
    }
  }

  .ws-portfolio-item__performance {
    color: rgba(255, 255, 255, 0.70);
  }

  .ws-portfolio-item__performance_value {
    &.positive {
      color: #A5D6A7;
    }

    &.negative {
      color: #FF9494;
    }
  }

  .ws-portfolio-holdings-label {
    color: rgba(255, 255, 255, 0.70);
  }

  .ws-portfolio-holding__ticker {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-holding__price {
    color: rgba(255, 255, 255, 1);
  }

  .ws-portfolio-holding__percentage {
    color: rgba(255, 255, 255, 1);
  }
}

@media (max-width: 1299px) {
  .ws-portfolio-item__header {
    flex-direction: column;
  }

  .ws-portfolio-item__header-info {
    width: 100%;
  }

  .ws-portfolio-item__header-sparklink {
    width: 100%;
  }
}