.options-mentorship-sign-up-result {
  // background-image: url(../../../assets/images/diamond.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 90px);
  padding: 80px;
}

.options-mentorship-sign-up-result-icon-wrapper {
  text-align: center;

  svg {
    color: #007bff !important;
    fill: #007bff !important;
    width: 200px !important;
    height: 200px !important;
  }
}

.options-mentorship-sign-up-result-loading-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
}

.options-mentorship-sign-up-result-success-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  margin-top: 25px;
}

.options-mentorship-sign-up-result-success-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

.back-to-dashboard-om {
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border: 1px solid white !important;
  margin: 0 auto !important;
  display: block !important;
  margin-top: 30px !important;
  text-transform: initial !important;
  padding: 8px 16px !important;
  background: #5eeecd;
  background-clip: text;
  color: black !important;
}

body.light {
  .back-to-dashboard-om {
    border-radius: 5px !important;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    border: 1px solid black !important;
    margin: 0 auto !important;
    display: block !important;
    margin-top: 30px !important;
    text-transform: initial !important;
    padding: 8px 16px !important;
    background: #5eeecd;
    background-clip: text;
    color: black !important;
  }
  .options-mentorship-sign-up-result-loading-text {
    color: #000;
  }

  .options-mentorship-sign-up-result-success-title {
    color: #000;
  }

  .options-mentorship-sign-up-result-success-info {
    color: #000;
  }
}

@media (max-width: 600px) {
  .options-mentorship-sign-up-result {
    padding-left: 40px;
    padding-right: 40px;
  }

  .options-mentorship-sign-up-result-success-title {
    font-size: 54px;
    line-height: 71px;
  }

  .options-mentorship-sign-up-result-success-info {
    font-size: 16px;
    line-height: 21px;
  }
}
