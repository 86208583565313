.emerald-common-page {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
}

.emerald-common-page__header {
  display: flex;
  gap: 18px;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

.emerald-common-page__left {
  width: calc(70% - 9px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.emerald-common-page__video {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
  border: 1px solid #000;
}

.emerald-common-page__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.emerald-common-page-author {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.emerald-common-page-author-name {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  margin-bottom: 3px;

  svg {
    width: 20px;
    height: 20px;
    color: #0071ee;
  }
}

.emerald-common-page-author-title {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emerald-common-page__content {
  border-radius: 10px;
  background: #fff;
  padding: 20px 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emerald-common-page__content-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.emerald-common-page__content-description {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e5e8;
}

.emerald-common-page__live_schedule-title {
  color: #121416;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 14px 0;
}

.emerald-common-page__schedules {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.emerald-common-page__schedule {
  width: calc((100% - 28px) / 5);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.emerald-common-page__schedule-day {
  background: #f7f8f7;
  padding: 5px 0;
  color: #7a7a7a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emerald-common-page__schedule-time {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emerald-common-page__right {
  width: calc(30% - 9px);
}

.emerald-common-page__body {
  display: flex;
  gap: 18px;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

body.dark {
  .emerald-common-page__title {
    color: #fff;
  }

  .emerald-common-page-author-name {
    color: #fff;
  }

  .emerald-common-page-author-title {
    color: #e4e5e8;
  }

  .emerald-common-page__content {
    background: #000;
    border: 1px solid #5eeecd;
  }

  .emerald-common-page__content-title {
    color: #fff;
  }

  .emerald-common-page__live_schedule-title {
    color: #fff;
  }

  .emerald-common-page__schedule-day {
    color: #e4e5e8;
    background: #1c1c1e;
  }

  .emerald-common-page__video {
    border: 1px solid #5eeecd;
  }

  .emerald-common-page-author-name {
    svg {
      color: #5eeecd;
    }
  }
}

@media (max-width: 1200px) {
  .emerald-common-page {
    align-items: flex-start;
  }

  .emerald-common-page__left {
    width: 100%;
  }

  .emerald-common-page__right {
    width: 100%;
    height: 700px;
  }

  .emerald-common-page__header {
    flex-direction: column;
  }

  .emerald-common-page__body {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .emerald-common-page__video {
    height: auto;
    aspect-ratio: 16 / 10;
  }

  .emerald-common-page__right {
    height: auto;
    aspect-ratio: 16 / 10;
  }
}

@media (max-width: 767px) {
  .emerald-common-page__schedules {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }

  .emerald-common-page__schedule {
    width: calc((100% - 40px) / 3);
  }

  .emerald-common-page__right {
    aspect-ratio: 1 / 1;
  }
}
