.free-tier-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.free-tier-nav-item {
  flex-grow: 1;
  text-align: center;
  width: 35%;
  padding: 29px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a6a6a6;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding-bottom: 10px;
  border-bottom: 6px solid transparent;
}

.mw-420 {
  max-width: 420px;
}

.free-tier-nav-item.active {
  border-bottom: 6px solid #5eeecd;
  color: black;
}

.prem-programs {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.16px;
}

.free-cards-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.free-card {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  width: 360px;
  height: 626px;
  gap: 25px;
}

.free-desc {
  color: #525252;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.069px;
}

.upgrade-free-button {
  background: linear-gradient(269.49deg, #5eeecd 0%, #007bff 100%) !important;
  border-radius: 5px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  text-transform: inherit !important;
  padding: 10px 40px !important;
  -webkit-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  -moz-box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
  box-shadow: 0px 16px 18px 0px rgba(0, 123, 255, 0.4) !important;
}

.card-price {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.137px;
}

.term-img {
  width: 308px;
  height: 176px;
}

.usd {
  color: #525252;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.069px;
}

.monthly {
  color: #525252;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.069px;
}

.free-card-title {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.062px;
}
.card-button-wrap {
  margin-top: auto;
}
body {
  &.dark {
    .prem-programs {
      color: white;
    }

    .free-tier-nav-item.active {
      color: #ffffff;
    }
  }
}

.analyst-rules {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  padding-left: 5px;
}

.rules-button {
  font-size: 14px !important;
  color: white !important;
  padding: 16px 24px !important;
  background-color: black !important;
  border-radius: 8px !important;
  width: auto !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 729px;
  box-sizing: border-box;
  position: relative;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 15px;
  }
}
.rules-list {
  padding: 20px 0px 20px 0px;
}
.full-notes {
  padding-left: 20px;
}
.bullets-rule {
  padding: 10px 20px 0px 20px;
}

.full-notes {
  text-decoration: underline;
}

.edit-note-icon {
  margin-top: 5px;
}

@media (max-width: 600px) {
  .rules-button {
    font-size: 10px !important; // Reduce the font size on mobile devices
    padding: 10px 16px !important; // Optionally, adjust the padding to make more room for the text
  }
}
