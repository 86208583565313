.emerald-tutorials {
  width: 100%;
}

.emerald-tutorials__hint {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 760px;
  margin-bottom: 20px;
}

.emerald-tutorials__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.emerald-tutorials__title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.emerald-tutorials__info {
  color: #3eb489;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

body.dark {
  .emerald-tutorials__hint {
    color: #e4e5e8;
  }

  .emerald-tutorials__title {
    color: #fff;
  }
}

@media (max-width: 575px) {
  .emerald-tutorials__header {
    flex-direction: column;
    gap: 10px;
  }
}
