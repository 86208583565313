.font-14 {
  font-size: 14px;
}

.font-bold {
  font-weight: 600;
}

.color-black {
  color: rgba(0, 0, 0, 0.87);
}

.color-green {
  color: #4CAF50;
}

.color-red {
  color: #F44336;
}

.lite-leaderboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lite-leaderboard__header-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.lite-leaderboard__header-icon {
  width: 32px !important;
  height: 32px !important;
  fill-opacity: 0.56;
}

.lite-leaderboard__header-timestamp {
  width: 330px;
}

.lite-leaderboard__content {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 1px 1px 15px 0px rgba(28, 28, 30, 0.04);
  margin-top: 16px;
  height: calc(100vh - 320px);
  overflow: auto;
  padding: 16px 0px;
}

.lite-leaderboard__content::-webkit-scrollbar {
  display: none;
}

.lite-leaderboard__content-header {
  display: flex;
  padding: 0px 24px;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: flex-start;
  height: 32px;
  color: rgba(0, 0, 0, 0.60);
}

.lite-leaderboard__collapse_button {
  min-width: 36px;
  cursor: pointer;

  svg {
    fill-opacity: 0.56;
    width: 16px !important;
    height: 16px !important;
  }
}

.lite-leaderboard__pr {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  min-width: 85px;
}

.lite-leaderboard__user {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  flex: 1 0 0;
  min-width: 150px;
}

.lite-leaderboard__focus {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  flex: 1 0 0;
  min-width: 200px;
}

.lite-leaderboard__stocks {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  flex: 1 0 0;
  min-width: 150px;
  display: flex;
}

.lite-leaderboard__performance {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  flex: 1 0 0;
  min-width: 110px;
  text-align: right;
}

.lite-leaderboard__action {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  width: 40px;

  button {
    padding: 0 !important;
    min-width: auto !important;
  }

  svg {
    fill-opacity: 0.56;
    fill: #000;
  }
}

.lite-leaderboard__content-body {
  padding: 0 16px;
}

.lite-leaderboard__row {
  width: 100%;

  &:hover {
    border-radius: 8px;
    background: rgba(187, 226, 255, 0.10);
  }
}

.lite-leaderboard__row.highlighted {
  background: var(--primary-blue-accentLight, rgba(187, 226, 255, 0.10));
  border-radius: 8px;
}

body.dark .lite-leaderboard__row.highlighted {
  background: var(--primary-blue-accentLight, rgba(187, 226, 255, 0.20));
  border-radius: 8px;
}

.lite-leaderboard__content-row {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 24px;
  color: rgba(0, 0, 0, 0.60);
  cursor: pointer;
}

.lite-leaderboard__stock_icon_wrapper {
  display: inline-grid;
  width: 14px;
}

.lite-leaderboard__stock_icon {
  border-radius: 50%;
  width: 180%;
  height: auto;
  border: 1px solid #fff;
}

.lite-leaderboard__content__splitter {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  svg {
    fill-opacity: 0.38;
  }
}

.lite-leaderboard__split_line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  height: 1px;
  background: #0000001F;
}

.lite-leaderboard__expanded-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  display: flex;
  padding: 0 8px;
}

.lite-leaderboard__expanded-content.expanded {
  max-height: 1300px;
  padding: 0 8px 16px 8px;
}

.lite-leaderboard__expanded-body {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: #F8FAFC;
}

.lite-leaderboard__expanded_chart {
  width: 100%;
  padding: 8px 16px;
}

.lite-leaderboard__expanded_details {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.lite-leaderboard__expanded_details_header {
  display: flex;
  padding-bottom: 6px;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  width: 100%;
}
.lite-leaderboard-ticker-name {
  white-space: nowrap;
}
.lite-leaderboard__expanded_details_ph {
  width: 270px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}

.lite-leaderboard__expanded_details_current_price {
  flex: 1 0 0;
  text-align: right;
  min-width: 160px;
}

.lite-leaderboard__expanded_details_tracked_price {
  flex: 1 0 0;
  text-align: right;
  min-width: 160px;
}

.lite-leaderboard__expanded_details_overall_performance {
  flex: 1 0 0;
  text-align: right;
  min-width: 160px;
}

.lite-leaderboard__expanded_details_period_performance {
  flex: 1 0 0;
  text-align: right;
  min-width: 120px;
}


.lite-leaderboard__expanded_details_allocation {
  flex: 1 0 0;
  text-align: right;
  width: 95px;
}

.lite-leaderboard__expanded_details_row {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
  width: 100%;
}

.lite-leaderboard__expanded_stock_icon {
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
}

body.dark {
  .lite-leaderboard__header-title {
    color: #fff;
  }

  .lite-leaderboard__content {
    background: #363636;
  }

  .lite-leaderboard__content-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background: #2c2c2c;
    color: rgba(255, 255, 255, 0.70);
  }

  .lite-leaderboard__content-row {
    color: rgba(255, 255, 255);
  }

  .lite-leaderboard__user {
    color: rgba(255, 255, 255, 0.7);
  }

  .color-black {
    color: #FFF;
  }

  .color-green {
    color: #A5D6A7;
  }

  .color-red {
    color: #EF9A9A;
  }

  .lite-leaderboard__action svg {
    fill: #FFF;
  }

  .lite-leaderboard__split_line {
    background: #FFFFFF1F;
  }

  .lite-leaderboard__content__splitter {
    color: #FFF;
  }

  .lite-leaderboard__expanded-body {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #3C3C3F;
  }

  .lite-leaderboard__expanded_details_header {
    color: rgba(255, 255, 255, 0.70);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .lite-leaderboard-ticker-name {
    color: rgba(255, 255, 255, 0.70) !important;
  }

  .lite-leaderboard__stock_icon {
    border: 1px solid #2C2C2C;
  }
}

@media (max-width: 1399px) {
  .lite-leaderboard__content-header {
    display: inline-flex;
  }
}

@media (max-width: 768px) {
  .lite-leaderboard__content {
    padding: 0;
    height: auto;
    overflow: initial;
  }
  
  .lite-leaderboard__content-header {
    display: none;
  }

  .lite-leaderboard__header {
    flex-direction: column;
    gap: 16px;
  }

  .lite-leaderboard__header-timestamp {
    width: 100%;
  }
}