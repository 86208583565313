.custom-toolbar {
  padding: 2px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  .toolbar-item {
    .MuiSvgIcon-root {
      fill: rgb(0, 123, 255);
    }
  }
}
body.home {
  .MuiPaper-rounded{
    .MuiSvgIcon-root  {
      color: rgb(0, 123, 255);
    }
  }
}
.tradealgo-mobile-table tr th {
  color: #000;
}

.tradealgo-mobile-table tr th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.MuiDialog-root.MuiModal-root {
  z-index: 999999 !important;
}
.MuiModal-root {
  z-index: 13001 !important;
}

.MuiDataGrid-columnHeaderDraggableContainer {
  background-color: rgb(0, 123, 255) !important;
}

.MuiDataGrid-columnHeader {
  background-color: rgb(0, 123, 255) !important;

  .MuiSvgIcon-root {
    fill: #fff !important;
  }
}

.tradealgo-table-no-data {
  background: #fff;
  color: grey;
  padding: 29px;
  display: flex;
  justify-content: center;
  border-radius: 16px;
}

.tradealgo-table-datagrid {
  background: white;
  border-radius: 16px;

  .MuiIconButton-label {
    color: #34404a;
  }

  .MuiDataGrid-root {
    border: none !important;
    position: relative;
    top: 0px;
    overflow: hidden !important;
    font-family: Inter, sans-serif !important;
  }

  .MuiDataGrid-cell {
    line-height: inherit !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: Inter, sans-serif !important;
  }

  .MuiDataGrid-columnsContainer {
    border-bottom-color: transparent;
  }

  .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  .MuiDataGrid-cell:focus {
    outline: none !important;
  }
}

.tradealgo-table-background {
  background: white;
  color: grey;
}

.tradealgo-table-text {
  color: grey !important;
  font-size: 14px;
  font-family: Inter, sans-serif !important;
  font-weight: 600;
  white-space: nowrap;
}

body.dark {
  .MuiDataGrid-columnsManagementHeader {
    .MuiSvgIcon-fontSizeMedium {
      fill: white;
    }
  }
  .toolbar-item {
    .MuiSvgIcon-root {
      fill: rgb(0, 123, 255) !important;
    }
  }
  .tradealgo-mobile-table tr th {
    color: #fff;
  }

  .tradealgo-mobile-table tr th:first-child {
    background: #191b1c;
  }

  .MuiDataGrid-columnHeaderDraggableContainer {
    background-color: rgb(94, 238, 205) !important;
  }

  .MuiDataGrid-columnHeader {
    background-color: rgb(94, 238, 205) !important;

    .MuiSvgIcon-root {
      fill: #000 !important;
    }
  }

  .tradealgo-table-no-data {
    background: rgba(21, 23, 23, 0.5);
    color: white;
  }

  .tradealgo-table-datagrid {
    background: rgba(21, 23, 23, 0.5);

    .MuiIconButton-label {
      color: #fffffa;
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
      color: black;
    }

    .MuiDataGrid-columnsContainer {
      border-bottom-color: rgb(60, 64, 67);
    }
  }

  .tradealgo-table-background {
    background: rgba(21, 23, 23, 0.5);
    color: white;
  }

  .tradealgo-table-text {
    color: white !important;
  }
}
