.notification-settings-page {
  margin-top: 30px;
}

.notification-settings-page__header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
}

.notification-settings-page__section {
  margin-bottom: 40px;
}

.notification-settings-page__section-header {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.notification-settings-page__section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.notification-settings-page__section-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
}

.notification-settings-page__section-content-item-label {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notification-settings-page__section-content-item
  label.MuiFormControlLabel-root {
  margin: 0;
}

body.dark {
  .notification-settings-page__header {
    color: #fff;
  }

  .notification-settings-page__section-header {
    color: #fff;
  }
}
