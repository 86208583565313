/************* Emerald Real Time Alerts **************/
.emerald-real-time-alerts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.emerald-real-time-alerts__left {
  width: calc(70% - 8px);
}

.emerald-real-time-alerts__right {
  width: calc(30% - 8px);
}

.emerald-real-time-trading-alerts-component {
  padding: 40px 20px;
  background: #000;
  border-radius: 10px;
}

.emerald-real-time-trading-alerts-navigator svg {
  color: #ffffff !important;
  fill: #ffffff !important;
  margin-right: 5px;
  cursor: pointer;
}

.emerald-real-time-trading-alerts-navigator:hover {
  color: #5eeecd;
}

.emerald-real-time-trading-alerts-navigator:hover svg {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.emerald-real-time-trading-alerts-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.emerald-real-time-trading-alerts-guide {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  margin-bottom: 30px;

  strong {
    font-weight: 700 !important;
  }
}

.emerald-real-time-trading-alerts-section {
  margin-bottom: 60px;
}

.emerald-real-time-trading-alerts-section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #e4e5e8;
}

.emerald-real-time-trading-alerts-section-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  padding: 20px 15px;
  border-bottom: 0.5px solid #e4e5e8;
  cursor: pointer;
}

.emerald-real-time-trading-alerts-section-item:hover {
  background: #e4e5e8;
}

.emerald-real-time-trading-alerts-section-item.active {
  background: #28292d;
  color: #fff;
}

.emerald-real-time-trading-alerts-section-item-main {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}

.emerald-real-time-trading-alerts-section-item svg {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.emerald-real-time-trading-alerts-section-item-more {
  border-left: 1px solid #000;
  padding-left: 16px;
  margin-top: 16px;
  padding-right: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000;
  cursor: initial;
}

.emerald-real-time-trading-alerts-section-item.active svg {
  color: #fff !important;
  fill: #fff !important;
}

a.trading-alerts-anchor {
  color: #000;
  text-decoration: underline;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
}

a.trading-alerts-anchor:hover {
  text-decoration: underline !important;
  color: #000;
}

.platinum-program-telegram-button {
  background: #007bff;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  display: inline-block;
  margin-bottom: 30px;
}

.platinum-program-telegram-button:hover {
  color: #f4f4f5;
}

body.light {
  .emerald-real-time-trading-alerts-component {
    background: #ffffff;
  }

  .emerald-real-time-trading-alerts-navigator {
    color: #7a7a7a;
  }

  .emerald-real-time-trading-alerts-navigator svg {
    color: #7a7a7a !important;
    fill: #7a7a7a !important;
  }

  .emerald-real-time-trading-alerts-navigator:hover {
    color: #5eeecd;
  }

  .emerald-real-time-trading-alerts-navigator:hover svg {
    color: #5eeecd !important;
    fill: #5eeecd !important;
  }

  .emerald-real-time-trading-alerts-title {
    color: #000;
  }

  .emerald-real-time-trading-alerts-guide {
    color: #7a7a7a;
  }

  .emerald-real-time-trading-alerts-guide strong {
    color: #000 !important;
  }

  .emerald-real-time-trading-alerts-section-title {
    color: #000000;
  }

  .emerald-real-time-trading-alerts-section-item-main {
    color: #000000;
  }

  .emerald-real-time-trading-alerts-section-item svg {
    color: #000 !important;
    fill: #000 !important;
  }

  .emerald-real-time-trading-alerts-section-item.active {
    background: #fff;

    .emerald-real-time-trading-alerts-section-item-main {
      color: #000;
    }

    svg {
      color: #ffffff !important;
      fill: #ffffff !important;
    }
  }
}

body.dark {
  .emerald-real-time-trading-alerts-component {
    border: 1px solid #5eeecd;
  }

  .emerald-real-time-trading-alerts-section-item:hover {
    background: #28292d;
  }

  .emerald-real-time-trading-alerts-section-item.active {
    background: #000;
  }

  .emerald-real-time-trading-alerts-section-item-more {
    color: #fff;
    border-left: 1px solid #fff;
  }

  a.trading-alerts-anchor {
    color: #fff;
  }
}

@media (max-width: 1299px) {
  .emerald-real-time-alerts {
    flex-direction: column;
  }

  .emerald-real-time-alerts__left {
    width: 100%;
  }

  .emerald-real-time-alerts__right {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .emerald-real-time-trading-alerts-component {
    padding: 20px;
  }
}
