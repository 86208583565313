.subscriptions-settings-page {
  margin-top: 30px;
}

.subscriptions-settings-page__header {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
}

.subscriptions-settings-item {
  margin-bottom: 60px;
}

.subscriptions-settings-item__title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.subscriptions-settings-item__title.active-plan {
  color: #007bff;
}

.subscriptions-settings-item__plan-header {
  padding: 14px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #e4e5e8;
}

.sub-table-rows {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.subscriptions-settings-item__plan-title {
  width: 25%;
  text-align: left;
}

.subscriptions-settings-item__plan-title.current-plan {
  color: #1c1c1e;
  font-weight: 600;
}

.subscriptions-settings-item__plan-status {
  width: 20%;
  text-align: center;
}

.subscriptions-settings-item__plan-status.positive-color {
  color: #007bff;
  font-weight: 600;
}

.subscriptions-settings-item__plan-price {
  width: 20%;
  text-align: center;
  font-weight: 500;
}

.subscriptions-settings-item__plan-action {
  width: 25%;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}

.subscriptions-settings-item__plan-action.positive-color {
  color: #007bff;
}

.plan-logo svg {
  fill: #000;
}

body.dark {
  .subscriptions-settings-page__header {
    color: #fff;
  }

  .subscriptions-settings-item__plan-header {
    background: #292c2f;
    color: #fff;
  }

  .subscriptions-settings-item__title {
    color: #fff;
  }

  .subscriptions-settings-item__title.active-plan {
    color: #3eb489;
  }

  .subscriptions-settings-item__plan-title.current-plan {
    color: #ffffff;
  }

  .subscriptions-settings-item__plan-status.positive-color {
    color: #3eb489;
  }

  .subscriptions-settings-item__plan-action.positive-color {
    color: #3eb489;
  }

  .sub-table-row {
    background: #000;
  }

  .plan-title {
    color: #fff;
  }

  .price-text {
    color: #a6a6a6;
  }

  .actions-text {
    color: #a6a6a6;
  }

  .status-text {
    color: #3eb489;
  }

  .plan-subtitle {
    color: #fff;
  }

  .plan-logo svg {
    fill: #ffffff;
  }
}

@media (max-width: 767px) {
  .subscriptions-settings-item__plan {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  .subscriptions-settings-item__plan-header {
    width: 50%;
    flex-direction: column;
    border-bottom: none;
    gap: 20px;
  }

  .subscriptions-settings-item__plan-title {
    text-align: center;
    width: 100%;
    padding: 20px 0;
  }

  .subscriptions-settings-item__plan-status {
    width: 100%;
    padding: 20px 0;
  }

  .subscriptions-settings-item__plan-price {
    width: 100%;
    padding: 20px 0;
  }

  .subscriptions-settings-item__plan-action {
    width: 100%;
    padding: 20px 0;
  }

  .sub-table-rows {
    max-width: 50%;
    flex-direction: row;
    overflow: auto;
  }

  .sub-table-rows::-webkit-scrollbar {
    display: none;
  }

  .sub-table-row {
    flex-direction: column;
    height: 100%;
    flex: 0 0 auto;
    border-right: 1px solid #292c2f;
    width: 240px;
  }

  .plan-wrapper {
    width: 100%;
    justify-content: center;
    padding: 6px 0;

    img {
      width: 48px;
    }
  }

  .status-wrapper {
    width: 100%;
    padding: 9.5px 0;
  }

  .sub-price-wrapper {
    width: 100%;
    padding: 11.5px 0;
  }

  .actions-text {
    width: 100%;
    padding: 20px 0;
  }

  .sub-actions-wrapper {
    width: 100%;
  }

  body.light {
    .subscriptions-settings-item__plan-header {
      border-right: 1px solid #e4e5e8;
    }

    .sub-table-row {
      border-right: 1px solid #e4e5e8;
    }
  }
}
