.ws-portfolio-performance-graph-inform {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  text-align: center;
  width: 100%;
}

.ws-portfolio-performance-graph {
  min-height: 313px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
}
.mobile {
  .ws-portfolio-performance-graph {
    min-height: 200px;
  }
}

.lite-stock-sample-graph-container {
  width: 100%;
  height: auto;
  position: relative;
}

.lite-stock-sample-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(242, 242, 245, 0.30);
  backdrop-filter: blur(4px);
  z-index: 1;
}

.lite-stock-sample-empty-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  width: 280px;
}

.ws-portfolio-performance-graph__period {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.ws-portfolio-performance-graph-loader {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.dark {
  .ws-portfolio-performance-graph-inform {
    color: rgba(255, 255, 255, 0.87);
  }

  .ws-portfolio-resolutions {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }

  .ws-portfolio-resolution {
    color: #FFF;
    border: 1px solid rgba(255, 255, 255, 0.12);

    &.Mui-selected {
      background-color: rgba(255, 255, 255, 0.16);
    }
  }

  .lite-stock-sample-overlay {
    background: rgba(91, 91, 93, 0.30);
  }

  .lite-stock-sample-empty-label {
    color: #FFF;
  }
}

.main-loading-overlay {
  position: relative;
  top: 41px;
  left: 57%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-overlay {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #6c5dd3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lite-stock-sample-overlay {
  background: rgba(91, 91, 93, 0.30);
}