.sentiment-dashboard {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;
  gap: 30px;
  padding: 0 5px;

  .overall-sentiment {
    width: 50%;
    padding: 20px;
    border-radius: 10px;
  }

  .overall-sentiment-light {
    background:
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.8) 100%
      ),
      url("../../assets/images/sentiments.png") lightgray 50% / cover no-repeat;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
  }

  .overall-sentiment-dark {
    border-radius: 10px;
    background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
      url("../../assets/images/sentiments-dark.png") lightgray 50% / cover
        no-repeat;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
  }

  .news-sentiment-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }

  .news-sentiment-timestamp {
    width: 46px;
    flex: 0 0 auto;
    color: #a6a6a6;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .news-sentiment-timevalue {
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #e4e5e8;
    cursor: pointer;
  }

  .news-sentiment-timevalue:last-child {
    border-bottom: none;
  }

  .news-sentiment-timevalue.active {
    color: #007bff;
    font-weight: 600;
  }

  .news-sentiment-charts {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .news-sentiment-charts::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #e5e5e5;
    transform: translateX(-50%);
  }

  .news-ai-sentiment-chart {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    min-width: calc(50% - 20px);
  }

  .news-ai-sentiment-chart-title {
    color: #000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .sentiment-overall {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052px;
    font-family: Inter, sans-serif;
    margin-bottom: 25px;
    color: #000;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: gray;
    margin-bottom: 3px;
  }

  .MuiButtonBase-root-MuiIconButton-root {
    padding: 2px;
  }

  .icon-space {
    margin-left: 6px;
  }

  .news-sentiment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: calc(50% - 20px);
  }

  .news-sentiment-details-title {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  .news-sentiment-details-content {
    position: relative;
  }

  .needle-img-wrapper {
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
  }

  .needle-img {
    display: block;
    height: 60px;
    transform-origin: center;
    transform: rotate(0deg);
    transition: all 0.25s linear;
  }

  .news-sentiment-diameter-value {
    color: #a6a6a6;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .positive-diameter-value {
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translateY(-15px);
  }

  .negative-diameter-value {
    position: absolute;
    left: 0;
    top: 100%;
    transform: translate(-100%, -15px);
  }

  .news-sentiment-details-label {
    color: #a6a6a6;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .news-sentiment-details-value {
    border-radius: 20px;
    padding: 4px 12px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .news-sentiment-details-value.positive {
    background: rgba(62, 180, 137, 0.1);
    color: #3eb489;
  }

  .news-sentiment-details-value.negative {
    background: rgba(234, 51, 35, 0.1);
    color: #ea3323;
  }

  .news-section {
    width: 50%;
    position: relative;
    padding: 20px;
    border-radius: 10px;
  }

  .news-section-light {
    background:
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.7) 100%
      ),
      url("../../assets/images/latest_news.png") lightgray 50% / cover no-repeat;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
  }

  .news-section-dark {
    background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
      url("../../assets/images/latest_news-dark.png") lightgray 50% / cover
        no-repeat;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
  }

  .events-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

  .header-left {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.052px;
  }

  .events-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }

  .events-title {
    color: #000;
    text-align: left;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.042px;
  }

  .news-content {
    color: #a6a6a6;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.036px;
  }

  .published {
    color: #007bff;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.036px;
  }

  .read-more {
    color: #007bff;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.036px;
    margin-top: 8px;
  }

  .read-more:hover {
    text-decoration: underline !important;
  }
}

.sentiment-dashboard.no-ai-data .news-section {
  width: 100% !important;
}

.sentiment-dashboard.no-ai-data:before {
  content: none;
}

.sentiment-dashboard.no-news-sentiment .overall-sentiment {
  width: 100% !important;
}

.sentiment-dashboard.no-news-sentiment:before {
  content: none;
}

.diameter-img {
  width: 150px;
}

body.dark {
  .news-ai-sentiment-chart-title {
    color: #fff;
  }

  .sentiment-dashboard .news-sentiment-timevalue.active {
    color: #5eeecd;
  }

  .news-sentiment-details-label {
    color: #e4e5e8;
  }

  .news-sentiment-details-value.positive {
    background: rgba(62, 180, 137, 0.4);
    color: #5eeecd;
  }

  .news-sentiment-diameter-value {
    color: #e4e5e8;
  }

  .news-sentiment-charts::before {
    background: #86868b;
  }

  .sentiment-dashboard .MuiSvgIcon-root {
    color: #cccccc;
  }

  .sentiment-dashboard .header-left {
    color: #fff;
  }

  .sentiment-dashboard .published {
    color: #5eeecd;
  }

  .sentiment-dashboard .news-content {
    color: #e4e5e8;
  }

  .read-more {
    color: #5eeecd;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .news-sentiment-container {
    flex-direction: column;
  }

  .sentiment-dashboard .news-sentiment-timestamp {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  }

  .sentiment-dashboard .news-sentiment-timevalue {
    border: none;
    border-right: 1px solid #e4e5e8;
    padding: 0 10px;
  }

  .sentiment-dashboard .news-sentiment-timevalue:last-child {
    border: none;
  }
}

@media (max-width: 1199px) {
  .sentiment-dashboard {
    flex-direction: column;
  }

  .sentiment-dashboard .overall-sentiment {
    width: 100%;
  }

  .sentiment-dashboard .news-section {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .news-sentiment-container {
    flex-direction: column;
  }

  .sentiment-dashboard .news-sentiment-timestamp {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  }

  .sentiment-dashboard .news-sentiment-timevalue {
    border: none;
    border-right: 1px solid #e4e5e8;
    padding: 0 10px;
  }

  .sentiment-dashboard .news-sentiment-timevalue:last-child {
    border: none;
  }
}

@media (max-width: 500px) {
  .sentiment-dashboard .news-sentiment-charts {
    flex-direction: column;
  }

  .sentiment-dashboard .news-sentiment-charts::before {
    background: transparent;
  }

  .sentiment-dashboard .news-ai-sentiment-chart {
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;
  }

  .sentiment-dashboard .news-sentiment-details {
    padding-top: 15px;
  }
}
