.movement-block-skeletons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 40px;
}

.movement-block-skeleton {
  transform: scale(1, 1) !important;
  border-radius: 12px;
}
