.tickers-list {
  padding-top: 20px;
  width: 310px;
  flex: 0 0 auto;
}

.tickers-list__header {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-right: 1px solid #e4e5e8;
}

.tickers-list__header__title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tickers-list__timeframes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #e4e5e8;
  padding: 0 20px;
}

.tickers-list__timeframe {
  width: calc(100% / 3);
  padding: 9px 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  border-bottom: 2px solid transparent;

  &.active {
    color: #000;
    border-bottom: 2px solid #007bff;
  }
}

.tickers-list__body {
  height: 350px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  border-right: 1px solid #e4e5e8;
}

.tickers-list__body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tickers-list__body__item {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  border-right: 3px solid transparent;
}

.tickers-list__body__item:hover {
  background: #f1f5fe;
}

.tickers-list__body__item.active {
  border-right: 3px solid #007bff;
  background: #f1f5fe;
}

.tickers-list__body__item__ticker {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2px;
}

.tickers-list__body__item__name {
  overflow: hidden;
  color: #a6a6a6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tickers-list__body__item__price {
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

.tickers-list__body__item__name {
  overflow: hidden;
  color: #a6a6a6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tickers-list__body__item__performance {
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tickers-list__body__item__performance__positive {
  color: #4caf50;
}

.tickers-list__body__item__performance__negative {
  color: #ea3323;
}

body.dark {
  .tickers-list__header__title {
    color: #fff;
  }

  .tickers-list__body__item__ticker {
    color: #fff;
  }

  .tickers-list__body__item__name {
    color: #e4e5e8;
  }

  .tickers-list__body__item__price {
    color: #ffffff;
  }

  .tickers-list__body__item.active {
    background: #1c1c1e;
    border-color: #5eeecd;
  }

  .tickers-list__body__item:hover {
    background: #1c1c1e;
  }

  .tickers-list__timeframe {
    color: rgba(255, 255, 255, 0.6);

    &.active {
      color: #FFF;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .tickers-list__body {
    height: 630px;
  }
}

@media (max-width: 1099px) {
  .tickers-list {
    width: 100%;
    padding-left: 20px;
  }

  .tickers-list__header {
    border-bottom: 1px solid #e4e5e8;
    border-right: none;
  }

  .tickers-list__body {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
    border-bottom: 1px solid #e4e5e8;
    border-left: 1px solid #e4e5e8;
  }

  .tickers-list__body__item {
    width: calc((100% - 20px) / 3);
    border: 2px solid transparent;
  }

  .tickers-list__body__item.active {
    border: 2px solid #007bff;
  }

  .tickers-list__timeframes {
    padding: 0;
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .tickers-list__body__item {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 500px) {
  .tickers-list__body__item {
    width: 100%;
  }
}
