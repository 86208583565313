.tickers-info {
  width: calc(100% - 310px);
  padding: 30px 0 0 30px;
}

.tickers-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tickers-info__header_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.tickers-info__header_company_symbol {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tickers-info__header_company_name {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tickers-info__header_button {
  border-radius: 6px !important;
  border: 1px solid #007bff !important;
  background-color: transparent !important;
  color: #007bff !important;
  text-align: center !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  box-shadow: none !important;
  display: flex !important;
  min-height: 35px;
  justify-content: flex-start !important;
  gap: 6px !important;
  padding: 10px;
}

.tickers-info__content {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
}

.tickers-info__content_sentiments {
  width: calc(30% - 15px);
}

.tickers-info__content_sentiments_title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tickers-info__content_sentiments_ai_scoring_title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0 6px 0;
}

.tickers-info__content_sentiments_ai_scoring_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e5e8;
}

.tickers-info__ai_scoring_timestamps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.tickers-info__ai_scoring_timestamps_item {
  border-radius: 20px;
  border: 1px solid #007bff;
  background: #fff;
  padding: 4px 18px;
  color: #007bff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 85px;
  text-align: center;
  cursor: pointer;
}

.tickers-info__ai_scoring_timestamps_item.active {
  background: #007bff;
  color: #ffffff;
}

.tickers-info__content_sentiments_news_value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding-top: 20px;
}

.tickers-info__news-sentiment-details-content {
  position: relative;
}

.tickers-info__needle-img-wrapper {
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}

.tickers-info__diameter-img {
  width: 120px;
}

.tickers-info__needle-img {
  display: block;
  height: 60px;
  transform-origin: center;
  transform: rotate(0deg);
  transition: all 0.25s linear;
}

.tickers-info__news-sentiment-details-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}

.tickers-info__news-sentiment-details-value {
  color: #4caf50;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tickers-info__news-sentiment-details-value--negative {
  color: #ea3323;
}

.tickers-info__news-sentiment-details-value--neutral {
  color: #a6a6a6;
}

.tickers-info__news-sentiment-details-value--positive {
  color: #4caf50;
}

.tickers-info__content_top_contracts {
  width: calc(70% - 15px);
}

.tickers-info__content_top_contracts_title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.tickers-info__content_top_contracts_date {
  color: #007bff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.tickers-info__content_top_contracts_content {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.tickers-info__content_top_contracts_content::-webkit-scrollbar {
  display: none;
}

.ticker-list-top-contracts-item {
  overflow: hidden;
  color: #a6a6a6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ticker-list-top-contracts-item.padding-text {
  padding: 0 10px;
}

.ticker-list-top-contracts-item.positive-color {
  color: #4caf50;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tickers-info__content_top_contracts_content > div {
  background: transparent;
}

body.dark {
  .tickers-info__header_company_symbol {
    color: #fff;
  }

  .tickers-info__header_company_name {
    color: #e4e5e8;
  }

  .tickers-info__content_sentiments_title {
    color: #fff;
  }

  .tickers-info__content_sentiments_ai_scoring_title {
    color: #fff;
  }

  .tickers-info__ai_scoring_timestamps_item {
    border: 1px solid #5eeecd;
    background: #1c1c1e;
    color: #5eeecd;
  }

  .tickers-info__ai_scoring_timestamps_item.active {
    background: #5eeecd;
    color: #000;
  }

  .tickers-info__news-sentiment-details-title {
    color: #fff;
  }

  .tickers-info__news-sentiment-details-value--positive {
    color: #5eeecd;
  }

  .tickers-info__content_top_contracts_title {
    color: #fff;
  }

  .tickers-info__content_top_contracts_date {
    color: #5eeecd;
  }

  .tickers-info__header_button {
    border: 1px solid #5eeecd !important;
    color: #5eeecd !important;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .tickers-info__content {
    flex-direction: column;
  }

  .tickers-info__content_sentiments {
    width: 100%;
  }

  .tickers-info__content_sentiments_title {
    text-align: center;
  }

  .tickers-info__content_sentiments_ai_scoring_title {
    text-align: center;
    margin-bottom: 20px;
  }

  .tickers-info-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  .tickers-info__content_sentiments_ai_scoring_container {
    border-bottom: none;
    padding-bottom: 0;
  }

  .tickers-info__content_sentiments_news_value {
    padding-top: 0;
    flex-direction: column;
    text-align: center;
  }

  .tickers-info__content_top_contracts {
    width: 100%;
  }

  .tickers-info__content_top_contracts_title {
    text-align: center;
  }

  .tickers-info__content_top_contracts_date {
    text-align: center;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .tickers-info-chart-wrapper {
    gap: 10px;
  }

  .tickers-info__news-sentiment-details-title {
    font-size: 14px;
  }
}

@media (max-width: 1099px) {
  .tickers-info {
    width: 100%;
    padding: 20px 0 0 20px;
  }

  .tickers-info__content {
    flex-direction: column;
  }

  .tickers-info__content_sentiments {
    width: 100%;
  }

  .tickers-info-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  .tickers-info__content_sentiments_ai_scoring_container {
    padding-bottom: 0;
    border-bottom: none;
  }

  .tickers-info__content_sentiments_news_value {
    padding-top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .tickers-info__content_sentiments_title {
    text-align: center;
  }

  .tickers-info__content_sentiments_ai_scoring_title {
    text-align: center;
    margin-bottom: 20px;
  }

  .tickers-info__content_top_contracts {
    width: 100%;
  }

  .tickers-info__content_top_contracts_title {
    text-align: center;
  }

  .tickers-info__content_top_contracts_date {
    text-align: center;
  }

  .ticker-list-top-contracts-item.padding-text {
    padding: 0 7px;
  }
}

@media (max-width: 767px) {
  .tickers-info__header {
    flex-direction: column;
    gap: 20px;
  }

  .tickers-info__content {
    margin-top: 20px;
  }

  .tickers-info__content_sentiments_title {
    font-size: 24px;
  }

  .tickers-info__content_sentiments_ai_scoring_title {
    font-size: 18px;
  }

  .tickers-info__news-sentiment-details-title {
    font-size: 18px;
  }

  .tickers-info-chart-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .tickers-info__content_top_contracts_title {
    font-size: 24px;
  }

  .tickers-info__content_top_contracts_date {
    font-size: 16px;
  }
}
