//light mode
.circle-wrap-light {
  width: 160px;
  height: 160px;
  background: #fefcff;
  border-radius: 50%;
}

.circle-wrap-light .circle .mask,
.circle-wrap-light .circle .fill {
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap-light .circle .mask {
  clip: rect(0px, 160px, 160px, 79px);
}

.inside-circle-bg {
  width: 146px;
  height: 146px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
}

.circle-wrap-light .inside-circle-bg {
  background: #fff;
}

.circle-wrap-dark .inside-circle-bg {
  background: #1c1e20;
}

.circle-wrap-light .inside-circle {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #f1f5f9;
  text-align: center;
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}
// dark mode
.circle-wrap-dark {
  width: 160px;
  height: 160px;
  background: transparent;
  border-radius: 50%;
}

.circle-wrap-dark .circle .mask,
.circle-wrap-dark .circle .fill {
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap-dark .circle .mask {
  clip: rect(0px, 160px, 160px, 79px);
}

.circle-wrap-dark .inside-circle {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #010202;
  text-align: center;
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}
/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 80px, 160px, 0px);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
}

/* sub parts */
.inside-circle-percentage-light {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  color: #000000;
  flex: none;
  flex-grow: 0;
}
.inside-circle-percentage-dark {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  color: #fbf7f7;
  flex: none;
  flex-grow: 0;
}

.inside-circle-value-light {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #9ca3af;
  flex: none;
  flex-grow: 0;
}
.inside-circle-value-dark {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #cacaca;
  flex: none;
  flex-grow: 0;
}

.inside-circle-title-light {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #a6a6a6;
  flex: none;
  flex-grow: 0;
  text-transform: capitalize;
}
.inside-circle-title-dark {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #cacaca;
  flex: none;
  flex-grow: 0;
  text-transform: capitalize;
}

.summary-block {
  flex: 1 1 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .summary-blocks {
    margin-top: 25px;
    margin-bottom: 14px;
  }

  .summary-block {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
  }
}
