.support-component {
  padding: 28px 30px;
  background: #161616;
  border-radius: 10px;
  min-height: calc(100vh - 280px);
}

.support-component-navigator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.support-component-navigator svg {
  color: #ffffff !important;
  fill: #ffffff !important;
  margin-right: 5px;
  cursor: pointer;
}

.support-component-navigator:hover {
  color: #5eeecd;
}

.support-component-navigator:hover svg {
  color: #5eeecd !important;
  fill: #5eeecd !important;
}

.support-component-header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #081131;
}

.support-component-header-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.support-component-header-promo {
  background: rgba(0, 220, 10, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-component-header-promo svg {
  color: #121416 !important;
  fill: #121416 !important;
  width: 36px !important;
  height: 36px !important;
}

.support-component-care {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #00dc0a;
  margin-left: 10px;
  max-width: 280px;
}

.support-component-info {
  margin-top: 24px;
}

.support-component-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.live-streams-author {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.support-component-author-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.support-component-author-job {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
}

.support-component-info-content {
  padding-bottom: 40px;
  border-bottom: 0.5px solid #e4e5e8;
}

.support-component-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 8px;
}

.support-component-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #e4e5e8;
}

.support-component-schedule {
  padding-top: 40px;
  padding-bottom: 26px;
  border-bottom: 0.5px solid #e4e5e8;
}

.support-component-schedule-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 20px;
}

.support-component-schedule-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.support-component-schedule-day-item {
  width: calc(20% - 2px);
}

.support-component-schedule-day {
  background: #161616;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  border: 1px solid #ffffff;
}

.support-component-schedule-time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  text-align: center;
  margin-top: 7px;
}

.support-component-bottom-item {
  width: calc(70% - 10px);
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.support-component-bottom-item-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.support-component-bottom-faq-img {
  width: 60px;
  margin-right: 15px;
}

.support-component-bottom-item-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.support-component-bottom-item-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  width: 190px;
}

.support-component-bottom-item-button {
  background: linear-gradient(
    101.16deg,
    #3183ff -7.41%,
    #0751bf 110.44%
  ) !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #ffffff !important;
  padding: 8px 0 !important;
  width: 155px !important;
  text-align: center !important;
  text-transform: initial !important;
  border-radius: 20px !important;
}

.support-faq-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #e4e5e8;
  margin-top: 30px;
  margin-bottom: 15px;
}

.support-faq-search-bar {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 20px;
}

input.support-faq-search-box {
  padding: 11px 14px;
  min-width: 350px;
  border: 0.5px solid #bcbcbc;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a6a6a6;
  background: transparent;
}

input.support-faq-search-box:focus-visible {
  outline: none;
}

.support-faq-all-topics {
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.support-faq-search-button {
  background: #121416 !important;
  padding: 11px 21px !important;
  border-radius: 0 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 11px !important;
  line-height: 14px !important;
  letter-spacing: -0.05em !important;
  color: #f4f4f5 !important;
  margin-left: 4px !important;
  text-transform: initial !important;
}

.support-faq-categories {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
}

.support-faq-category {
  width: calc(100% / 3 - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 22px 6px 13px;
  background: #161616;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #a6a6a6;
}

.support-faq-category-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.support-faq-category-available {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
  margin-top: 3px;
}

img.support-faq-category-icon {
  width: 24px;
}

.support-faq-section {
  margin-bottom: 60px;
}

.support-faq-section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #e4e5e8;
}

.support-faq-section-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #121416;
  padding: 20px 15px;
  border-bottom: 0.5px solid #e4e5e8;
  cursor: pointer;
}

.support-faq-section-item.active {
  background: #121416;
  color: #fff;
}

.support-faq-section-item-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.support-faq-section-item svg {
  color: #ffffff !important;
  fill: #ffffff !important;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.support-faq-section-item-more {
  border-left: 1px solid #6e6e70;
  padding-left: 16px;
  margin-top: 16px;
  padding-right: 30px;
}

.support-faq-section-item.active svg {
  color: #fff !important;
  fill: #fff !important;
}

.support-faq-scroll-item {
  position: fixed !important;
  bottom: 40px;
  right: calc(30% - 15px);
}

.up-circle-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #121416;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.up-circle-button:hover {
  background: #5eeecd;
}

.up-circle-button svg {
  fill: #ffffff !important;
  color: #ffffff !important;
}

.support-component-hand-person-icon {
  width: 36px;
  height: 36px;
}

.platinum-program-qa-chat-widget-mobile {
  display: none;

  iframe {
    border-radius: 10px;
  }
}

body.light {
  .support-component {
    background: #ffffff;
  }

  .support-component-author-name {
    color: #000000;
  }

  .support-component-author-job {
    color: #7a7a7a;
  }

  .support-component-title {
    color: #000000;
  }

  .support-component-description {
    color: #6e6e70;
  }

  .support-component-schedule-title {
    color: #000000;
  }

  .support-component-schedule-day {
    background: #f7f8f7;
    border-radius: 0;
    color: #6e6e70;
  }

  .support-component-schedule-time {
    color: #6e6e70;
  }

  .support-component-bottom-item-title {
    color: #000000;
  }

  .support-component-bottom-item-info {
    color: #7a7a7a;
  }

  .support-component-navigator {
    color: #7a7a7a;
  }

  .support-component-navigator svg {
    color: #7a7a7a !important;
    fill: #7a7a7a !important;
  }

  .support-component-navigator:hover {
    color: #5eeecd;
  }

  .support-component-navigator:hover svg {
    color: #5eeecd !important;
    fill: #5eeecd !important;
  }

  .support-faq-description {
    color: #7a7a7a;
  }

  .support-faq-all-topics {
    color: #000000;
  }

  .support-faq-category {
    background: #f7f8f7;
    border: 1px solid #f7f8f7;
  }

  .support-faq-category-title {
    color: #000000;
  }

  .support-faq-category-available {
    color: #7a7a7a;
  }

  .support-faq-section-title {
    color: #000000;
  }

  .support-faq-section-item-main {
    color: #000000;
  }

  .support-faq-section-item svg {
    color: #000000 !important;
    fill: #000000 !important;
    border-radius: 50%;
    border: 1px solid #7a7a7a;
  }

  .support-faq-section-item.active {
    .support-faq-section-item-main {
      color: #ffffff;
    }

    svg {
      color: #ffffff !important;
      fill: #ffffff !important;
      border-radius: 50%;
      border: 1px solid #ffffff;
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .support-faq-scroll-item {
    right: 45px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .support-component-bottom-item {
    flex-direction: column;
    width: 400px;
  }

  .support-component-schedule-day {
    font-size: 12px;
  }

  .support-component-schedule-time {
    font-size: 12px;
  }

  .support-faq-scroll-item {
    right: calc(30% + 43px);
  }
}

@media (max-width: 767px) {
  .support-component {
    padding: 20px;
  }

  .support-component-schedule-day {
    font-size: 12px;
  }

  .support-component-schedule-time {
    font-size: 12px;
  }

  .support-component-header {
    flex-direction: column;
  }

  .support-component-header-info {
    margin-bottom: 10px;
  }

  .support-component-header-promo {
    justify-content: flex-start;
  }

  .support-component-bottom-item {
    width: 100%;
  }

  .support-faq-scroll-item {
    right: 33px;
  }
}

@media (max-width: 600px) {
  .support-faq-category {
    width: 100%;
    padding: 16px 22px 16px 13px;
  }

  input.support-faq-search-box {
    min-width: auto;
    width: calc(100% - 100px);
  }
}

@media (max-width: 992px) {
  .platinum-program-qa-chat-widget-mobile {
    display: block;
    margin-top: 20px;

    iframe {
      height: 50vh;
    }
  }

  .platinum-program-qa-chat-widget {
    display: none;
  }
}
