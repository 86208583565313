.ta-product {
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  min-width: 300px;
  width: calc((100% - 72px) / 4);
  flex: 0 0 auto;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}

.ta-product__container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  aspect-ratio: 1 / 1;
  width: 100%;
}

.ta-product__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ta-product__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ta-product__coming-soon {
  padding: 2px 8px;
  border-radius: 999px;
  background: rgba(142, 142, 147, 0.50);
  color: #FFF;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05px;
  text-transform: uppercase;
}

.ta-product-ta-logo {
  width: 85px;
}

.ta-product-logo {
  width: 100%;
}

.ta-product__title {
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  width: 200px;
}

.ta-product__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ta-product__descriptions {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 290px;
}

.ta-product__button {
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  width: 170px !important;
  padding: 8px 22px !important;
  border-radius: 4px !important;
  background: #f2f2f5 !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
}

.terminal-product {
  background-image: url(../../assets/images/products/terminal.png);

  .ta-product__title {
    color: #fff;
  }

  .ta-product__descriptions {
    color: #fff;
  }

  .ta-product__button {
    color: #000 !important;
    background: #f2f2f5 !important;
  }
}

.wealth-series-product {
  background-image: url(../../assets/images/products/wealth-series.png);

  .ta-product__title {
    color: #fff;
    width: 100%;
  }

  .ta-product__descriptions {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%;
    letter-spacing: 0.4px;
  }

  .ta-product__button {
    border-radius: 4px !important;
    background: linear-gradient(0deg, #FFF5EC 0%, #FFF5EC 100%), linear-gradient(90deg, #F8EDE6 0.22%, #9D5521 100.01%) !important;
    box-shadow: 0px 0px 16px 0px #C9A387 !important;
    color: #725C30;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
  }

  .ta-product-logo {
    filter: drop-shadow(0px 0px 16.976px #C9A387);
  }
}

.emerald-product {
  background-image: url(../../assets/images/products/emerald.png);

  .ta-product__title {
    color: #0e5856;
  }

  .ta-product__descriptions {
    color: #0e5856;
  }

  .ta-product__button {
    color: #fff !important;
    background: linear-gradient(88deg, #000 0%, #5eeecd 100%) !important;
  }
}

.platinum-product {
  background-image: url(../../assets/images/products/platinum.png);

  .ta-product__title {
    background: linear-gradient(269deg, #5eeecd 0%, #007bff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ta-product__description1 {
    color: rgba(0, 0, 0, 0.87);
  }

  .ta-product__description2 {
    background: linear-gradient(269deg, #5eeecd 0%, #007bff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ta-product__button {
    color: #fff !important;
    background: linear-gradient(88deg, #5eeecd 0%, #007bff 100%) !important;
  }
}

.tradegpt-product {
  background-image: url(../../assets/images/products/tradegpt.png);

  .ta-product__title {
    color: #fff;
  }

  .ta-product__descriptions {
    color: #fff;
  }

  .ta-product__button {
    color: #000 !important;
    background: #f2f2f5 !important;
  }
}

.mentorship-product {
  background-image: url(../../assets/images/products/mentorship.png);

  .ta-product__title {
    color: #fff;
  }

  .ta-product__descriptions {
    color: #fff;
  }

  .ta-product__button {
    color: #000 !important;
    background: #f2f2f5 !important;
  }
}

.academy-product {
  background-image: url(../../assets/images/products/academy.png);

  .ta-product__title {
    background: linear-gradient(269deg, #5eeecd 0%, #007bff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ta-product__descriptions {
    color: rgba(0, 0, 0, 0.87);
  }

  .ta-product__button {
    color: #fff !important;
    background: #5b5b5d !important;
  }
}

.ta-product__calculator {
  position: absolute;
  width: 90px;
  bottom: 0;
  right: 0;
}

.ta-product__diamond {
  position: absolute;
  width: 160px;
  bottom: 0;
  right: 0;
}

body.dark {
  .platinum-product {
    .ta-product__description1 {
      color: rgba(255, 255, 255, 0.87);
    }
  }

  .academy-product {
    .ta-product__descriptions {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

@media (max-width: 767px) {
  .ta-product {
    width: 100%;
  }

  .ta-product__calculator {
    width: 30%;
  }

  .ta-product__diamond {
    width: 60%;
  }
}
