.tutorials-past-videos-page {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 15px;
}

.tutorial-past-video {
  width: calc(100% / 3 - 10px);
  cursor: pointer;
}

.tutorial-past-video-inner-content {
  padding: 20px;
  border-radius: 10px;
}

.tutorial-past-video-thumbnail {
  border-radius: 10px;
  height: auto;
  width: 100%;
  cursor: pointer;
}

.tutorial-past-video-title {
  font-size: 16px;
  margin-top: 5px;
  font-weight: bold;
  color: #000000;
}

.tutorial-past-video-length {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 5px;
}

.tutorials-live-sessions-tabs {
  width: calc(100% / 3);
}

.tutorials-stream-search {
  width: calc(100% / 3 * 2);
}

.tutorial-live-video {
  width: calc(100% / 3 * 2 - 10px);
  aspect-ratio: 16 / 9;
}

.tutorial-live-video-chat {
  width: calc(100% / 3 - 10px);
}

body.dark {
  .tutorial-past-video-title {
    color: #ffffff;
  }

  .tutorial-past-video-length {
    color: #c3c3c3;
  }

  .tutorial-video-description {
    color: #ffffff;
  }
}

@media (max-width: 1300px) {
  .tutorial-past-video-title {
    font-size: 14px;
  }

  .tutorial-past-video-length {
    font-size: 14px;
  }

  .tutorials-live-sessions-tabs {
    width: 50%
  }

  .tutorials-stream-search {
    width: 50%
  }

  .tutorial-live-video {
    width: 100%;
  }

  .tutorial-live-video-chat {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .tutorial-past-video {
    width: calc(50% - 15px);
  }
}

@media (max-width: 600px) {
  .tutorial-past-video {
    width: 100%;
  }

  .tutorial-past-video-title {
    font-size: 16px;
  }

  .tutorial-past-video-length {
    font-size: 16px;
  }
}
