.lite-stock-performance-news {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 7px; 
    padding: 1px;
    background:linear-gradient(45deg,#E3A396,#007BFF); 
    mask: 
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude;
  }
}

.lite-stock-performance-news__header {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #FFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.lite-stock-performance-news__ticker_info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.lite-stock-performance-news__ticker_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.lite-stock-performance-news__ticker_caption {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.lite-stock-performance-news__actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lite-stock-performance-news__add_button {
  border-radius: 4px !important;
  padding: 6px 8px !important;
  color: #007BFF !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  text-transform: initial !important;
}

.lite-stock-performance-news__content {
  display: flex;
  padding: 8px 24px 16px 16px;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  max-height: 300px;
  min-height: 300px;
  gap: 8px;
  overflow: auto;
  z-index: 1;
  margin: 4px;
}

a.lite-stock-performance-news__source {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #007BFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.lite-stock-performance-news-dialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
  margin-bottom: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 7px; 
    background:linear-gradient(45deg,#E3A396,#007BFF); 
    mask: 
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude;
  }
}

.lite-stock-performance-news__content--modal {
  max-height: 500px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

body.dark {
  .lite-stock-performance-news {
    background: #2C2C2C;

    &::before {
      background:linear-gradient(45deg,#FCEDEC,#009EFF); 
    }
  }

  .lite-stock-performance-news__header {
    background: #2C2C2C;
  }

  .lite-stock-performance-news__ticker_caption {
    color: #FFF;
  }

  .lite-stock-performance-news__add_button {
    color: #009EFF !important;
  }

  .lite-stock-performance-news__description {
    color: #FFF;
  }

  a.lite-stock-performance-news__source {
    color: #009EFF;
  }

  .lite-stock-performance-news-dialog {
    background: #2C2C2C;
  }

  .lite-stock-performance-news__content {
    background: #2C2C2C;
  }

  .lite-stock-performance-news__header--modal {
    background: #121212 !important;
  }
}

@media (max-width: 410px) {
  .lite-stock-performance-news__header {
    padding: 16px 8px;
  }

  .lite-stock-performance-news__content {
    padding: 16px 8px;
  }

  .lite-stock-performance-news__ticker_info {
    gap: 8px;
  }

  .lite-stock-performance-news__header {
    flex-direction: column;
    gap: 8px;
  }
}