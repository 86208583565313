.movement-half-width-block {
  width: calc(50% - 15px);
}

.movement-row-block {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 40px;
}

.movement-row-block-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 24px;
}

.movement-row-block-header {
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.movement-row-block-header-item {
  width: calc(100% / 3 - 20px);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: #94a3b8;
}

.movement-row-block-content-row {
  padding: 16px;
  background: #f1f5f9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
}

.movement-row-block-content-row:hover {
  text-decoration: none !important;
}

.movement-row-block-content-row-progressbar {
  position: absolute;
  height: 100%;
  background: #5eeecd;
  left: 0;
  z-index: 1;
}
.movement-row-block-content-row-progressbar-bear {
  position: absolute;
  height: 100%;
  background: #7c6feca1;
  left: 0;
  z-index: 1;
}

.bearish-record .movement-row-block-content-row-progressbar {
  background: #ff4949 !important;
}

.movement-row-block-content-item {
  z-index: 2;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0f172a;
  width: calc(33.3333333333% - 26px);
}

.movement-row-block-content-row:hover .movement-row-block-content-item {
  color: #808080;
}

@media (max-width: 1200px) {
  .movement-half-width-block {
    width: 100%;
  }
}