.supports-widget {
  margin-top: 10px;
  padding: 24px;
  background: #161616;
  border-radius: 10px;
}

.supports-widget-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 16px;
}

.supports-actions-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.supports-widget-item {
  width: calc(50% - 10px);
  padding: 20px;
  background: #161616;
  border: 1px solid #7a7a7a;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.supports-widget-item-detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

img.supports-widget-faq-img {
  width: 60px;
  margin-right: 15px;
}

.supports-widget-item-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.supports-widget-item-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e4e5e8;
  margin-top: 8px;
}

.supports-widget-item-button {
  background: linear-gradient(
    101.16deg,
    #3183ff -7.41%,
    #0751bf 110.44%
  ) !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #ffffff !important;
  padding: 8px 0 !important;
  width: 155px !important;
  text-align: center !important;
  text-transform: initial !important;
  border-radius: 20px !important;
}

body.light {
  .supports-widget {
    background: #ffffff;
  }

  .supports-widget-title {
    color: #081131;
  }

  .supports-widget-item {
    background: #f7f8f7;
    border: 1px solid #f7f8f7;
  }

  .supports-widget-item-title {
    color: #000;
  }

  .supports-widget-item-info {
    color: #7a7a7a;
  }
}

@media (max-width: 992px) {
  .supports-widget-item {
    flex-direction: column;
  }

  .supports-widget-item-detail {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .supports-actions-wrapper {
    flex-direction: column;
  }

  .supports-widget-item {
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-start;
  }
}
